import React from "react";
import { NavLink } from "react-router-dom";
import manExplorer from "../../../../assets/img/division/publishing/ExplorerMan.svg";
import imgPlanetPink from "../../../../assets/img/planets/pink.png";
import imgPlanetWhite from "../../../../assets/img/planets/white.png";
import { ReactComponent as Wave } from "../../../../assets/img/home/wave.svg";
import Logo from "../../../../assets/logos/logo-forager-white.svg";
import SocialLinks from "../../../general/SocialLinks";

function AboutExplorer() {
  return (
    <div className="relative">
      <section className="container mx-auto relative bg-navy-blue pt-16 xl:pt-0 overflow-hidden">
        <div className="flex flex-col md:flex-row-reverse items-center mx-auto md:justify-between">
          <img
            src={manExplorer}
            alt="man in space"
            className="z-10 w-40 max-w-64 md:w-1/2 xl:w-[24rem] xl:mt-8 xl:mr-16 mb-10 lg:mb-0 mx-auto"
          />
          <div className="max-w-lg">
            <div className="hidden md:flex mb-16 xl:mt-16 xl:mb-10">
              <img src={Logo} alt="Logo Forager" className="w-64" />
              <span className="text-neon-pink text-5xl xl:text-6xl font-bold ml-3">
                publishing
              </span>
            </div>
            <h1 className="xl:w-10/12 text-2xl px-2 text-center md:text-left font-bold text-white">
              one team with the experience to
              <span className="text-neon-pink">
                {" "}
                write, edit, and publish{" "}
              </span>
              your written projects and
              <span className="text-neon-pink"> tell the story </span>
              you want to share
            </h1>
            <div className="flex gap-6 justify-center md:justify-start my-12 lg:mt-20">
              <NavLink
                to="/division/publishing/work"
                className="hover:bg-neon-pink hover:text-white w-40 h-12 shadow-md bg-white rounded-3xl grid place-items-center font-bold"
              >
                see our work
              </NavLink>
              <NavLink
                to="/contact"
                className="hover:bg-neon-pink w-40 h-12 shadow-md bg-dark-denim text-white rounded-3xl grid place-items-center font-bold"
              >
                get in touch
              </NavLink>
            </div>
            <SocialLinks margin="mt-12" />
            <p className="hidden md:flex bg-white w-56 h-0.5 rounded-xl mt-4"> </p>
          </div>
        </div>
        <div className="container">
          <img
            src={imgPlanetPink}
            alt="planet orange"
            className="w-14 md:w-32 aspect-square absolute right-6 md:right-16 top-52 md:hidden xl:flex 2 xl:top-[46rem]"
          />
          <img
            src={imgPlanetWhite}
            alt="planet white"
            className="w-7 xl:w-24 aspect-square absolute left-4 md:left-[29rem] xl:left-1/2 top-60 md:top-48 xl:top-32 2xl:top-24"
          />
          <img
            src={imgPlanetWhite}
            alt="planet white"
            className="w-7 xl:w-16 aspect-square absolute md:hidden xl:flex 2xl:hidden right-9 xl:right-20 top-24 lg:top-[25rem]"
          />
        </div>
      </section>
      <div className="bg-dark-denim mt-20 xl:-mt-8">
        <Wave className="w-full -translate-y-1" />
      </div>
      <img
        src={imgPlanetWhite}
        alt="planet white"
        className="z-50 w-12 aspect-square absolute right-9 bottom-28 md:hidden"
      />
      <img
        src={imgPlanetPink}
        alt="planet orange"
        className="w-9 md:w-20 aspect-square xl:w-40 xl:h-40 absolute left-10 md:left-[29rem] xl:left-[36rem] 2xl:left-[60rem] top-16 md:top-[33rem] xl:top-[27rem] 2xl:top-[29rem]"
      />
      <img
        src={imgPlanetPink}
        alt="planet orange"
        className="w-36 xl:w-72 aspect-square absolute -left-8 bottom-0"
      />
    </div>
  );
}

export default AboutExplorer;
