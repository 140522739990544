import React from "react";
import spaceMan from "../../assets/img/home/Animacion-web.gif";
import imgPlanetOrange from "../../assets/img/planets/orange.png";
import imgPlanetLime from "../../assets/img/planets/lime.png";
import imgPlanetWhite from "../../assets/img/planets/white.png";
import imgPlanetCyan from "../../assets/img/planets/cyan.png";
import imgPlanentPink from "../../assets/img/planets/pink.png";
import { ReactComponent as Wave } from "../../assets/img/home/wave.svg";
import SocialLinks from "../general/SocialLinks";

export default function HomeHero() {
  return (
    <section className="bg-navy-blue -mt-1">
      <div className="relative container mx-auto">
        <div className="md:px-4 flex flex-col md:flex-row-reverse items-center justify-between mx-auto">
          <div>
            <img
              src={spaceMan}
              alt="man in space"
              className="w-40 max-w-64 md:w-full xl:w-[30rem] mx-auto"
            />
          </div>
          <div className="md:w-11/12 xl:w-1/2 max-w-full md:mt-16 xl:mt-0">
            <h1 className="text-4xl lg:text-6xl lg:leading-[4.3rem] text-center  md:text-left text-white mt-2 md:mb-10 px-10 md:px-0">
              <span className="text-princeton-orange font-bold">
                harvesting
                {" "}
              </span>
              the
              <span className="text-lime-green font-bold"> best trends </span>
              from around
              <span className="text-neon-pink font-bold"> the world </span>
            </h1>
            <p className="-tracking-[0.05px] lg:tracking-normal text-white p-4 md:pr-5 md:p-0 md:mb-12">
              We are a team of professionals specializing in helping natural and
              cultural heritage organizations with all of their digital needs.
              As digital nomads, we are constantly travelling and picking up new
              trends from each city we explore. That makes us highly adaptable
              and ready to harvest the best results for our partners.
            </p>
            <div className="flex flex-col md:flex-row items-center gap-5 my-5 xl:my-0 md:mb-3">
              <a
                href="/work"
                className="w-44 h-12 bg-white hover:bg-opacity-20 hover:text-white rounded-3xl grid place-items-center font-bold"
              >
                see our work
              </a>
              <a
                href="/contact"
                className="w-44 h-12 bg-dark-denim hover:bg-bayside-cyan text-white rounded-3xl grid place-items-center font-bold"
              >
                get in touch
              </a>
            </div>
            <SocialLinks margin="mt-12" />
            <p className="hidden md:flex bg-white w-56 h-0.5 rounded-xl mt-4"> </p>
          </div>
        </div>
        <img
          src={imgPlanetOrange}
          alt="planet orange"
          className="w-14 xl:w-48 aspect-square absolute z-10 right-2 md:right-[34%] lg:right-[42%] top-40 md:top-[90%] with-transition"
        />
        <img
          src={imgPlanetLime}
          alt="planet lime"
          className="w-8 md:w-20 aspect-square absolute left-9 md:left-[54%] top-12 md:top-20 with-transition"
        />
        <img
          src={imgPlanetWhite}
          alt="planet white"
          className="w-7 md:w-16 aspect-square absolute z-10 left-4 md:left-[91%] xl:left-[82%] top-56 xl:top-[49rem] md:top-[34rem] with-transition"
        />
        <img
          src={imgPlanetCyan}
          alt="planet cyan"
          className="w-12 md:w-28 aspect-square absolute z-10 right-5 bottom-0 md:-bottom-48 with-transition"
        />
        <img
          src={imgPlanentPink}
          alt="planet pink"
          className="z-10 w-32 lg:w-[17rem] aspect-square absolute -left-11 xl:-left-32 -bottom-44 md:-bottom-40 lg:-bottom-72 with-transition"
        />
      </div>
      <div className="bg-dark-denim mt-20 xl:-mt-8">
        <Wave className="w-full -translate-y-1" />
      </div>
    </section>
  );
}
