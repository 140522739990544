import React from "react";
import { useDispatch } from "react-redux";
import { deleteContactMail } from "../../actions/contactMailActions";

import garbage from "../../assets/icons/garbage.svg";

function SingleMail({ mail }) {
  const { email, createdAt, name, subject, message, _id } = mail;

  const dispatch = useDispatch();

  const deleteHandler = (e) => {
    e.preventDefault();
    dispatch(deleteContactMail(_id));
  };

  return (
    <div
      className="p-7 flex gap-4 bg-white rounded-[10px] lg:rounded-[30px]"
      style={{ justifyContent: "space-between" }}
    >
      <div className="flex flex-col gap-y-1">
        <p>from:</p>
        <p>date:</p>
        <p>subject:</p>
      </div>
      <div className="flex flex-col gap-y-1" style={{ flexGrow: "1" }}>
        <p>{`${name} (${email})`}</p>
        <p>{createdAt.toString().substring(0, 10)}</p>
        <p className="font-bold">{subject}</p>
        <p>{message}</p>
      </div>
      <button type="submit" className="mb-auto" onClick={deleteHandler}>
        <img src={garbage} alt="garbage" />
      </button>
    </div>
  );
}

export default SingleMail;
