import React from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import LogoBlue from '../assets/logos/logo-forager-dark.svg';
import AppDrawer from './components/AppDrawer';
import AppFooter from './components/AppFooter';
import NavegationDesktop from './components/NavegationDesktop';

export default function DivisionLayout({
  children, colorClass, links, divisionName,
}) {
  const location = useLocation();
  const navigate = useNavigate();
  React.useEffect(() => {
    const { pathname, hash } = location;
    if (hash) {
      navigate(pathname);
      window.location.hash = hash;
    }
    // console.log(document.getElementById('division-link-group'));
  }, []);
  return (
    <div className="drawer drawer-end">
      <input id="app-drawer" type="checkbox" className="drawer-toggle" />
      <div className="drawer-content bg-navy-blue">
        <header className="bg-navy-blue md:sticky md:top-0 md:z-50 md:w-full">
          <div className="hidden md:block">
            <NavegationDesktop />
          </div>
          <div className={`rounded-b-3xl md:rounded-none ${colorClass}`}>
            <div className="flex flex-wrap justify-between items-center container mx-auto py-3">
              <div className="mb-3 md:mb-0 flex">
                <img
                  src={LogoBlue}
                  alt="Logo Forager Media Group"
                  className="ml-4 w-32"
                />
                <h3 className="text-2xl text-white font-extrabold ml-2">{ divisionName }</h3>
              </div>
              <div className="division-links grid grid-cols-3 justify-between w-full md:w-6/12 text-center text-dark-denim font-bold">
                <div>
                  <NavLink to={links.about} className="flex justify-center">
                    <span className="hidden lg:flex mr-1">{ divisionName }</span>
                    about
                  </NavLink>
                </div>
                <div className="border-x-2 border-dark-denim">
                  <NavLink to={links.services} className="flex justify-center">
                    <span className="hidden lg:flex mr-1">{ divisionName }</span>
                    services
                  </NavLink>
                </div>
                <div>
                  <NavLink to={links.work} className="flex justify-center">
                    <span className="hidden lg:flex mr-1">{ divisionName }</span>
                    work
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </header>
        <main>{ children }</main>
        <AppFooter />
      </div>
      <AppDrawer />
    </div>
  );
}
