import React from "react";

const BlogPlanets = () => {
  return (
    <div className="flex">
      <img
        src={require("../assets/img/planets/lime.png")}
        alt="planet white"
        className="w-10 md:w-20 aspect-square absolute left-2 top-7"
      />
      <img
        src={require("../assets/img/planets/white.png")}
        alt="planet orange"
        className="w-6 md:w-11 aspect-square absolute left-44 md:left-1/2 top-8 md:top-24"
      />
      <img
        src={require("../assets/img/planets/cyan.png")}
        alt="planet lime"
        className="w-16 md:w-32 aspect-squar absolute right-7 top-1"
      />
      <img
        src={require("../assets/img/planets/white.png")}
        alt="planet orange"
        className="w-5 xl:w-11 aspect-square absolute left-12 md:left-36 top-[80%] md:top-[84%]"
      />
      <img
        src={require("../assets/img/planets/orange.png")}
        alt="planet cyan"
        className="w-16 xl:w-24 aspect-square absolute right-40 md:right-1/4 top-[84%] xl:top-3/4"
      />
      <img
        src={require("../assets/img/planets/white.png")}
        alt="planet pink"
        className="w-8 md:w-16 aspect-square absolute right-10 top-[80%]"
      />
    </div>
  );
};

export default BlogPlanets;
