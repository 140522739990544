import React from 'react';
import DivisionLayout from '../../../layouts/DivisionLayout';
import Work from '../../../components/divisions/divisionProductions/work/Work';

function WorkProductions() {
  const links = {
    about: '/division/productions/about',
    services: '/division/productions/services',
    work: '/division/productions/work',
  };
  return (
    <DivisionLayout colorClass="bg-bayside-cyan" links={links} divisionName="productions">
      <Work />
    </DivisionLayout>
  );
}

export default WorkProductions;
