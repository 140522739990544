import React, { useEffect, useState } from "react";

const Message = ({ body, type, delay = 5 }) => {
  const [display, setDisplay] = useState("block");

  useEffect(() => {
    setDisplay("block");
    setTimeout(() => setDisplay("none"), delay * 1000);
    // eslint-disable-next-line
  }, [body]);

  return (
    <div
      className="message"
      style={{
        backgroundColor: "transparent",
        position: "fixed",
        display: `${display}`,
        zIndex: "500000",
        display: "flex",
        justifyContent: "center",
        margin: "auto",
      }}
    >
      <div className="container">
        <div
          className="message-body"
          style={{
            backgroundColor:
              type === "info"
                ? "var(--navy-blue)"
                : type === "admin"
                ? "var(--dark-denim)"
                : "var(--neon-pink)",
          }}
        >
          <h3 style={{ fontWeight: "bold" }}>{type ? "success" : "error"}</h3>
          <h6 style={{ textTransform: "none" }}>
            {type ? body : `error: ${body}`}
          </h6>
        </div>
      </div>
    </div>
  );
};

export default Message;
