import React from "react";
import { NavLink } from "react-router-dom";
import virtualTour from "../../../../assets/img/division/virtual/tour-development.jpg";
import virtualLearning from "../../../../assets/img/division/virtual/virtual-learning.jpg";
import augmentedReality from "../../../../assets/img/division/virtual/augmented-reality.jpg";
import interactiveInstallations from "../../../../assets/img/division/virtual/interactive-installations.jpg";
import rocket from "../../../../assets/img/division/digital/rocket.svg";
import ServiceCard from "../../ServiceCard";

function Service() {
  const services = [
    {
      img: virtualTour,
      service: "virtual tour development",
      text: `Our team works to develop interactive virtual tours that let visitors explore unreachable destinations or get a behind-the-scenes look at spaces too precious to open. The software used depends on the project, but the quality is always the highest.`,
    },
    {
      img: virtualLearning,
      service: "virtual learning resources",
      text: `Our team works with educators to create resources that align with government curriculum but are exciting for students to use. Making learning fun removes the feeling that students are learning and ensures higher information retention.`,
    },
    {
      img: augmentedReality,
      service: "augmented reality experience",
      text: `Our team works with your existing guided tours to develop unique augmented reality experiences that take the traditional audio tour to the next level. We do all the heavy lifting to ensure you can create it without worrying about the details.`,
    },
    {
      img: interactiveInstallations,
      service: "interactive installations",
      text: `Our team works with your curatorial team to develop immersive installations to bring new technologies to the fingertips of your visitors. We design the programs that build off of your exhibits and install the technology to keep it running.`,
    },
  ];

  return (
    <div className="bg-white">
      <div className="container mx-auto px-0">
        <h1 className="font-bold text-2xl text-center -mt-1 lg:pt-5 pt-12 pb-14 md:pt-0">
          our
          <span className="text-lime-green"> virtual & augmented reality </span>
          services
        </h1>
        <section className="px-4 lg:container mx-auto grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-5">
          {services.map((ele, index) => (
            <ServiceCard
              key={index}
              division="virtual"
              img={ele.img}
              service={ele.service}
              text={ele.text}
            />
          ))}
        </section>
      </div>
      <div className="overflow-hidden relative mt-20">
        <div className="container mx-auto">
          <div className="sm:w-1/2">
            <p className="px-4 md:mt-5 lg:mt-14">
              We have worked with clients across three continents, from big name
              attractions to small community-led institutions. No matter the
              size, each client we work with gets the same attention to detail,
              experience-driven creativity, and respect towards culturally
              sensitive materials to deliver a project that not only impresses
              our clients but impresses their audiences.
            </p>
            <NavLink
              to="/division/virtual/work"
              className="shadow-md mt-14 mb-20 lg:mb-28 w-56 h-12 hover:bg-dark-denim bg-lime-green text-white rounded-3xl mx-auto sm:mx-4 grid place-items-center font-bold"
            >
              see portfolio
            </NavLink>
          </div>
        </div>
        <div className="pl-4 bg-lime-green pb-10 md:py-12">
          <div className="container mx-auto">
            <img
              src={rocket}
              alt="rocket"
              className="-rotate-90 sm:rotate-0 sm:absolute sm:bottom-0 sm:-right-48 lg:right-0 sm:max-h-full"
            />
            <div className="sm:w-1/2 xl:pt-10">
              <p className="pr-4 pt-8 font-bold">
                If you are looking for a team that has
                <span className="text-white"> the experience and passion </span>
                to truly understand the needs of the heritage sector,
                <span className="text-white">
                  {" "}
                  Forager Digital is your best resource.{" "}
                </span>
                We are committed to working with institutions of all sizes and
                together with our partner Forager Financial Services, will work
                to find funding that supports
                <span className="text-white"> your next project.</span>
              </p>
              <NavLink
                to="/contact"
                className="shadow-md mt-14 lg:mt-16 mb-20 xl:mb-9 w-48 h-12 hover:bg-white bg-dark-denim text-white hover:text-dark-denim rounded-3xl mx-auto sm:mx-4 grid place-items-center font-bold"
              >
                contact us
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Service;
