import React from "react";
import imgPlanetOrange from "../../assets/img/planets/orange.png";
import imgPlanetLime from "../../assets/img/planets/lime.png";
import imgPlanetWhite from "../../assets/img/planets/white.png";
import imgPlanetCyan from "../../assets/img/planets/cyan.png";
import imgPlanetPink from "../../assets/img/planets/pink.png";
import peopleHappy from "../../assets/img/industries/people-happy.png";
import peopleHappyDesktop from "../../assets/img/about/people-happy-desktop.png";
import explorersDesktop from "../../assets/img/about/explorers-desktop.png";
import groupExplorer from "../../assets/img/about/group -explorer.svg";

function About() {
  return (
    <div className="mb-20">
      <div className="bg-navy-blue flex">
        <div className="container mx-auto relative">
          <h1 className="pt-[4.6rem] md:pt-[5.3rem] pb-[6.6rem] font-bold text-3xl md:text-6xl text-white text-center">
            about us
          </h1>
          <div className="flex">
            <img
              src={imgPlanetOrange}
              alt="planet orange"
              className="w-[4.4rem] lg:w-32 h-[4.4rem] lg:h-32 absolute left-3 md:left-11 top-4 md:top-4 with-transition"
            />
            <img
              src={imgPlanetLime}
              alt="planet lime"
              className="w-14 lg:w-24 h-14 lg:h-24 absolute left-20 md:left-40 lg:left-72 top-32 md:top-24 with-transition"
            />
            <img
              src={imgPlanetWhite}
              alt="planet white"
              className="w-10 lg:w-16 h-10 lg:h-16 absolute left-4 md:left-20 lg:left-40 top-40 with-transition"
            />
            <img
              src={imgPlanetWhite}
              alt="planet white"
              className="w-6 lg:w-11 h-6 lg:h-11 absolute right-32 md:right-36 lg:right-56 top-2 lg:top-6 with-transition"
            />
            <img
              src={imgPlanetCyan}
              alt="planet cyan"
              className="w-8 lg:w-16 h-8 lg:h-16 absolute right-3 md:right-9 lg:right-24 top-16 lg:top-20 with-transition"
            />
            <img
              src={imgPlanetPink}
              alt="planet pink"
              className="w-20 lg:w-36 h-20 lg:h-36 absolute right-10 md:right-36 lg:right-56 top-[7.5rem] lg:top-32 with-transition"
            />
          </div>
        </div>
      </div>
      <div className="container mx-auto">
        <div className="pt-12 grid grid-cols-2 items-center">
          <div className="col-span-2 md:col-span-1 px-4 md:px-8">
            <h2 className="font-bold text-2xl mb-5 text-navy-blue">
              our guiding force
            </h2>
            <p>
              The concept behind Forager Media Group is one of exploration and
              innovation. Much of the work we do requires outside-of-the-box
              thinking and what better way to do that than getting out of our
              comfort zone and seeing the world from a different angle?
            </p>
            <p className="my-4">
              Our team is from all different backgrounds offering a myriad of
              cultural perspectives. We feel that this diversity is a strength
              that gives us adaptability and agility for the projects that we
              work on.
            </p>
            <p>
              Often we have a local problem that needs fixing and one of our
              team can pull in an example for their city or a country they
              travelled to that can be reworked and implemented for the project
              to succeed. That is where we get our slogan: harvesting the best
              trends from around the world.
            </p>
          </div>
          <div className="col-span-2 md:col-span-1">
          <img
            src={peopleHappy}
            alt="people happy"
            className="mt-14 lg:hidden w-full md:rounded-l-lg"
          />
            <img
              src={peopleHappyDesktop}
              alt="people happy"
              className="hidden lg:flex w-full md:rounded-l-lg"
            />
          </div>
        </div>
        <div className="container mx-auto mt-14 lg:mt-32 px-4 grid grid-cols-2 gap-4 lg:gap-24 items-center">
          <div className="col-span-2 md:col-span-1 order-2 md:order-1 mx-auto mt-5">
            <img src={groupExplorer} alt="" />
          </div>
          <div className="col-span-2 md:col-span-1 order-1 md:order-2">
            <div className="md:px-0 md:my-auto">
              <h2 className="font-bold text-2xl mb-5 text-navy-blue">
                how we got here
              </h2>
              <p>
                Forager Media Group was founded by anthropologist and explorer,
                Bryce Mathew Watts in 2017 as an outlet for his passion for
                writing. Initially working with restaurants, food startups, and
                travel brands, Forager Media Group was a key source of digital
                inspiration for these first clients to showcase their stories in
                a more engaging way.
              </p>
              <p className="my-4">
                Now with a larger team and four focused divisions specializing
                in specific technical needs for our heritage partners, we are
                moving into a new collaborative era within the heritage sector.
                Where we saw individual attempts at tackling large projects
                prior to the COVID-19 pandemic, we see a new exciting trend of
                collaboration.
              </p>
              <p>
                Through our partnerships with individual institutions and
                regional organizations like the BC Museums Association, we are
                developing the potential for partners large and small to fulfill
                their mandates with high-quality results.
              </p>
            </div>
          </div>
        </div>
        <section className="px-4 lg:px-0 mt-20 md:my-28 grid grid-cols-2 md:grid-cols-5 gap-1 md:gap-3 justify-center text-center">
          <div className="grid md:col-span-1 place-items-center bg-bayside-cyan text-navy-blue rounded-3xl px-4 py-5 lg:px-4 lg:py-8">
            <h6 className="font-bold mb-4">be collaborative</h6>
            <p>work with communities to develop together.</p>
          </div>
          <div className="grid md:col-span-1 place-items-center bg-princeton-orange text-white rounded-3xl px-4 py-5 lg:px-4 lg:py-8">
            <h6 className="font-bold mb-4">embrace diversity</h6>
            <p>give space for groups historically left out.</p>
          </div>
          <div className="order-last md:order-none grid md:col-span-1 place-items-center bg-lime-green text-navy-blue rounded-3xl px-4 py-5 lg:px-4 lg:py-8">
            <h6 className="font-bold mb-4">be innovative</h6>
            <p>always look for ways to make it better.</p>
          </div>
          <div className="grid md:col-span-1 col-span-2 place-items-center bg-navy-blue text-white rounded-3xl px-4 py-5 lg:px-4 lg:py-8 text-center">
            <h6 className="font-bold mb-4">give back</h6>
            <p>always find ways to support communities.</p>
          </div>
          <div className="grid md:col-span-1 place-items-center bg-neon-pink  text-white rounded-3xl px-4 py-5 lg:px-4 lg:py-8">
            <h6 className="font-bold mb-4">be sensitive</h6>
            <p>don&apos;t forget the impact of past injustices.</p>
          </div>
        </section>
        <div className="items-center mt-20 mb-32 gap-y-14 grid grid-cols-2">
          <div className="col-span-2 md:col-span-1 h-80 md:h-80 w-full md:rounded-xl bg-cover bg-no-repeat bg-center" style={{backgroundImage:`url(${explorersDesktop})`}} />
          <div className="col-span-2 md:col-span-1 px-4 md:pl-9">
            <p>
              Forager Media Group has offices in Canada, Colombia, and Ireland,
              but every few months we have a new nomadic headquarters. We
              developed this idea as a way to make sure we didn’t become
              stagnant in our business processes. Each time one of our team
              relocates to a new city, they explore heritage organizations and
              take note of different styles and methods being used throughout
              the city.
            </p>
            <p className="my-4">
              This exploration keeps the team energized and lets them develop
              new skills for themselves, but it has already brought in new ideas
              for projects that we have worked on with partners.
            </p>
            <p>
              Whether it&apos;s a new guided tour application, an innovative way
              of disseminating information, or just a new artistic style to add
              to social media posts, the nomadic personality of Forager Media
              Group means that we will always have a new idea to present to our
              partners.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
