import React from "react";
import { Link } from "react-router-dom";

function BlogCard({ blog }) {
  return (
    <Link
      to={`/blog/${blog.slug}`}
      className="shadow-md overflow-hidden relative col-span-3 md:col-span-1 rounded-lg group"
    >
      <div className="hidden lg:block absolute top-0 w-full group-hover:bg-gradient-to-b h-full from-transparent to-black/50" />
      <div
        className="h-52 xl:h-96 bg-cover bg-no-repeat bg-center"
        style={{
          backgroundImage: `url(${blog.banner})`,
        }}
      />
      <div className="lg:hidden group-hover:block absolute bottom-0 w-full p-2 text-xs bg-dark-denim/50 lg:bg-transparent text-white">
        <h3>{blog.title}</h3>
        <span>Bryce Mathew Watts</span>
      </div>
    </Link>
  );
}

export default BlogCard;
