import React from 'react';
import ExplorerAbout from '../../../components/divisions/divisionDigital/about/ExplorerAbout';
import ServicesAbout from '../../../components/divisions/divisionDigital/about/ServiceAbout';
import WorkAbout from '../../../components/divisions/divisionDigital/about/WorkAbout';
import DivisionLayout from '../../../layouts/DivisionLayout';

export default function About() {
  const links = {
    about: '/division/digital/about',
    services: '/division/digital/services',
    work: '/division/digital/work',
  };
  return (
    <DivisionLayout colorClass="bg-princeton-orange" links={links} divisionName="digital">
      <ExplorerAbout />
      <WorkAbout />
      <ServicesAbout />
    </DivisionLayout>
  );
}
