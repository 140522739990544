import React from "react";
import { NavLink } from "react-router-dom";
import LogoForagerWhite from "../../assets/logos/logo-forager-white.svg";
import Logo from "../../assets/logos/logo-forager-media-group.png";

function NavegationDesktop() {
  return (
    <nav className="container mx-auto xl:px-24 font-bold flex justify-between items-center navbar">
      <NavLink to="/" className="ml-4 mt-4 md:mt-0">
        <img src={Logo} alt="Logo Forager Media Group" className="w-28 mh-4" />
      </NavLink>
      <ul className="hidden md:flex text-white app-nav-desktop-links">
        <li>
          <NavLink
            to="/about"
            className="px-4 lg:px-8 py-6 grid place-items-center"
          >
            about
          </NavLink>
        </li>
        <li>
          <div className="dropdown">
            <label
              id="division-link-group"
              tabIndex={0}
              htmlFor="division-desktop"
              className="cursor-pointer"
            >
              <NavLink
                to="/division"
                className="px-4 lg:px-8 py-6 grid place-items-center pointer-events-none"
              >
                division
              </NavLink>
            </label>
            <ul
              tabIndex={0}
              className="menu menu-compact dropdown-content p-2 shadow text-white bg-dark-denim w-[15rem] px-5 py-6 rounded-b-3xl"
            >
              <li>
                <NavLink to="/division/digital/about" className="flex gap-1">
                  <img src={LogoForagerWhite} alt="" className="w-16" />
                  <h4 className="text-princeton-orange font-bold pb-2 text-base">
                    digital
                  </h4>
                </NavLink>
              </li>
              <li>
                <NavLink to="/division/virtual/about" className="flex gap-1">
                  <img src={LogoForagerWhite} alt="" className="w-16" />
                  <h4 className="text-lime-green font-bold pb-2 text-base">
                    virtual
                  </h4>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/division/productions/about"
                  className="flex gap-1"
                >
                  <img src={LogoForagerWhite} alt="" className="w-16" />
                  <h4 className="text-bayside-cyan font-bold pb-2 text-base">
                    productions
                  </h4>
                </NavLink>
              </li>
              <li>
                <NavLink to="/division/publishing/about" className="flex gap-1">
                  <img src={LogoForagerWhite} alt="" className="w-16" />
                  <h4 className="text-neon-pink font-bold pb-2 text-base">
                    publishing
                  </h4>
                </NavLink>
              </li>
            </ul>
          </div>
        </li>
        <li>
          <NavLink
            to="/industries"
            className="px-4 lg:px-8 py-6 grid place-items-center"
          >
            industries
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/work"
            className="px-4 lg:px-8 py-6 grid place-items-center"
          >
            work
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/blog"
            className="px-4 lg:px-8 py-6 grid place-items-center"
          >
            blog
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/contact"
            className="px-4 lg:px-8 py-6 grid place-items-center"
          >
            contact
          </NavLink>
        </li>
      </ul>
    </nav>
  );
}

export default NavegationDesktop;
