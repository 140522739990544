import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ReactComponent as GrapichDesingIcon } from "../../assets/icons/graphic-desing.svg";
import { ReactComponent as ECommerceIcon } from "../../assets/icons/e-commerce.svg";
import { ReactComponent as SopportIcon } from "../../assets/icons/support.svg";
import { ReactComponent as ContentIcon } from "../../assets/icons/content.svg";
import { ReactComponent as BrandIdentityIcon } from "../../assets/icons/brand-identity.svg";
import { ReactComponent as MarketingIcon } from "../../assets/icons/marketing.svg";

function NextArrow(props) {
  const { onClick } = props;
  return (
    <div className="hidden xl:flex">
      <button
        type="button"
        className="absolute top-1/2 right-0 -translate-y-1/2 translate-x-9 w-14 h-12 bg-navy-blue rounded-r-full rounded-l-md shadow"
        onClick={onClick}
      >
        <i className="fa-solid fa-chevron-right text-white" />
      </button>
    </div>
  );
}
function PrevArrow(props) {
  const { onClick } = props;
  return (
    <div className="hidden xl:flex">
      <button
        type="button"
        className="z-10 absolute top-1/2 left-0 -translate-y-1/2 -translate-x-7 w-14 h-12 bg-navy-blue rounded-l-full rounded-r-md shadow"
        onClick={onClick}
      >
        <i className="fa-solid fa-chevron-left text-white" />
      </button>
    </div>
  );
}

export default function HomeServices() {
  const settings = {
    autoplay: true,
    autoplaySpeed: 5000,
    infinite: true,
    speed: 2500,
    initialSlide: 0,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 10000,
        settings: {
          arrows: true,
          slidesToShow: 4,
          slidesToScroll: 4,
        },
      },
      {
        breakpoint: 1280,
        settings: {
          arrows: true,
          centerMode: false,
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          arrows: false,
          slidesToShow: 2,
          centerMode: false,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 640,
        settings: {
          arrows: false,
          centerMode: true,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const services = [
    {
      bgColor: "princeton-orange",
      color: "white",
      icon: <GrapichDesingIcon className="mx-auto" />,
      heading: "graphic design",
      subGroup: [
        "Web Design",
        "B2B Materials",
        "Packaging",
        "E-books",
        "Trade Show Packages",
      ],
    },
    {
      bgColor: "lime-green",
      color: "navy-blue",
      icon: <ECommerceIcon className="mx-auto" />,
      heading: "e-commerce",
      subGroup: [
        "Woocommerce",
        "Shopify",
        "Product Sale Plugins",
        "Ticketing Plugins",
        "Reservations Plugins",
      ],
    },
    {
      bgColor: "neon-pink",
      color: "white",
      icon: <SopportIcon className="mx-auto" />,
      heading: "support",
      subGroup: [
        "Quick Response Time",
        "Whatsapp Chats",
        "Regular Meetings",
        "24 Hour Turnaround",
        "Customisable Client Support",
      ],
    },
    {
      bgColor: "bayside-cyan",
      color: "navy-blue",
      icon: <ContentIcon className="mx-auto" />,
      heading: "content",
      subGroup: [
        "Copywriting",
        "Press Releases",
        "SEO",
        "Social Media",
        "Ghostwriting",
      ],
    },
    {
      bgColor: "navy-blue",
      color: "white",
      icon: <BrandIdentityIcon className="mx-auto" />,
      heading: "brand identity",
      subGroup: [
        "Personality Development",
        "Logo Design",
        "SWOT Analysis",
        "Target Acquisition",
        "Multi Platform Messaging",
      ],
    },
    {
      bgColor: "dark-denim",
      color: "white",
      icon: <MarketingIcon className="mx-auto" />,
      heading: "marketing",
      subGroup: [
        "Automation",
        "Email",
        "Content",
        "Inbound",
        "Online Publicity",
      ],
    },
  ];

  return (
    <section className="lg:mx-14 py-14 md:py-32 bg-white">
      <div className="container md:mx-auto 2xl:mx-auto shadows">
        <Slider {...settings} className="w-full xl:mx-auto">
          {services.map((ele) => (
            <div key={ele.heading} className="px-3">
              <article
                className={`h-80 flex-shrink-0 card w-full mx-auto bg-${ele.bgColor} text-${ele.color} grid place-items-center`} 
              >
                <div className="card-body text-center">
                  <div className="h-14">{ele.icon}</div>
                  <h3 className="mb-1 text-2xl font-bold">{ele.heading}</h3>
                  <ol>
                    {ele.subGroup.map((subEle, i) => (
                      <li key={i}>{subEle}</li>
                    ))}
                  </ol>
                </div>
              </article>
            </div>
          ))}
        </Slider>
      </div>
    </section>
  );
}
