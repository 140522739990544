import React from 'react';
import DivisionLayout from '../../../layouts/DivisionLayout';
import About from '../../../components/divisions/divisionVirtual/about/About';
import Services from '../../../components/divisions/divisionVirtual/about/Service';
import Work from '../../../components/divisions/divisionVirtual/about/Work';

function VirtualAbout() {
  const links = {
    about: '/division/virtual/about',
    services: '/division/virtual/services',
    work: '/division/virtual/work',
  };
  return (
    <DivisionLayout colorClass="bg-lime-green" links={links} divisionName="virtual">
      <About />
      <Work />
      <Services />
    </DivisionLayout>
  );
}

export default VirtualAbout;
