import React from "react";
import { NavLink } from "react-router-dom";
import { ReactComponent as LogoForagerBlue } from "../../assets/logos/logo-forager-blue.svg";

function DivisionCard({ color, img, title, text }) {
  return (
    <NavLink
      to={`/division/${title}/about`}
      className={`rounded-[32px] h-fit-content w-[22rem] md:w-[19rem] xl:w-[18.3rem] bg-base-100 overflow-hidden text-center border-4 border-transparent ${color} group`}
    >
      <figure className="h-32 overflow-hidden">
        <img src={img} alt={`divisions ${title}`}  className="group-hover:scale-110 with-transition" />
      </figure>
      <div className="card-body bg-white h-full rounded-b-[1.8rem]">
        <div className="flex justify-center items-start mt-1">
          <LogoForagerBlue className="w-24" />
          <h3 className={`mx-2 text-${color} font-bold text-lg -mt-0.5`}>
            {title}
          </h3>
        </div>
        <p className="text-base text-center leading-5 mt-3 tracking-normal md:tracking-[-0.015em]">
          {text}
        </p>
      </div>
    </NavLink>
  );
}

export default DivisionCard;
