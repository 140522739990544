import React from "react";
import imgPlanetOrange from "../../../../assets/img/planets/orange.png";
import imgPlanetWhite from "../../../../assets/img/planets/white.png";
import pencilHolder from "../../../../assets/img/division/digital/pencil-holder.png";
import graphic1 from "../../../../assets/img/divisions/digital/graphic_design/1.png";
import graphic2 from "../../../../assets/img/divisions/digital/graphic_design/2.png";
import graphic3 from "../../../../assets/img/divisions/digital/graphic_design/3.png";
import web1 from "../../../../assets/img/divisions/digital/web_development/1.png";
import web2 from "../../../../assets/img/divisions/digital/web_development/2.png";
import web3 from "../../../../assets/img/divisions/digital/web_development/3.png";
import marketing1 from "../../../../assets/img/divisions/digital/marketing_strategy/1.png";
import marketing2 from "../../../../assets/img/divisions/digital/marketing_strategy/2.png";
import marketing3 from "../../../../assets/img/divisions/digital/marketing_strategy/3.png";
import megaphone from "../../../../assets/img/division/digital/megaphone.png";
import cube from "../../../../assets/img/division/digital/cube.png";

function DivisionDigitalServices() {
  return (
    <section>
      <div className="bg-navy-blue">
        <div className="container mx-auto relative py-16 lg:pt-9 lg:pb-12">
          <h1 className="text-3xl md:text-6xl font-bold text-center xl:text-left xl:pl-24 text-princeton-orange">
            <span className="text-white">our </span>
            services
          </h1>
          <img
            src={imgPlanetWhite}
            alt="planet white"
            className="w-14 h-14 absolute left-4 xl:left-[51rem] 2xl:left-[58rem] top-5 xl:top-8"
          />
          <img
            src={imgPlanetOrange}
            alt="planet orange"
            className="w-7 xl:w-36 aspect-square absolute left-20 xl:left-[40rem] top-28 xl:top-8"
          />
          <img
            src={imgPlanetOrange}
            alt="planet orange"
            className="w-9 xl:w-28 aspect-square absolute right-28 xl:right-[12.5rem] 2xl:right-[16.5rem] top-4 xl:top-4"
          />
          <img
            src={imgPlanetWhite}
            alt="planet white"
            className="w-5 xl:w-14 aspect-square absolute right-9 xl:right-20 top-12 xl:top-16"
          />
          <img
            src={imgPlanetOrange}
            alt="planet orange"
            className="w-16 xl:w-20 aspect-square absolute right-5 xl:-right-8 top-28 xl:-top-7"
          />
        </div>
      </div>
      <div
        id="brand-development"
        className="bg-dark-denim division-service-ids"
      >
        <div className="container mx-auto grid grid-cols-2 grid-rows-auto gap-3 px-4 py-14 lg:py-20">
          <div className="order-1 col-span-2 lg:col-span-1 max-w-xl mx-auto">
            <h2 className="mb-5 text-3xl font-bold text-center text-princeton-orange">
              brand
              <span className="text-white"> development</span>
            </h2>
          </div>
          <div className="order-2 col-span-2 lg:col-span-1 row-span-2 flex gap-2 w-full max-w-xl max-h-96 mx-auto mb-10 lg:mb-0">
            <img src={pencilHolder} alt="" className="w-1/3" />
            <img src={megaphone} alt="" className="translate-y-7 w-1/3" />
            <img src={cube} alt="" className="w-1/3" />
          </div>
          <div className="order-3 col-span-2 lg:col-span-1 max-w-xl mx-auto">
            <p className="text-white flex flex-col gap-4 pb-4">
              Personalities are how we decide whether we like to spend time with
              a new friend or not, the same is true for your brand. Whether we
              work with a small local heritage society or a large regional
              tourism brand offering cultural tours, the need to portray a
              distinct personality to attract the right audience is no less
              important.
              <span className="block">
                Forager Digital’s marketing strategists and graphic designers
                will help you define your brand. Through interviews, research,
                and conceptual planning, we work in close collaboration with
                your team to develop the perfect personality for your
                organization. Logos, branded colours, fonts, and everything else
                needed for your team to know exactly how to best represent
                yourself.
              </span>
              <span className="block">
                When the brand development process is complete, your team will
                have a detailed Brand Guideline to inform every social media
                post, newsletter design, and exhibition panel. Clarity of
                messaging comes when you know how to express your brand.
              </span>
            </p>
          </div>
        </div>
      </div>
      <div id="graphic-design" className="bg-white division-service-ids">
        <div className="container mx-auto grid grid-cols-2 grid-rows-auto gap-3 px-4 py-14">
          <div className="order-1 lg:order-2 col-span-2 lg:col-span-1 max-w-xl mx-auto">
            <h2 className="mb-5 text-3xl font-bold text-center text-princeton-orange">
              graphic
              <span className="text-dark-denim"> design</span>
            </h2>
          </div>
          <div className="pr-4 order-2 lg:order-1 col-span-2 lg:col-span-1 row-span-2 flex gap-2 w-full max-w-xl max-h-96 mx-auto mb-10 lg:mb-0">
            <img src={graphic1} alt="" className="w-1/3" />
            <img src={graphic2} alt="" className="translate-y-7 w-1/3" />
            <img src={graphic3} alt="" className="w-1/3" />
          </div>
          <div className="order-3 col-span-2 lg:col-span-1 max-w-xl mx-auto">
            <p className="flex flex-col gap-4 pb-4">
              As the saying goes, a picture is worth a thousand words. Great
              graphic design elevates a brand’s public perception and conveys a
              whole story. Whether we work on social media posts, in-house
              displays, or website visuals, the need to ensure each showcases
              your values and quality remains the same.
              <span className="block">
                Forager Digital’s graphic designers and illustrators will help
                you bring your project to life. Once we understand your brand
                and the goals of the project, our team gets to work coming up
                with a handful of design concepts to make sure we are on the
                right track. With your input, we design the best visual elements
                to capture the essence of your project, and throughout each
                project, the Forager Digital team stays in close contact with
                you for updates. At the end of your project, you have all
                necessary versions of your designs ready to utilize in any
                number of formats.
              </span>
            </p>
          </div>
        </div>
      </div>
      <div id="web-development" className="bg-dark-denim division-service-ids">
        <div className="container mx-auto grid grid-cols-2 grid-rows-auto gap-3 px-4 py-14">
          <div className="order-1 col-span-2 lg:col-span-1 max-w-xl mx-auto">
            <h2 className="mb-5 text-3xl font-bold text-center text-princeton-orange">
              web
              <span className="text-white"> development</span>
            </h2>
          </div>
          <div className="pr-4 order-2 col-span-2 lg:col-span-1 row-span-2 flex gap-2 w-full max-w-xl max-h-96 mx-auto mb-10 lg:mb-0">
            <img src={web1} alt="" className="w-1/3" />
            <img src={web2} alt="" className="translate-y-7 w-1/3" />
            <img src={web3} alt="" className="w-1/3" />
          </div>
          <div className="order-3 col-span-2 lg:col-span-1 max-w-xl mx-auto">
            <p className="text-white flex flex-col gap-4 pb-4">
              A professional website is only becoming more important. While
              there are options out there to create cut-and-paste sites, that
              limits the design and the functionality of your website. Whether
              you are looking to refresh your existing website or create
              something completely new, the same effort goes into making sure
              that your website becomes one of the strongest elements to attract
              visitors. Audience generation starts online and with a good first
              impression, anything is possible.
              <span className="block">
                Forager Digital’s web team is experienced, detailed, and
                innovative in its approach. It has worked on small landing page
                sites and large multifaceted platforms. We know the industry we
                work in requires a variety of different solutions and Forager
                Digital’s team is ready to deliver.
              </span>
              <span className="block">
                Each project is slightly different, but the process is the same,
                good communication, in-depth functionality research, and
                dedicated support to ensure each project finishes on time with
                satisfied partners.
              </span>
            </p>
          </div>
        </div>
      </div>
      <div id="marketing-strategy" className="bg-white division-service-ids">
        <div className="container mx-auto grid grid-cols-2 grid-rows-auto gap-3 px-4 py-14 lg:pb-28">
          <div className="order-1 lg:order-2 col-span-2 lg:col-span-1 max-w-xl mx-auto">
            <h2 className="mb-5 text-3xl font-bold text-center text-princeton-orange">
              marketing
              <span className="text-dark-denim"> strategy</span>
            </h2>
          </div>
          <div className="pr-4 order-2 lg:order-1 col-span-2 lg:col-span-1 row-span-2 flex gap-2 w-full max-w-xl max-h-96 mx-auto mb-10 lg:mb-0">
            <img src={marketing1} alt="" className="w-1/3" />
            <img src={marketing2} alt="" className="translate-y-7 w-1/3" />
            <img src={marketing3} alt="" className="w-1/3" />
          </div>
          <div className="order-3 col-span-2 lg:col-span-1 max-w-xl mx-auto">
            <p className="flex flex-col gap-4 pb-4">
              Engaging with your audience, whether in person or online, is an
              important way to drive visitor traffic and increase revenue.
              Whether you host events or sell products in your gift show,
              knowing how to market yourself is the best way to ensure you
              aren’t wasting time or money.
              <span className="block">
                Forager Digital’s marketing team has a strong background in
                competitor research, brand messaging, and marketing tactics for
                your organization. It will work with your team to understand who
                you are, who your competition is, who your target audience is,
                and finally, what are the best ways to get your organization
                front and centre.
              </span>
              <span className="block">
                From the very beginning, Forager Digital’s marketers will work
                closely with you to formulate a personalized strategy that meets
                your organization’s goals and at the end of the process, your
                team will have a document with background and tactics to guide
                their marketing activities for the next 12 months.
              </span>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default DivisionDigitalServices;
