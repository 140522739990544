import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createContactMail } from "../../actions/contactMailActions";
import { CONTACTMAIL_CREATE_RESET } from "../../constants/contactMailConstants";
import mapsImg from "../../assets/img/contact/map.svg";

export default function Contact() {
  const dispatch = useDispatch();
  const contactMailCreate = useSelector((state) => state.contactMailCreate);
  const { success } = contactMailCreate;

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");

  const nameHandler = (e) => {
    setName(e.target.value);
  };

  const emailHandler = (e) => {
    setEmail(e.target.value);
  };

  const subjectHandler = (e) => {
    setSubject(e.target.value);
  };

  const messageHandler = (e) => {
    setMessage(e.target.value);
  };

  const submitHandler = (e) => {
    e.preventDefault();

    if (
      name.length > 0 && email.length > 0 && subject.length > 0 && message.length > 0
    ) {
      const contactMail = {
        name,
        email,
        subject,
        message,
      };
      dispatch(createContactMail(contactMail));
    }
  };

  useEffect(() => {
    if (success) {
      setName("");
      setEmail("");
      setSubject("");
      setMessage("");
      alert("Email sent successfully! Thank you for your interest!");

      setTimeout(() => {
        dispatch({ type: CONTACTMAIL_CREATE_RESET });
      }, 7000);
    }
  }, [success, dispatch]);

  return (
    <section className="container mx-auto">
      <div className="grid grid-cols-2 grid-rows-auto gap-x-6 container mx-auto px-4">
        <div className="font-bold col-span-2 order-1 text-center pt-14 pb-9">
          let&apos;s get this show on the road!
          <h1 className="text-3xl lg:text-6xl text-navy-blue">contact us</h1>
        </div>
        <div className="col-span-2 lg:col-span-1 order-2 lg:order-3 md:pl-8">
          <h2 className="font-bold text-neon-pink text-2xl">how we can help</h2>
          <h3 className="font-bold text-xl">lets talk!</h3>
          <p className="tracking-[-0.02em] md:tracking-normal my-4">
            We harvest the very best stories and cook up the best project
            outcomes no matter where we are in the world. Whether trekking the
            rainforests of Colombia, the deserts of Arizona, or ascending the
            heights of the Austrian Alps, we are ready to produce results for
            you. Flexible, adaptable and innovative, our team is ready to meet
            your digital, virtual, production, or publishing needs. We
            can&apos;t wait to hear from you!
          </p>
          <p className="md:pt-4">
            <span className="font-bold">Phone / WhatsApp: </span>
            +1 250 569 4401
          </p>
          <p className="mt-4">
            <span className="font-bold">General Enquiries: </span>
            info@foragermediagroup.com
          </p>
          <p className="mt-4">
            <span className="font-bold">Sales Enquiries: </span>
            sales@foragermediagroup.com
          </p>
        </div>
        <div className="col-span-2 lg:col-span-1 lg:row-span-2 order-3 lg:order-2 mt-[4.8rem] mb-12 lg:my-0">
          <img src={mapsImg} alt="forager gives back" className="w-full" />
        </div>
        <div className="md:pl-8 xl:pb-5 lg:mt-5 col-span-2 lg:col-span-1 order-4 flex flex-col lg:flex-row justify-between gap-8 items-center text-center md:text-left">
          <h3 className="text-2xl lg:text-base font-bold text-princeton-orange">
            north america
            <span className="text-base font-normal block text-dark-denim">
              Gibsons, Canada
            </span>
          </h3>
          <h3 className="text-2xl lg:text-base font-bold text-lime-green">
            south america
            <span className="text-base font-normal block text-dark-denim">
              Bogotá, Colombia
            </span>
          </h3>
          <h3 className="text-2xl lg:text-base font-bold text-neon-pink">
            europe
            <span className="text-base font-normal block text-dark-denim">
              Cork, Ireland
            </span>
          </h3>
        </div>
        <div className="col-span-2 order-5">
          <h4 className="text-neon-pink font-bold text-xl mt-12 mb-3">
            get in touch
          </h4>
          <p>
            Fields marked with an
            <span className="text-red-600"> * </span>
            are required
          </p>
          <form
            className="mt-10 lg:mt-8 mb-24 grid grid-cols-2 gap-5"
            autoComplete="off"
            onSubmit={submitHandler}
          >
            <label className="font-bold col-span-2 md:col-span-1">
              name
              <span className="text-red-600">*</span>
              <input
                type="text"
                name="name"
                className="h-14 rounded-xl border w-full mt-2 p-4"
                value={name}
                required
                onChange={nameHandler}
              />
            </label>
            <label className="font-bold col-span-2 md:col-span-1">
              email
              <span className="text-red-600">*</span>
              <input
                type="email"
                name="email"
                className="h-14 rounded-xl border w-full mt-2 p-4"
                value={email}
                onChange={emailHandler}
                required
              />
            </label>
            <label className="font-bold col-span-2 ">
              subject
              <span className="text-red-600">*</span>
              <input
                type="text"
                name="subject"
                className="h-14 rounded-xl border w-full mt-2 p-4"
                value={subject}
                onChange={subjectHandler}
                required
              />
            </label>
            <label className="font-bold col-span-2">
              message
              <span className="text-red-600">*</span>
              <textarea
                name="message"
                className="h-32 rounded-xl border w-full mt-2 p-4"
                value={message}
                onChange={messageHandler}
                required
              />
            </label>
            <button
              type="submit"
              className="col-span-2 mt-16 lg:mt-5 bg-dark-denim hover:bg-white w-40 h-12 rounded-3xl font-bold text-white hover:text-dark-denim mx-auto shadow-lg"
              onClick={submitHandler}
            >
              submit
            </button>
          </form>
        </div>
      </div>
    </section>
  );
}
