import React from 'react';
import DivisionLayout from '../../../layouts/DivisionLayout';
import Work from '../../../components/divisions/divisionPublishing/Work/Work';

function WorkPublishing() {
  const links = {
    about: '/division/publishing/about',
    services: '/division/publishing/services',
    work: '/division/publishing/work',
  };
  return (
    <DivisionLayout colorClass="bg-neon-pink" links={links} divisionName="publishing">
      <Work />
    </DivisionLayout>
  );
}

export default WorkPublishing;
