import React from "react";
import CardDigitalImg from "../../assets/img/home/card-digital-home-forager-media-group.png";
import CardProductionsImg from "../../assets/img/home/card-productions-home-forager-media-group.png";
import CardPublishingImg from "../../assets/img/home/card-publishing-home-forager-media-group.png";
import CradVirtualImg from "../../assets/img/home/card-virtual-home-forager-media-group - copia.png";
import DivisionCard from "./DivisionCard";

export default function HomeDivisions() {
  const divisions = [
    {
      title: "digital",
      color: "princeton-orange",
      img: CardDigitalImg,
      text: "Powered by innovative graphic designers, web developers, anddigital marketers, our digital division is ready to take your brand to the next level. Whether you need a new website, custom branding, or promotional help, our team is ready.",
    },
    {
      title: "virtual",
      color: "lime-green",
      img: CradVirtualImg,
      text: "Powered by experienced virtual and augmented reality developers, our virtual division is ready to help you take advantage of this new technology. Whether you need a VR tour, an AR scavenger hunt, or virtual learning resources, our team is ready.",
    },
    {
      title: "productions",
      color: "bayside-cyan",
      img: CardProductionsImg,
      text: "Powered by Powered by expert producers, editors, and audio engineers, our production division is ready to turn your storyboard into an amazing experience. Whether you need help with a podcast, a YouTube series, or promotional videos, our team is ready.",
    },
    {
      title: "publishing",
      color: "neon-pink",
      img: CardPublishingImg,
      text: "Powered by creative writers, eagle-eyed editors and publishing specialists, our publishing division is ready to turn your idea into a reality. Whether you need help with your online magazine, or getting your book ready for publishing, our team is ready.",
    },
  ];
  return (
    <section className="bg-dark-denim pt-28 md:pt-12 pb-20 -mt-5 home-divisions">
      <h2 className="container tracking-tighter md:tracking-normal text-white text-center font-bold text-2xl md:text-3xl px-4 lg:px-0 lg:w-[56%] mx-auto pb-16">
        we are a team that takes advantage of
        <span className="text-princeton-orange"> remote working </span>
        with a
        <span className="text-lime-green"> wide range of specialities </span>
        to produce
        <span className="text-neon-pink"> measurable benefits </span>
        for your institution
      </h2>
      <div className="flex flex-wrap justify-center gap-5 md:px-20 lg:px-40 xl:px-0">
        {divisions.map((ele, index) => (
          <DivisionCard
            key={index}
            color={ele.color}
            img={ele.img}
            title={ele.title}
            text={ele.text}
          />
        ))}
      </div>
    </section>
  );
}
