import React from "react";
import PromoteCard from "../../PromoteCard";
import iconStart from "../../../../assets/img/division/digital/iconStart.svg";
import notebook from "../../../../assets/img/division/digital/notebook.svg";
import award from "../../../../assets/img/division/digital/award.svg";
import bulb from "../../../../assets/img/division/digital/bulb.svg";
import manWriting from "../../../../assets/img/division/digital/man-writing.png";

function WorkAbout() {
  const steps = [
    {
      icon: iconStart,
      heading: "what we will do for you",
      text: "We aim to: create a custom brand identity. This sets the colours, typography and guidelines for all visual materials to ensure no matter what it is, you are instantly recognizable.",
    },
    {
      icon: notebook,
      heading: "create a marketing plan",
      text: "We aim to: analyze your goals and find the best way to put your institution on top. We create a step-by-step guide to get your team engaging your audience and keep you growing.",
    },
    {
      icon: award,
      heading: "make your website pop",
      text: "We aim to: captivate the attention of potential visitors. Websites need all the information an audience requires, but that information needs to inspire with stunning visuals.",
    },
    {
      icon: bulb,
      heading: "exhibit at your best",
      text: "We aim to: create show-stopping exhibition materials for your institution. Don’t just put up a sign, get graphic elements that make a statement and ensure everyone remembers you.",
    },
  ];
  return (
    <section className="bg-dark-denim -mt-1 pt-16 lg:pt-0">
      <h1 className="text-princeton-orange text-2xl md:text-3xl text-center font-bold lg:pb-3">
        what
        <span className="text-white"> we will </span>
        do for you
      </h1>
      <div className="px-4 lg:container lg:mx-auto grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-5 pt-14 pb-16 lg:pb-24  text-center">
        {steps.map((ele, index) => (
          <PromoteCard
            key={index}
            icon={ele.icon}
            heading={ele.heading}
            text={ele.text}
          />
        ))}
      </div>
      <div className="bg-white md:py-24">
        <div className="grid grid-cols-1 md:grid-cols-2 items-center gap-y-10 lg:gap-20 lg:container lg:mx-auto">
          <div
            className="md:rounded-3xl w-full mx-0 h-80 division-about"
            style={{
              backgroundImage: `url(${manWriting})`,
            }}
          ></div>
          <p className="mx-4 pb-3">
            <span className="font-bold">Forager Digital </span>
            is the division that takes the essence of our clients and runs with
            it. Whether we are designing promotional materials, strategizing the
            best marketing solutions, or developing a new website, we know that
            working closely with our clients and truly understanding their
            story, their mandate, and their community is key to achieving the
            results they deserve.
            <span className="text-princeton-orange text-2xl text-left font-bold block my-6">
              creativity to inspire new audiences
            </span>
            Our designers, developers, and marketers are ready to work with
            clients big or small to bring their story front of mind and ensure
            that their audiences continue to see the value in their work and the
            collection of materials they house.
          </p>
        </div>
      </div>
    </section>
  );
}

export default WorkAbout;
