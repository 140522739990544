import React from "react";
import { NavLink } from "react-router-dom";
import ghostwriting from "../../../../assets/img/division/publishing/ghostwriting.png";
import copyediting from "../../../../assets/img/division/publishing/copyediting.png";
import digitalMagazines from "../../../../assets/img/division/publishing/digital-magazines.png";
import book from "../../../../assets/img/division/publishing/book.png";
import rocket from "../../../../assets/img/division/digital/rocket.svg";
import ServiceCard from "../../ServiceCard";

function AboutServices() {
  const services = [
    {
      img: ghostwriting,
      service: "ghostwriting",
      text: `Our team works with you to write your next written masterpiece. Ghostwriters have the skills to turn a phrase and make that book a success. We will embody your voice and make sure that you get all the credit.`,
    },
    {
      img: copyediting,
      service: "copyediting",
      text: `Our team works with your in-house writers to act as a second pair of eyes. Our copyeditors are the eagle-eyed experts that will ensure your publication is ready to promote to your audience whether it is online or offline.`,
    },
    {
      img: digitalMagazines,
      service: "digital magazines",
      text: `Our team works with your programmers to plan new digital magazines and upgrade your existing ones. We have writers, editors, and designers ready to bring together your content in a professional digital publication ready to publish to sites like Joomag and Issuu.`,
    },
    {
      img: book,
      service: "book publishing",
      text: `Our team works with you to publish your next big hit. A great way to compliment your exhibit is with an e-books or a physical book. Let us create a small book to offer to visitors to take home the memories of their last visit and continue learning from your expertise.`,
    },
  ];

  return (
    <div className="bg-white">
      <div className="container mx-auto px-0">
        <h1 className="font-bold text-2xl text-center -mt-1 lg:pt-5 pt-12 pb-14 md:pt-0">
          our
          <span className="text-neon-pink"> publishing </span>
          services
        </h1>
        <section className="px-4 lg:container mx-auto grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-5">
          {services.map((ele, index) => (
            <ServiceCard
              key={index}
              division="publishing"
              img={ele.img}
              service={ele.service}
              text={ele.text}
            />
          ))}
        </section>
      </div>
      <div className="overflow-hidden relative mt-20">
        <div className="container mx-auto">
          <div className="sm:w-1/2">
            <p className="px-4 md:mt-5 lg:mt-14">
              We have worked with clients across three continents, from big name
              attractions to small community-led institutions. No matter the
              size, each client we work with gets the same attention to detail,
              experience-driven creativity, and respect towards culturally
              sensitive materials to deliver a project that not only impresses
              our clients but impresses their audiences.
            </p>
            <NavLink
              to="/division/publishing/work"
              className="shadow-md mt-14 mb-20 lg:mb-28 w-56 h-12 hover:bg-dark-denim bg-neon-pink text-white rounded-3xl mx-auto sm:mx-4 grid place-items-center font-bold"
            >
              see portfolio
            </NavLink>
          </div>
        </div>
        <div className="pl-4 bg-neon-pink  pb-10 md:py-12">
          <div className="container mx-auto">
            <img
              src={rocket}
              alt="rocket"
              className="-rotate-90 sm:rotate-0 sm:absolute sm:bottom-0 sm:-right-48 lg:right-0 sm:max-h-full"
            />
            <div className="sm:w-1/2 xl:pt-10">
              <p className="pr-4 pt-8 font-bold">
                If you are looking for a team that has
                <span className="text-white"> the experience and passion </span>
                to truly understand the needs of the heritage sector,
                <span className="text-white">
                  {" "}
                  Forager Productions is your best resource.{" "}
                </span>
                We are committed to working with institutions of all sizes and
                together with our partner Forager Financial Services, will work
                to find funding that supports
                <span className="text-white"> your next project.</span>
              </p>
              <NavLink
                to="/contact"
                className="shadow-md mt-14 lg:mt-16 mb-20 xl:mb-9 w-48 h-12 hover:bg-white bg-dark-denim text-white hover:text-dark-denim rounded-3xl mx-auto sm:mx-4 grid place-items-center font-bold"
              >
                contact us
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutServices;
