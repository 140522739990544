import React from "react";
import imgPlanetCyan from "../../../../assets/img/planets/cyan.png";
import imgPlanetWhite from "../../../../assets/img/planets/white.png";
import mv1 from "../../../../assets/img/divisions/productions/marketing_videos/1.png";
import mv2 from "../../../../assets/img/divisions/productions/marketing_videos/2.png";
import mv3 from "../../../../assets/img/divisions/productions/marketing_videos/3.png";
import ap1 from "../../../../assets/img/divisions/productions/audio_productions/1.png";
import ap2 from "../../../../assets/img/divisions/productions/audio_productions/2.png";
import ap3 from "../../../../assets/img/divisions/productions/audio_productions/3.png";
import lfp1 from "../../../../assets/img/divisions/productions/long_form_productions/1.png";
import lfp2 from "../../../../assets/img/divisions/productions/long_form_productions/2.png";
import lfp3 from "../../../../assets/img/divisions/productions/long_form_productions/3.png";
import animation1 from "../../../../assets/img/divisions/productions/animations/1.png";
import animation2 from "../../../../assets/img/divisions/productions/animations/2.png";
import animation3 from "../../../../assets/img/divisions/productions/animations/3.png";

function Services() {
  return (
    <section className="bg-white">
      <div className="bg-navy-blue">
        <div className="container mx-auto relative py-16 lg:pt-9 lg:pb-12">
          <h1 className="text-3xl md:text-6xl font-bold text-center xl:text-left xl:pl-24 text-bayside-cyan">
            <span className="text-white">our </span>
            services
          </h1>
          <img
            src={imgPlanetWhite}
            alt="planet white"
            className="w-14 h-14 absolute left-4 xl:left-[51rem] 2xl:left-[58rem] top-5 xl:top-8"
          />
          <img
            src={imgPlanetCyan}
            alt="planet orange"
            className="w-7 xl:w-36 aspect-square absolute left-20 xl:left-[40rem] top-28 xl:top-8"
          />
          <img
            src={imgPlanetCyan}
            alt="planet orange"
            className="w-9 xl:w-28 aspect-square absolute right-28 xl:right-[12.5rem] 2xl:right-[16.5rem] top-4 xl:top-4"
          />
          <img
            src={imgPlanetWhite}
            alt="planet white"
            className="w-5 xl:w-14 aspect-square absolute right-9 xl:right-20 top-12 xl:top-16"
          />
          <img
            src={imgPlanetCyan}
            alt="planet orange"
            className="w-16 xl:w-20 aspect-square absolute right-5 xl:-right-8 top-28 xl:-top-7"
          />
        </div>
      </div>
      <div id="marketing-videos" className="bg-dark-denim division-service-ids">
        <div className="container mx-auto grid grid-cols-2 grid-rows-auto gap-3 px-4 py-14 lg:py-20">
          <div className="order-1 col-span-2 lg:col-span-1 max-w-xl mx-auto">
            <h2 className="mb-5 text-3xl font-bold text-center text-bayside-cyan">
              marketing
              <span className="text-white"> videos</span>
            </h2>
          </div>
          <div className="pr-4 order-2 col-span-2 lg:col-span-1 row-span-2 flex gap-2 w-full max-w-xl max-h-96 mx-auto mb-10 lg:mb-0">
            <img src={mv1} alt="" className="w-1/3" />
            <img src={mv2} alt="" className="translate-y-7 w-1/3" />
            <img src={mv3} alt="" className="w-1/3" />
          </div>
          <div className="order-3 col-span-2 lg:col-span-1 max-w-xl mx-auto">
            <p className="text-white flex flex-col gap-4 pb-4">
              Showcasing your organization is best done with something visually
              captivating. Producing a promotional video, even 30 seconds in
              length, can be an eye-catching way to let your audience know about
              your upcoming event, a new exhibition, or even just about your
              organization in general. Statistic after statistic shows that
              video content on social media has a higher chance of being
              interacted with than a static image and a significantly higher
              chance compared to a text post.
              <span className="block">
                Forager Productions’ video editors are skilled at putting
                together a captivating bite-sized video that gets both a message
                across and showcases the quality of your offering. They work
                with your team to plan, film, and publish your video content.
                Regardless of where the final destination of the video will be,
                Forager Productions will provide every format necessary to
                ensure you can post your promotional videos anywhere in order to
                capture your audience’s attention.
              </span>
            </p>
          </div>
        </div>
      </div>
      <div id="audio-productions" className="bg-white division-service-ids">
        <div className="container mx-auto grid grid-cols-2 grid-rows-auto gap-3 px-4 py-14">
          <div className="order-1 lg:order-2 col-span-2 lg:col-span-1 max-w-xl mx-auto">
            <h2 className="mb-5 text-3xl font-bold text-center text-bayside-cyan">
              audio
              <span className="text-dark-denim"> productions</span>
            </h2>
          </div>
          <div className="pr-4 order-2 lg:order-1 col-span-2 lg:col-span-1 row-span-2 flex gap-2 w-full max-w-xl max-h-96 mx-auto mb-10 lg:mb-0">
            <img src={ap1} alt="" className="w-1/3" />
            <img src={ap2} alt="" className="translate-y-7 w-1/3" />
            <img src={ap3} alt="" className="w-1/3" />
          </div>
          <div className="order-3 col-span-2 lg:col-span-1 max-w-xl mx-auto">
            <p className="flex flex-col gap-4 pb-4">
              Audio content is an older format that video and it shows no sign
              of going away. Podcasts have become the newest craze and with more
              and more brands, professionals, and organizations getting set up
              with their own, it is time you considered it. Whether listening on
              the bus to work or spending time relaxing in the evening, audio
              content is a great way to share your story with an audience
              actively looking for quality content.
              <span className="block">
                Forager Productions’ team has the skills to help you plan,
                record, and publish your next piece of audio mastery. Regardless
                of the intended audience, the same care needs to be put into the
                story development, the specific equipment used, and the style of
                editing employed. Throughout the whole process, you will have
                the opportunity to guide the team on the intended outcome. The
                final stage of publishing can be to your own site or a popular
                hosting platform, all options will be presented for you to make
                an informed decision on how best to optimize your reach.
              </span>
            </p>
          </div>
        </div>
      </div>
      <div
        id="long-form-productions"
        className="bg-dark-denim division-service-ids"
      >
        <div className="container mx-auto grid grid-cols-2 grid-rows-auto gap-3 px-4 py-14">
          <div className="order-1 col-span-2 lg:col-span-1 max-w-xl mx-auto">
            <h2 className="mb-5 text-3xl font-bold text-center text-white">
              long-form
              <span className="text-bayside-cyan"> productions</span>
            </h2>
          </div>
          <div className="pr-4 order-2 col-span-2 lg:col-span-1 row-span-2 flex gap-2 w-full max-w-xl max-h-96 mx-auto mb-10 lg:mb-0">
            <img src={lfp1} alt="" className="w-1/3" />
            <img src={lfp2} alt="" className="translate-y-7 w-1/3" />
            <img src={lfp3} alt="" className="w-1/3" />
          </div>
          <div className="order-3 col-span-2 lg:col-span-1 max-w-xl mx-auto">
            <p className="text-white flex flex-col gap-4 pb-4">
              Anything over 10 minutes in length is considered a long-form
              production. By venturing into this format, you have the
              opportunity to add a depth of knowledge that bite-sized video
              content can’t. Whether it is meant for your own YouTube channel or
              something longer that can be sold to a streaming service like
              Netflix, the ability to reach new audiences remains the same.
              <span className="block">
                Forager Productions’ team will help you in all aspects of your
                production journey. If all you have is an idea, they will work
                with you on the storyboard, script writing, filming, and
                editing. Likewise, if there are only technical aspects that you
                need help with, the team can provide this assistance as well. At
                the end of your long-form production journey, you can be sure
                that you will have a finished product that showcases the very
                best of what your story has to introduce to your audien
              </span>
            </p>
          </div>
        </div>
      </div>
      <div id="animations" className="bg-white division-service-ids">
        <div className="container mx-auto grid grid-cols-2 grid-rows-auto gap-3 px-4 py-14 lg:pb-28">
          <div className="order-1 lg:order-2 col-span-2 lg:col-span-1 max-w-xl mx-auto">
            <h2 className="mb-5 text-3xl font-bold text-center text-bayside-cyan">
              animations
            </h2>
          </div>
          <div className="pr-4 order-2 lg:order-1 col-span-2 lg:col-span-1 row-span-2 flex gap-2 w-full max-w-xl max-h-96 mx-auto mb-10 lg:mb-0">
            <img src={animation1} alt="" className="w-1/3" />
            <img src={animation2} alt="" className="translate-y-7 w-1/3" />
            <img src={animation3} alt="" className="w-1/3" />
          </div>
          <div className="order-3 col-span-2 lg:col-span-1 max-w-xl mx-auto">
            <p className="flex flex-col gap-4 pb-4">
              Something illustrated and colourful is a great way to set yourself
              apart. Whether you are looking to develop an animated video for
              promotional content or to create long-form animations as part of a
              new program to engage with children, animation brings something
              exciting to the table.
              <span className="block">
                Forager Productions’ animators will help you plan, visualize,
                and produce your next animation project. The purpose of
                animation can be to instill a sense of nostalgia in your adult
                audiences or to excite kid’s with your subject matter. Either
                way, a good animation can take you beyond the limits of what
                live-action video can. Exploring below the surface of the earth
                or topics from deep in history, Forager Productions’ animators
                will take your idea and make it a reality. At the end of each
                project, you will have the files necessary to publish your
                animation on whatever platform works best for your audience.
              </span>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Services;
