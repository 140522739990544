import React from 'react';
import Work from '../../../components/divisions/divisionVirtual/work/Work';
import DivisionLayout from '../../../layouts/DivisionLayout';

function VirtualWork() {
  const links = {
    about: '/division/virtual/about',
    services: '/division/virtual/services',
    work: '/division/virtual/work',
  };
  return (
    <DivisionLayout colorClass="bg-lime-green" links={links} divisionName="virtual">
      <Work />
    </DivisionLayout>
  );
}

export default VirtualWork;
