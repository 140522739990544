import React from "react";
import { NavLink } from "react-router-dom";
import headerImg from "../../assets/img/home/forager-media-group-the-perfect-way-to-stay-relevant.png";
import montanaImgDesktop from "../../assets/img/home/forager-media-group-the-perfect-way-to-stay-relevant-desktop.png";
import rocketGif from "../../assets/img/home/rocket-animation.gif";
import starsGif from "../../assets/img/home/start-animation.gif";
import bulbGif from "../../assets/img/home/bulb-animation.gif";
import hands from "../../assets/icons/hands.svg";
import mapsImg from "../../assets/img/home/map-bogota-colombia.svg";
import mapsDesktop from "../../assets/img/home/map-bogota-desktop.svg";

export default function HomeGetInspired() {
  return (
    <section className="container mx-auto">
      <div className="mb-14 lg:mb-32 lg:flex items-center">
        <figure>
          <img
            src={headerImg}
            alt="The perfect way to stay relevant"
            className="lg:hidden lg:rounded-r-3xl w-full lg:w-11/12 max-w-6xl"
          />
        </figure>
        <figure>
          <img
            src={montanaImgDesktop}
            alt="The perfect way to stay relevant"
            className="hidden lg:flex lg:rounded-3xl"
          />
        </figure>
        <div className="mt-11 xl:mt-0 px-5 md:pr-0 md:pl-16 w-full md:w-4/4 max-w-[50rem] mx-auto">
          <h2 className="tracking-[-0.03em] mb-4 text-navy-blue text-2xl md:text-3xl font-bold">
            the perfect way to
            <span className="text-princeton-orange"> stay relevant </span>
            is to
            <span className="text-lime-green"> explore </span>
            what your
            <span className="text-neon-pink"> clients are looking for</span>
          </h2>
          <p className="tracking-[-0.03em] md:tracking-normal">
            Your brand is your reputation. Your audience needs to trust you.
            Whether you are a major attraction or a small local institution,
            they need to see you as the best option. Always serve up the
            tastiest information and your audience will follow you on your
            journey, wherever it may lead.
          </p>
          <h3 className="my-6 text-navy-blue text-2xl font-bold">
            cultivate audiences and harvest results
          </h3>
          <p className="tracking-[-0.03em] md:tracking-normal mb-14 md:text-sm">
            We are a global team and work anywhere there is wifi or phone
            signal. That means we are always working for our partners. We can
            craft a social media strategy from a cafe in Prague, meet with you
            in-house or design your brand identity from a riverboat on the
            Ganges. We are adaptive, agile and ready to work anywhere. The
            perfect team to tackle an industry that has so much to share.
          </p>
          <div className="md:flex md:gap-5 md:justify-center">
            <a
              href="/work"
              className="hover:bg-princeton-orange block w-56 md:w-48 py-4 mb-5 md:mb-0 mx-auto md:mx-0 rounded-full bg-navy-blue text-center text-white font-bold shadow-lg"
            >
              get inspired
            </a> 
            <a
              href="/contact"
              className="hover:bg-princeton-orange hover:text-white block w-56 md:w-48 py-4 mx-auto md:mx-0 rounded-full bg-bayside-cyan text-center text-navy-blue font-bold shadow-lg"
            >
              build capacity
            </a>
          </div>
        </div>
      </div>
      <div className="container mx-auto px-0 mb-16 lg:mb-32 md:flex md:items-center text-center text-white">
        <article className="shadow-md lg:h-[20rem] md:pt-9 pb-16 md:pb-9 px-3 md:px-6 flex items-center bg-navy-blue rounded-t-3xl md:rounded-l-3xl md:rounded-tr-none">
          <div>
            <img
              src={rocketGif}
              alt="icon bulb"
              className="mx-auto w-20 mt-8 md:mt-0"
            />
            <h5 className="mb-5 text-xl font-bold">experience</h5>
            <p className="px-4">
              With a combined 35 years in the industry, our team has the global
              experience to bring fresh ideas to your institution. We will
              conceive, design, develop, and implement captivating projects just
              for you.
            </p>
          </div>
        </article>
        <article className="items-center shadow-md pt-9 pb-16 md:px-6 -mt-5 md:mt-0 md:flex bg-dark-denim rounded-t-3xl md:rounded-3xl md:h-[29rem] lg:h-[24rem]">
          <div>
            <img src={starsGif} alt="star icon" className="mx-auto w-20" />
            <h5 className="mb-5 text-xl font-bold">flexible</h5>
            <p className="px-4">
              No set location, out team moves to wherever the seasons take us.
              We can be with you one day, sipping coffee in Cairo the next, then
              inspecting packaging samples in Taipei.
            </p>
          </div>
        </article>
        <article className="shadow-md lg:h-[20rem] -mt-5 md:mt-0 pt-4 pb-12 px-3 md:px-6 flex items-center bg-navy-blue md:rounded-r-3xl">
          <div>
            <img src={bulbGif} alt="icon bulb" className="mx-auto w-24" />
            <h5 className="mb-5 text-xl font-bold">innovative</h5>
            <p className="px-4">
              We&apos;re full service which means we&apos;ve got you covered
              from start to finish. You&apos;ll form a lasting relationship with
              us, collaboration is central to what we do, and it is what keeps
              us motivated.
            </p>
          </div>
        </article>
      </div>
      <article className="mx-4 md:mx-0 overflow-hidden lg:flex lg:justify-center bg-navy-blue rounded-3xl text-white">
        <img
          src={hands}
          alt="forager gives back"
          className="w-full object-bottom"
        />
        <div className="px-9 pb-11 md:p-12 md:px-28">
          <h5 className="mt-3 xl:mt-0 mb-5 text-2xl font-bold">forager gives back</h5>
          <p className="mb-4">
            Our mandate is to provide support and community development
            opportunities within the heritage sector. That is why Forager Media
            Group is proud to follow in the tradition of all Forager
            International companies in pledging at least 1% of all revenue to
            charitable causes in the communities where we work.
          </p>
          <p>
            To find out more about this program or how to apply for funding,
            <NavLink to="/contact">
              <strong> please contact us.</strong>
            </NavLink>
          </p>
        </div>
      </article>
      <article className="mx-4 xl:mx-0 mb-20 lg:mb-36 mt-16 lg:mt-32 md:items-center md:flex gap-16 md:flex-row-reverse md:justify-between">
        <img
          src={mapsImg}
          alt="forager gives back"
          className="lg:hidden mx-auto w-full md:w-1/2 lg:max-w-[46rem]"
        />
        <img
          src={mapsDesktop}
          alt="forager gives back"
          className="hidden lg:block mx-auto w-full md:w-1/2 lg:max-w-[46rem]"
        />
        <div className="w-full md:w-1/2 md:flex md:flex-col">
          <div className="my-16 md:my-6 text-center md:text-left order-1 md:order-2">
            <h2 className="hidden md:flex text-xl font-bold text-navy-blue">
              our offices:
            </h2>
            <span className="hidden md:block mb-4">
              Cork, Ireland / Gibsons, Canada
            </span>
            <h2 className="text-xl font-bold text-navy-blue">our nomadic hq</h2>
            <span className="block">Bogotá, Colombia</span>
          </div>
          <div className="order-2 md:order-1 my-auto">
            <p className="text-neon-pink text-2xl md:text-3xl font-bold mb-5">
              how can we help?
            </p>
            <p className="tracking-[-0.03em] md:tracking-normal">
              We harvest the very best stories and cook up the best project
              outcomes no matter where we are in the world. Whether trekking the
              rainforests of Colombia, the deserts of Arizona, or ascending the
              heights of the Austrian Alps, we are ready to produce results for
              you. Flexible, adaptable and innovative, our team is ready to meet
              your digital, virtual, production, or publishing needs. We
              can&apos;t wait to hear from you!
            </p>
          </div>
          <NavLink
            to="/contact"
            className="hover:bg-bayside-cyan order-3 block w-56 mt-16 md:mt-2 py-4 mx-auto md:mx-0 rounded-full bg-neon-pink text-center text-white font-bold shadow-lg"
          >
            get in touch
          </NavLink>
        </div>
      </article>
    </section>
  );
}
