import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";

import {
  contactMailCreateReducer,
  contactMailListReducer,
  contactMailDeleteReducer,
  contactMailAuthReducer,
} from "./reducers/contactMailReducers";

import {
  blogCreateReducer,
  blogDetailsReducer,
  blogListReducer,
  blogUpdateReducer,
  blogDeleteReducer,
  blogCommentReducer,
} from "./reducers/blogReducers";

import {
  mediaListReducer,
  mediaUploadReducer,
  mediaDeleteReducer,
} from "./reducers/mediaReducers";

//
//
//
const reducer = combineReducers({
  contactMailCreate: contactMailCreateReducer,
  contactMailList: contactMailListReducer,
  contactMailDelete: contactMailDeleteReducer,
  contactMailAuth: contactMailAuthReducer,
  //
  blogList: blogListReducer,
  blogCreate: blogCreateReducer,
  blogDetails: blogDetailsReducer,
  blogUpdate: blogUpdateReducer,
  blogDelete: blogDeleteReducer,
  blogComment: blogCommentReducer,
  //
  mediaList: mediaListReducer,
  mediaUpload: mediaUploadReducer,
  mediaDelete: mediaDeleteReducer,
});

const initialState = {};
const middleware = [thunk];

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware)),
);

export default store;
