import React from "react";
import imgPlanetLime from "../../../../assets/img/planets/lime.png";
import imgPlanetWhite from "../../../../assets/img/planets/white.png";
import vr1 from "../../../../assets/img/divisions/virtual/vr_development/1.png";
import vr2 from "../../../../assets/img/divisions/virtual/vr_development/2.png";
import vr3 from "../../../../assets/img/divisions/virtual/vr_development/3.png";
import vl1 from "../../../../assets/img/divisions/virtual/virtual_learning_resources/1.png";
import vl2 from "../../../../assets/img/divisions/virtual/virtual_learning_resources/2.png";
import vl3 from "../../../../assets/img/divisions/virtual/virtual_learning_resources/3.png";
import ar1 from "../../../../assets/img/divisions/virtual/ar_experience/1.png";
import ar2 from "../../../../assets/img/divisions/virtual/ar_experience/2.png";
import ar3 from "../../../../assets/img/divisions/virtual/ar_experience/3.png";
import interactive1 from "../../../../assets/img/divisions/virtual/interactive_installations/1.png";
import interactive2 from "../../../../assets/img/divisions/virtual/interactive_installations/2.png";
import interactive3 from "../../../../assets/img/divisions/virtual/interactive_installations/3.png";

function Services() {
  return (
    <section>
      <div className="bg-navy-blue">
        <div className="container mx-auto relative py-16 lg:pt-9 lg:pb-12">
          <h1 className="text-3xl md:text-6xl font-bold text-center xl:text-left xl:pl-24 text-lime-green">
            <span className="text-white">our </span>
            services
          </h1>
          <img
            src={imgPlanetWhite}
            alt="planet white"
            className="w-14 h-14 absolute left-4 xl:left-[51rem] 2xl:left-[58rem] top-5 xl:top-8"
          />
          <img
            src={imgPlanetLime}
            alt="planet orange"
            className="w-7 xl:w-36 aspect-square absolute left-20 xl:left-[40rem] top-28 xl:top-8"
          />
          <img
            src={imgPlanetLime}
            alt="planet orange"
            className="w-9 xl:w-28 aspect-square absolute right-28 xl:right-[12.5rem] 2xl:right-[16.5rem] top-4 xl:top-4"
          />
          <img
            src={imgPlanetWhite}
            alt="planet white"
            className="w-5 xl:w-14 aspect-square absolute right-9 xl:right-20 top-12 xl:top-16"
          />
          <img
            src={imgPlanetLime}
            alt="planet orange"
            className="w-16 xl:w-20 aspect-square absolute right-5 xl:-right-8 top-28 xl:-top-7"
          />
        </div>
      </div>
      <div
        id="virtual-tour-development"
        className="bg-dark-denim division-service-ids"
      >
        <div className="container mx-auto grid grid-cols-2 grid-rows-auto gap-3 px-4 py-14 lg:py-20">
          <div className="order-1 col-span-2 lg:col-span-1 max-w-xl mx-auto">
            <h2 className="mb-5 text-3xl font-bold text-center text-lime-green">
              virtual tour
              <span className="text-white"> development</span>
            </h2>
          </div>
          <div className="pr-4 order-2 col-span-2 lg:col-span-1 row-span-2 flex gap-2 w-full max-w-xl max-h-96 mx-auto mb-10 lg:mb-0">
            <img src={vr1} alt="" className="w-1/3" />
            <img src={vr2} alt="" className="translate-y-7 w-1/3" />
            <img src={vr3} alt="" className="w-1/3" />
          </div>
          <div className="order-3 col-span-2 lg:col-span-1 max-w-xl mx-auto">
            <p className="text-white flex flex-col gap-4 pb-4">
              Virtual tours have gained more and more attention, especially
              throughout the pandemic. It isn’t just a matter of taking a few
              photos and adding captions and calling it a day. Taking the time
              to tell a good story using professional equipment and integrating
              engaging features makes a virtual tour much more exciting for your
              audience to engage with.
              <span className="block">
                Forager Virtual’s team is skilled at in-the-field production and
                development when it comes to creating a virtual tour. They work
                with you to plan, film, and publish your virtual tour and help
                find the best outlet to capture the biggest audience possible,
                while also monetizing the content. No matter where your
                organization is located, a virtual tour allows visitors to
                explore and engage with your unique story. The tours can be
                optimized for VR headsets or for use on desktop and mobile
                devices. The final decision depends on your audience.
              </span>
            </p>
          </div>
        </div>
      </div>
      <div
        id="virtual-learning-resources"
        className="bg-white division-service-ids"
      >
        <div></div>
        <div className="container mx-auto grid grid-cols-2 grid-rows-auto gap-3 px-4 py-14">
          <div className="order-1 lg:order-2 col-span-2 lg:col-span-1 max-w-xl mx-auto">
            <h2 className="mb-5 text-3xl font-bold text-center text-lime-green">
              virtual learning
              <span className="text-dark-denim"> resources</span>
            </h2>
          </div>
          <div className="pr-4 order-2 lg:order-1 col-span-2 lg:col-span-1 row-span-2 flex gap-2 w-full max-w-xl max-h-96 mx-auto mb-10 lg:mb-0">
            <img src={vl1} alt="" className="w-1/3" />
            <img src={vl2} alt="" className="translate-y-7 w-1/3" />
            <img src={vl3} alt="" className="w-1/3" />
          </div>
          <div className="order-3 col-span-2 lg:col-span-1 max-w-xl mx-auto">
            <p className="flex flex-col gap-4 pb-4">
              Distance learning was a necessity during the pandemic but it came
              with its challenges. Making a lesson that was able to keep a
              student’s attention proved the biggest hurdle to overcome. With
              virtual learning resources, you can create engaging experiences
              for teachers to use that make students feel more like they are
              playing a game than taking part in a traditional classroom
              lecture.
              <span className="block">
                Forager Virtual’s team works with the educators at Forager
                Education to bring two important components together, new
                technology and curriculum alignment. Whether it is a virtual
                quiz taking place while wandering through your institution or a
                scavenger hunt that makes students take a deeper look at their
                surroundings, these resources can be a valuable tool to drive
                youth engagement with your collections. As long as the
                curriculum connections are carefully planned, your own virtual
                learning resource can quickly become a go-to for teachers when
                looking for new ways to get their students excited about their
                next lesson.
              </span>
            </p>
          </div>
        </div>
      </div>
      <div
        id="augmented-reality-experience"
        className="bg-dark-denim division-service-ids"
      >
        <div className="container mx-auto grid grid-cols-2 grid-rows-auto gap-3 px-4 py-14">
          <div className="order-1 col-span-2 lg:col-span-1 max-w-xl mx-auto">
            <h2 className="mb-5 text-3xl font-bold text-center text-white">
              augmented
              <span className="text-lime-green"> reality experience</span>
            </h2>
          </div>
          <div className="pr-4 order-2 col-span-2 lg:col-span-1 row-span-2 flex gap-2 w-full max-w-xl max-h-96 mx-auto mb-10 lg:mb-0">
            <img src={ar1} alt="" className="w-1/3" />
            <img src={ar2} alt="" className="translate-y-7 w-1/3" />
            <img src={ar3} alt="" className="w-1/3" />
          </div>
          <div className="order-3 col-span-2 lg:col-span-1 max-w-xl mx-auto">
            <p className="text-white flex flex-col gap-4 pb-4">
              Finding new ways to excite visitors can be a daunting task. With
              augmented reality, there are endless numbers of new possibilities
              available to you. Often an exhibit or heritage site can feel like
              a static space that is experienced once and means the visitor
              moves on to other organizations. With augmented reality
              experiences, you can create something that is dynamic, exciting,
              and constantly changing, meaning that there is a reason for
              visitors to keep coming back.
              <span className="block">
                Forager Virtual’s developers will work with you to realize the
                full potential of your physical space when integrating augmented
                reality features. Whether its simple animations on static
                physical objects that give a quick surprise to visitors or
                something more involved that allows visitors to dig deeper into
                your collection without cluttering the walls and cases with
                essay-like info panels, Forager Virtual can help you bring your
                story to life in ways that deepen the in-person visitor
                experience.
              </span>
            </p>
          </div>
        </div>
      </div>
      <div
        id="interactive-installations"
        className="bg-white division-service-ids"
      >
        <div className="container mx-auto grid grid-cols-2 grid-rows-auto gap-3 px-4 py-14">
          <div className="order-1 lg:order-2 col-span-2 lg:col-span-1 max-w-xl mx-auto">
            <h2 className="mb-5 text-3xl font-bold text-center text-lime-green">
              interactive
              <span className="text-dark-denim font-bold"> installations</span>
            </h2>
          </div>
          <div className="pr-4 order-2 lg:order-1 col-span-2 lg:col-span-1 row-span-2 flex gap-2 w-full max-w-xl max-h-96 mx-auto mb-10 lg:mb-0">
            <img src={interactive1} alt="" className="w-1/3" />
            <img src={interactive2} alt="" className="translate-y-7 w-1/3" />
            <img src={interactive3} alt="" className="w-1/3" />
          </div>
          <div className="order-3 col-span-2 lg:col-span-1 max-w-xl mx-auto">
            <p className="flex flex-col gap-4 py-14">
              One of the biggest ways for an organization to bring new visitors
              through the door is to create interactive installations. These
              integrate a number of different features from video displays,
              animations, touch screens, and motion-activated interactions. Not
              all interactive installations require huge investments in
              technology and equipment, so don’t let that be something that
              frightens you. Smaller installations can be cheap and easy ways to
              bring an element of interactivity to organizations that can often
              feel like hands-off type spaces.
              <span className="block">
                Forager Virtual’s designers, software engineers, and developers
                have the skills and creative minds to help you explore the
                possibilities with this type of new feature of your
                organization. They will work every step of the way to ensure
                that you understand what is being planned and how it is
                something that will benefit your organization. Not everyone is
                tech-savvy and Forager Virtual’s team makes sure that everything
                is planned and implemented in a way that leaves no room for
                confusion.
              </span>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Services;
