import React from "react";
import PromoteCard from "../../PromoteCard";
import eyes from "../../../../assets/img/division/publishing/eyes.svg";
import book from "../../../../assets/img/division/publishing/iconBook.svg";
import calendar from "../../../../assets/img/division/publishing/calendar.svg";
import notebook from "../../../../assets/img/division/publishing/notebook.svg";
import openBook from "../../../../assets/img/division/publishing/open-book.png";

function AboutWork() {
  const steps = [
    {
      icon: eyes,
      heading: "be your second eyes",
      text: "We aim to: review your writing project with an eye for detail to ensure that every grammatical point is accurate and consistently showcases your tone-of-voice.",
    },
    {
      icon: book,
      heading: "bring your story to life",
      text: "We aim to: take your ideas and create a captivating story that engages with your audience in a way that is exciting and informative. You come up with the idea and we take care of the rest.",
    },
    {
      icon: calendar,
      heading: "prioritize quality publications",
      text: "We aim to: bring your publication to a point of admiration with your audience. You have interesting information to share, our team will put it together into a captivating publication.",
    },
    {
      icon: notebook,
      heading: "end-to-end publishing",
      text: "We aim to: provide quality services at either end of the publishing process. Writing, editing, formatting, or final printing, our team is ready to provide the support you need.",
    },
  ];
  return (
    <section className="bg-dark-denim -mt-1 pt-16 lg:pt-0">
      <h1 className="text-neon-pink text-2xl md:text-3xl text-center font-bold lg:pb-3">
        what
        <span className="text-white"> we will </span>
        do for you
      </h1>
      <div className="px-4 lg:container lg:mx-auto grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-5 pt-14 pb-16 lg:pb-24  text-center">
        {steps.map((ele, index) => (
          <PromoteCard
            key={index}
            icon={ele.icon}
            heading={ele.heading}
            text={ele.text}
          />
        ))}
      </div>
      <div className="bg-white md:py-24">
        <div className="grid grid-cols-1 md:grid-cols-2 items-center gap-y-10 lg:gap-20 lg:container lg:mx-auto">
          <div
            className="md:rounded-3xl w-full mx-0 h-80 division-about"
            style={{
              backgroundImage: `url(${openBook})`,
            }}
          ></div>
          <p className="mx-4 pb-3">
            <span className="font-bold">Forager Publishing </span>
            is the division that brings ideas to life in the written form.
            Whether it is for marketing purposes or to turn your idea into a new
            book, we are a dedicated team that shares your passion for
            storytelling. We will work with your team to showcase what it is you
            have to say with all the creative writing and grammatical skills to
            share your story with your readers and instantly capture their
            attention.
            <span className="text-neon-pink text-2xl text-left font-bold block my-6">
              sharing your story with the world
            </span>
            Our writers, editors, and publishers are ready to work with clients
            big or small to take their ideas for publications from idea to
            finished product. Don't let the stress of writing and editing scare
            you away from that next project, our team is here and ready to help.
          </p>
        </div>
      </div>
    </section>
  );
}

export default AboutWork;
