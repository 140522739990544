import React from 'react';
import { NavLink } from 'react-router-dom';
import Logo from '../../assets/logos/logo-short-forager-media-group.svg';
import iconClose from '../../assets/icons/close.svg';
import LogoForagerWhite from '../../assets/logos/logo-forager-white.svg';

export default function AppDrawer() {
  return (
    <>
      <div className="drawer-side">
        <label htmlFor="app-drawer" className="drawer-overlay" />
        <nav className="menu flex w-48 pt-4 bg-navy-blue">
          <div className="flex justify-between mb-5 px-4">
            <div>
              <NavLink to="/">
                <img src={Logo} alt="" className="w-14 mx-4" />
              </NavLink>
            </div>
            <label htmlFor="app-drawer" className="px-2 drawer-button text-white font-extrabold z-50">
            <img src={iconClose} alt="" /></label>
          </div>
          <ul className="place-self-end w-10/12 text-white app-nav">
            <li>
              <NavLink to="/about" className="app-nav-link rounded-l-3xl font-semibold bg-princeton-orange">
                about
              </NavLink>
            </li>
            <li>
              <input id="division" className="peer/division hidden" type="checkbox" name="status" />
              <label htmlFor="division" className="z-10 peer-checked/division:bg-lime-green rounded-l-full font-semibold">
                division
              </label>
              <nav className="
                relative overflow-hidden max-h-0 p-0 rounded-bl-3xl
                peer-checked/division:max-h-60 peer-checked/division:overflow-visible
              bg-dark-denim with-transition
                before:absolute before:left-0 before:-top-6 before:w-full before:h-7 before:bg-inherit
              "
              >
                <ul className="py-1 text-white">
                  <li>
                    <NavLink to="/division/digital/about" className="flex gap-1">
                      <img src={LogoForagerWhite} alt="" className="w-14" />
                      <h4 className="text-princeton-orange font-bold pb-2 text-sm">digital</h4>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/division/virtual/about" className="flex gap-1">
                      <img src={LogoForagerWhite} alt="" className="w-14" />
                      <h4 className="text-lime-green font-bold pb-2 text-sm">virtual</h4>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/division/productions/about" className="flex gap-1">
                      <img src={LogoForagerWhite} alt="" className="w-14" />
                      <h4 className="text-bayside-cyan font-bold pb-2 text-sm">productions</h4>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/division/publishing/about" className="flex gap-1">
                      <img src={LogoForagerWhite} alt="" className="w-14" />
                      <h4 className="text-neon-pink font-bold pb-2 text-sm">publishing</h4>
                    </NavLink>
                  </li>
                </ul>
              </nav>
            </li>
            <li>
              <NavLink to="/industries" className="app-nav-link rounded-l-3xl bg-bayside-cyan font-semibold">
                industries
              </NavLink>
            </li>
            <li>
              <NavLink to="/work" className="app-nav-link rounded-l-3xl bg-neon-pink font-semibold">
                work
              </NavLink>
            </li>
            <li>
              <NavLink to="/blog" className="app-nav-link rounded-l-3xl bg-dark-denim font-semibold">
                blog
              </NavLink>
            </li>
            <li>
              <NavLink to="/contact" className="app-nav-link rounded-l-3xl bg-white/30 font-semibold">
                contact
              </NavLink>
            </li>
          </ul>
        </nav>
      </div>
    </>
  );
}
