import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { listRelatedMedia, uploadMedia } from "../actions/mediaActions";
import { createBlog } from "../actions/blogActions";

import MainLayout from "../layouts/MainLayout";
import BlogPlanets from "./BlogPlanets";
//
// import imgPlanetOrange from "../../assets/img/planets/orange.png";
import imgPlanetOrange from "../assets/img/planets/orange.png";
import imgPlanetLime from "../assets/img/planets/lime.png";
import imgPlanetCyan from "../assets/img/planets/cyan.png";
import imgPlanentPink from "../assets/img/planets/pink.png";
//
import Loader from "../components/general/Loader";
import Message from "../components/general/Message";
import { authContactMails } from "../actions/contactMailActions";

function CreateNewBlog() {
  const dispatch = useDispatch();

  const mediaList = useSelector((state) => state.mediaList);
  const { loading: loadingFiles, error: errorFiles, files } = mediaList;

  const mediaUpload = useSelector((state) => state.mediaUpload);
  const { loading: loadingFile, error: errorFile, file } = mediaUpload;

  const blogCreate = useSelector((state) => state.blogCreate);
  const { loading: loadingCreate, error: errorCreate, success } = blogCreate;

  const contactMailAuth = useSelector((state) => state.contactMailAuth);
  const { error: errorAuth, success: successAuth } = contactMailAuth;

  const [banner, setBanner] = useState(``);

  const [hero1, setHero1] = useState(``);
  const [hero2, setHero2] = useState(``);
  const [hero3, setHero3] = useState(``);
  const [hero4, setHero4] = useState(``);

  const uploadFileHandler = async (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("image", file);

    dispatch(uploadMedia(formData));
  };

  const [elementType, setElementType] = useState(``);
  const [elementContent, setElementContent] = useState(``);

  const [displayWindow, setDisplayWindow] = useState(false);

  const displayMenu = (e) => {
    e.preventDefault();
    if (!files) {
      getMediaFiles(e);
    }
    setElementType(e.target.id);
    setElementContent(``);
    setDisplayWindow(true);
  };

  const hideMenu = (e) => {
    e.preventDefault();
    setDisplayWindow(false);
    setElementContent(``);
    setElementType(``);
  };

  const elementContentHandler = (e) => {
    e.preventDefault();
    setElementContent(e.target.value);
  };

  const getMediaFiles = async (e) => {
    e.preventDefault();
    dispatch(listRelatedMedia());
  };

  const mediaFileUrlHandler = (e) => {
    e.preventDefault();
    setElementContent(e.target.id);
  };

  const setVisual = () => {
    switch (elementType) {
      case "banner":
        setBanner(elementContent);
        break;
      case "hero1":
        setHero1(elementContent);
        break;
      case "hero2":
        setHero2(elementContent);
        break;
      case "hero3":
        setHero3(elementContent);
        break;
      case "hero4":
        setHero4(elementContent);
        break;
      default:
        return;
    }
    setDisplayWindow(false);
    setElementContent(``);
    setElementType(``);
  };

  useEffect(() => {
    if (file && file.length > 0) {
      setElementContent(file);
    }
  }, [file]);

  /**/

  const [title, setTitle] = useState(``);

  const titleHandler = (e) => {
    setTitle(e.target.value);
  };

  /**/

  /**/
  const [bodyHeading1, setBodyHeading1] = useState(``);
  const [bodyText1, setBodyText1] = useState([]);

  const [bodyHeading2, setBodyHeading2] = useState(``);
  const [bodyText2, setBodyText2] = useState([]);

  const [bodyHeading3, setBodyHeading3] = useState(``);
  const [bodyText3, setBodyText3] = useState([]);

  const [bodyHeading4, setBodyHeading4] = useState(``);
  const [bodyText4, setBodyText4] = useState([]);

  const [bodyHeading5, setBodyHeading5] = useState(``);
  const [bodyText5, setBodyText5] = useState([]);

  const [bodyHeading6, setBodyHeading6] = useState(``);
  const [bodyText6, setBodyText6] = useState([]);

  const [bodyHeading7, setBodyHeading7] = useState(``);
  const [bodyText7, setBodyText7] = useState([]);

  const [bodyHeading8, setBodyHeading8] = useState(``);
  const [bodyText8, setBodyText8] = useState([]);

  const [bodyHeading9, setBodyHeading9] = useState(``);
  const [bodyText9, setBodyText9] = useState([]);

  const [bodyHeading10, setBodyHeading10] = useState(``);
  const [bodyText10, setBodyText10] = useState([]);

  const [bodyHeading11, setBodyHeading11] = useState(``);
  const [bodyText11, setBodyText11] = useState([]);

  const bodyHeadingHandler = (e) => {
    switch (Number(e.target.id)) {
      case 1:
        setBodyHeading1(e.target.value);
        break;
      case 2:
        setBodyHeading2(e.target.value);
        break;
      case 3:
        setBodyHeading3(e.target.value);
        break;
      case 4:
        setBodyHeading4(e.target.value);
        break;
      case 5:
        setBodyHeading5(e.target.value);
        break;
      case 6:
        setBodyHeading6(e.target.value);
        break;
      case 7:
        setBodyHeading7(e.target.value);
        break;
      case 8:
        setBodyHeading8(e.target.value);
        break;
      case 9:
        setBodyHeading9(e.target.value);
        break;
      case 10:
        setBodyHeading10(e.target.value);
        break;
      case 11:
        setBodyHeading11(e.target.value);
        break;

      default:
        break;
    }
  };

  const bodyTextHandler = (e) => {
    switch (Number(e.target.id)) {
      case 100:
        setBodyText1(e.target.value.split("\n"));
        break;
      case 200:
        setBodyText2(e.target.value.split("\n"));
        break;
      case 300:
        setBodyText3(e.target.value.split("\n"));
        break;
      case 400:
        setBodyText4(e.target.value.split("\n"));
        break;
      case 500:
        setBodyText5(e.target.value.split("\n"));
        break;
      case 600:
        setBodyText6(e.target.value.split("\n"));
        break;
      case 700:
        setBodyText7(e.target.value.split("\n"));
        break;
      case 800:
        setBodyText8(e.target.value.split("\n"));
        break;
      case 900:
        setBodyText9(e.target.value.split("\n"));
        break;
      case 1000:
        setBodyText10(e.target.value.split("\n"));
        break;
      case 1100:
        setBodyText11(e.target.value.split("\n"));
        break;

      default:
        break;
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();
    if (title.length > 0) {
      const newBlog = {
        title,
        slug: title.split(" ").join("-"),
        body: [
          {
            heading: bodyHeading1,
            text: bodyText1.filter((ele) => ele.length > 0),
          },
          {
            heading: bodyHeading2,
            text: bodyText2.filter((ele) => ele.length > 0),
          },
          {
            heading: bodyHeading3,
            text: bodyText3.filter((ele) => ele.length > 0),
          },
          {
            heading: bodyHeading4,
            text: bodyText4.filter((ele) => ele.length > 0),
          },
          {
            heading: bodyHeading5,
            text: bodyText5.filter((ele) => ele.length > 0),
          },
          {
            heading: bodyHeading6,
            text: bodyText6.filter((ele) => ele.length > 0),
          },
          {
            heading: bodyHeading7,
            text: bodyText7.filter((ele) => ele.length > 0),
          },
          {
            heading: bodyHeading8,
            text: bodyText8.filter((ele) => ele.length > 0),
          },
          {
            heading: bodyHeading9,
            text: bodyText9.filter((ele) => ele.length > 0),
          },
          {
            heading: bodyHeading10,
            text: bodyText10.filter((ele) => ele.length > 0),
          },
          {
            heading: bodyHeading11,
            text: bodyText11.filter((ele) => ele.length > 0),
          },
        ],
        banner,
        heros: [hero1, hero2, hero3, hero4],
      };

      dispatch(createBlog(newBlog));
    }
  };

  /*
   */

  useEffect(() => {
    if (success) {
      setTimeout(() => {
        document.location.reload();
      }, 5000);
    }
  }, [success]);

  // admin
  const [password, setPassword] = useState(``);
  const [userInfo, setUserInfo] = useState(null);

  const passwordHandler = (e) => {
    setPassword(e.target.value);
  };

  const authHandler = (e) => {
    e.preventDefault();
    dispatch(authContactMails(password));
    setPassword(``);
  };

  useEffect(() => {
    setUserInfo(localStorage["auth"]);

    if (successAuth) {
      localStorage.setItem("auth", true);

      setTimeout(() => {
        document.location.reload();
      }, 5000);
    }
  }, [successAuth]);

  useEffect(() => {
    if (errorAuth) {
      setTimeout(() => {
        document.location.reload();
      }, 5000);
    }
  }, [errorAuth]);

  return userInfo ? (
    <>
      {loadingFile && <Loader />}
      {loadingFiles && <Loader />}
      {loadingCreate && <Loader />}
      {errorFiles && <Message body={errorFiles} />}
      {errorFile && <Message body={errorFile} />}
      {errorCreate && <Message body={errorCreate} />}
      {success && (
        <Message body="Blog has been published successfully!" type="info" />
      )}
      {displayWindow && (
        <>
          <div className="popup">
            <div className="overlay"></div>
            <div className="container">
              <div className="popup-content">
                <div style={{ display: "flex", gap: "10px" }}>
                  <input
                    type="text"
                    onChange={elementContentHandler}
                    placeholderheading-inputs="enter image url"
                    min="0"
                    className="rounded-xl border w-full shadow-lg rounded-full pl-7"
                    value={elementContent}
                    style={{ flexGrow: "1" }}
                  />

                  <input
                    type="file"
                    className="rounded-xl border w-full mt-3 p-4 shadow-lg"
                    onChange={uploadFileHandler}
                    id="upload-inline-image"
                    style={{ display: "none" }}
                  />
                  <label
                    htmlFor="upload-inline-image"
                    className="block w-56 h-12 mx-auto bg-neon-pink text-white rounded-full font-bold shadow-lg"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    upload file
                  </label>
                  <button
                    className="block w-56 h-12 mx-auto bg-neon-pink text-white rounded-full font-bold shadow-lg"
                    onClick={getMediaFiles}
                  >
                    refresh list
                  </button>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "50px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      gap: "20px",
                      width: "fit-content",
                    }}
                  >
                    <button
                      className="block w-56 h-12 mx-auto bg-neon-pink text-white rounded-full font-bold shadow-lg"
                      onClick={setVisual}
                      style={{ marginTop: "20px" }}
                    >
                      {elementContent.length > 0 ? "set" : "clear"}
                    </button>
                    <button
                      className="block w-56 h-12 mx-auto bg-neon-pink text-white rounded-full font-bold shadow-lg"
                      onClick={hideMenu}
                      style={{ marginTop: "20px" }}
                    >
                      cancel
                    </button>
                  </div>
                  {files && files.length > 0 && (
                    <div className="media-lib">
                      {files.map((file, index) => {
                        let text = file[0];

                        let arr = text.split("/");

                        arr.splice(
                          arr.indexOf("upload") + 1,
                          0,
                          "c_thumb,w_200,g_face",
                        );

                        text = arr.join("/");

                        return (
                          <div
                            key={index}
                            className="media-file"
                            style={{
                              backgroundImage: `url(${text}`,
                            }}
                            id={file[0]}
                            onClick={mediaFileUrlHandler}
                          ></div>
                        );
                      })}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      <MainLayout>
        <div className="bg-dark-denim lg:flex add-blog">
          <div className="relative px-4 2xl:pl-40 xl:container mx-auto pt-20 md:pt-36 text-white">
            <input
              type="text"
              placeholder="insert blog title here"
              className="w-3/4 text-4xl font-bold text-neon-pink rounded-xl mt-3 p-4 shadow-lg bg-transparent"
              value={title}
              onChange={titleHandler}
            />
            <BlogPlanets />
          </div>
          <div
            className="add-visual-container"
            style={{
              width: "50%",
              backgroundImage: banner.length > 0 ? `url(${banner})` : "",
            }}
          >
            <label id="banner" onClick={displayMenu}>
              <img
                src={require("../assets/img/add-blog/add-visual.png")}
                alt="add-visual"
                style={{
                  width: "200px",
                }}
                id="banner"
              />
            </label>
          </div>
        </div>
        <form
          className="bg-white container mx-auto px-0"
          style={{ paddingTop: "50px" }}
          onSubmit={submitHandler}
        >
          <div className="grid grid-cols-2 gap-7">
            <div className="order-1 col-span-2 lg:col-span-1 lg:my-auto px-4 pt-10 pb-3">
              <input
                type="text"
                placeholder="insert heading here (leave empty if no heading)"
                className="heading-inputs rounded-xl border w-full mt-3 p-4 shadow-lg"
                value={bodyHeading1}
                onChange={bodyHeadingHandler}
                id={1}
              />
              <textarea
                name="comment"
                placeholder="insert paragraphs here"
                className="h-32 rounded-xl border w-full mt-3 p-4 shadow-lg"
                onChange={bodyTextHandler}
                value={bodyText1.join("\n")}
                id={100}
              />
            </div>
            <div className="order-2 col-span-2 lg:col-span-1">
              <div
                style={{
                  backgroundImage: hero1.length > 0 ? `url(${hero1})` : "",
                }}
                className="w-full lg:mt-10 pb-3 lg:pb-0 rounded-xl border shadow-lg add-visual-container"
              >
                <label id="hero1" onClick={displayMenu}>
                  <img
                    src={require("../assets/img/add-blog/add-visual.png")}
                    alt="add-visual"
                    style={{
                      width: "200px",
                    }}
                    id="hero1"
                  />
                </label>
              </div>
            </div>
            <div className="order-3 md:mt-24 col-span-2 lg:col-span-1 flex flex-col gap-7 px-4 mb-3">
              <div className="order-1 col-span-2 lg:col-span-1 lg:my-auto px-4 pt-10 pb-3">
                <input
                  type="text"
                  placeholder="insert heading here (leave empty if no heading)"
                  className="heading-inputs rounded-xl border w-full mt-3 p-4 shadow-lg"
                  onChange={bodyHeadingHandler}
                  value={bodyHeading2}
                  id={2}
                />
                <textarea
                  name="comment"
                  placeholder="insert paragraphs here"
                  className="h-32 rounded-xl border w-full mt-3 p-4 shadow-lg"
                  onChange={bodyTextHandler}
                  value={bodyText2.join("\n")}
                  id={200}
                />
              </div>
            </div>
            <div className="mb-3 md:mt-24 order-4 col-span-2 lg:col-span-1 px-4 lg:px-0 flex flex-col gap-7">
              <div className="order-1 col-span-2 lg:col-span-1 lg:my-auto px-4 pt-10 pb-3">
                <input
                  type="text"
                  placeholder="insert heading here (leave empty if no heading)"
                  className="heading-inputs rounded-xl border w-full mt-3 p-4 shadow-lg"
                  onChange={bodyHeadingHandler}
                  value={bodyHeading3}
                  id={3}
                />
                <textarea
                  name="comment"
                  placeholder="insert paragraphs here"
                  className="h-32 rounded-xl border w-full mt-3 p-4 shadow-lg"
                  onChange={bodyTextHandler}
                  value={bodyText3.join("\n")}
                  id={300}
                />
              </div>
            </div>
            <div className="order-5 col-span-2 md:mt-16 md:mb-6">
              <div className="order-2 col-span-2 lg:col-span-1">
                <div
                  style={{
                    backgroundImage: hero2.length > 0 ? `url(${hero2})` : "",
                  }}
                  className="w-full lg:mt-10 pb-3 lg:pb-0 rounded-xl border shadow-lg add-visual-container"
                >
                  <label id="hero2" onClick={displayMenu}>
                    <img
                      src={require("../assets/img/add-blog/add-visual.png")}
                      alt="add-visual"
                      style={{
                        width: "200px",
                      }}
                      id="hero2"
                    />
                  </label>
                </div>
              </div>
            </div>
            <div className="order-5 col-span-2 lg:col-span-1 px-4">
              <div className="order-1 col-span-2 lg:col-span-1 lg:my-auto px-4 pt-10 pb-3">
                <input
                  type="text"
                  placeholder="insert heading here (leave empty if no heading)"
                  className="heading-inputs rounded-xl border w-full mt-3 p-4 shadow-lg"
                  onChange={bodyHeadingHandler}
                  value={bodyHeading4}
                  id={4}
                />
                <textarea
                  name="comment"
                  placeholder="insert paragraphs here"
                  className="h-32 rounded-xl border w-full mt-3 p-4 shadow-lg"
                  onChange={bodyTextHandler}
                  value={bodyText4.join("\n")}
                  id={400}
                />
              </div>

              <div className="order-1 col-span-2 lg:col-span-1 lg:my-auto px-4 pt-10 pb-3">
                <input
                  type="text"
                  placeholder="insert heading here (leave empty if no heading)"
                  className="heading-inputs rounded-xl border w-full mt-3 p-4 shadow-lg"
                  onChange={bodyHeadingHandler}
                  value={bodyHeading5}
                  id={5}
                />
                <textarea
                  name="comment"
                  placeholder="insert paragraphs here"
                  className="h-32 rounded-xl border w-full mt-3 p-4 shadow-lg"
                  onChange={bodyTextHandler}
                  value={bodyText5.join("\n")}
                  id={500}
                />
              </div>
            </div>
            <div className="mt-3 md:mt-16 md:mb-14 order-6 lg:order-7 col-span-2">
              <div className="order-2 col-span-2 lg:col-span-1">
                <div
                  style={{
                    backgroundImage: hero3.length > 0 ? `url(${hero3})` : "",
                  }}
                  className="w-full lg:mt-10 pb-3 lg:pb-0 rounded-xl border shadow-lg add-visual-container"
                >
                  <label id="hero3" onClick={displayMenu}>
                    <img
                      src={require("../assets/img/add-blog/add-visual.png")}
                      alt="add-visual"
                      style={{
                        width: "200px",
                      }}
                      id="hero3"
                    />
                  </label>
                </div>
              </div>
            </div>
            <div className="order-7 lg:order-6 col-span-2 lg:col-span-1 px-4">
              <div className="order-1 col-span-2 lg:col-span-1 lg:my-auto px-4 pt-10 pb-3">
                <input
                  type="text"
                  placeholder="insert heading here (leave empty if no heading)"
                  className="heading-inputs rounded-xl border w-full mt-3 p-4 shadow-lg"
                  onChange={bodyHeadingHandler}
                  value={bodyHeading6}
                  id={6}
                />
                <textarea
                  name="comment"
                  placeholder="insert paragraphs here"
                  className="h-32 rounded-xl border w-full mt-3 p-4 shadow-lg"
                  onChange={bodyTextHandler}
                  value={bodyText6.join("\n")}
                  id={600}
                />
              </div>
              <div className="order-1 col-span-2 lg:col-span-1 lg:my-auto px-4 pt-10 pb-3">
                <input
                  type="text"
                  placeholder="insert heading here (leave empty if no heading)"
                  className="heading-inputs rounded-xl border w-full mt-3 p-4 shadow-lg"
                  onChange={bodyHeadingHandler}
                  value={bodyHeading7}
                  id={7}
                />
                <textarea
                  name="comment"
                  placeholder="insert paragraphs here"
                  className="h-32 rounded-xl border w-full mt-3 p-4 shadow-lg"
                  onChange={bodyTextHandler}
                  value={bodyText7.join("\n")}
                  id={700}
                />
              </div>
            </div>
            <div className="order-8 col-span-2 lg:col-span-1 px-4 mb-8">
              <div className="order-1 col-span-2 lg:col-span-1 lg:my-auto px-4 pt-10 pb-3">
                <input
                  type="text"
                  placeholder="insert heading here (leave empty if no heading)"
                  className="heading-inputs rounded-xl border w-full mt-3 p-4 shadow-lg"
                  onChange={bodyHeadingHandler}
                  value={bodyHeading8}
                  id={8}
                />
                <textarea
                  name="comment"
                  placeholder="insert paragraphs here"
                  className="h-32 rounded-xl border w-full mt-3 p-4 shadow-lg"
                  onChange={bodyTextHandler}
                  value={bodyText8.join("\n")}
                  id={800}
                />
              </div>
              <div className="hidden xl:block">
                <div className="order-1 col-span-2 lg:col-span-1 lg:my-auto px-4 pt-10 pb-3">
                  <input
                    type="text"
                    placeholder="insert heading here (leave empty if no heading)"
                    className="heading-inputs rounded-xl border w-full mt-3 p-4 shadow-lg"
                    onChange={bodyHeadingHandler}
                    value={bodyHeading9}
                    id={9}
                  />
                  <textarea
                    name="comment"
                    placeholder="insert paragraphs here"
                    className="h-32 rounded-xl border w-full mt-3 p-4 shadow-lg"
                    onChange={bodyTextHandler}
                    value={bodyText9.join("\n")}
                    id={900}
                  />
                </div>
              </div>
              <div className="order-1 col-span-2 lg:col-span-1 lg:my-auto px-4 pt-10 pb-3">
                <input
                  type="text"
                  placeholder="insert heading here (leave empty if no heading)"
                  className="heading-inputs rounded-xl border w-full mt-3 p-4 shadow-lg"
                  onChange={bodyHeadingHandler}
                  value={bodyHeading10}
                  id={10}
                />
                <textarea
                  name="comment"
                  placeholder="insert paragraphs here"
                  className="h-32 rounded-xl border w-full mt-3 p-4 shadow-lg"
                  onChange={bodyTextHandler}
                  value={bodyText10.join("\n")}
                  id={1000}
                />
              </div>
              <div className="order-1 col-span-2 lg:col-span-1 lg:my-auto px-4 pt-10 pb-3">
                <input
                  type="text"
                  placeholder="insert heading here (leave empty if no heading)"
                  className="heading-inputs rounded-xl border w-full mt-3 p-4 shadow-lg"
                  onChange={bodyHeadingHandler}
                  value={bodyHeading11}
                  id={11}
                />
                <textarea
                  name="comment"
                  placeholder="insert paragraphs here"
                  className="h-32 rounded-xl border w-full mt-3 p-4 shadow-lg"
                  onChange={bodyTextHandler}
                  value={bodyText11.join("\n")}
                  id={1100}
                />
              </div>
            </div>
            <div className="order-9 col-span-2 lg:col-span-1 px-4">
              <div className="order-2 col-span-2 lg:col-span-1">
                <div
                  style={{
                    backgroundImage: hero4.length > 0 ? `url(${hero4})` : "",
                  }}
                  className="w-full lg:mt-10 pb-3 lg:pb-0 rounded-xl border shadow-lg add-visual-container"
                >
                  <label id="hero4" onClick={displayMenu}>
                    <img
                      src={require("../assets/img/add-blog/add-visual.png")}
                      alt="add-visual"
                      style={{
                        width: "200px",
                      }}
                      id="hero4"
                    />
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="col-span-3 mt-9 mb-24">
            <button
              type="submit"
              className="block w-56 h-12 mx-auto bg-neon-pink text-white rounded-full font-bold shadow-lg"
              onSubmit={submitHandler}
            >
              publish
            </button>
          </div>
        </form>
      </MainLayout>
    </>
  ) : (
    <MainLayout>
      {successAuth && <Message body="Admin authorized!" type="admin" />}
      {errorAuth && <Message body="Wrong password!" />}

      <div className="relative">
        <div
          className="h-[92vh] px-4 lg:px-24 flex flex-col justify-center lg:justify-start bg-navy-blue"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <h1 className="mb-5 lg:pt-36 font-bold text-3xl text-white text-center md:text-start">
            enter password
          </h1>
          <form onSubmit={authHandler}>
            <input
              type="password"
              name=""
              id=""
              placeholder="enter password here"
              className="px-6 py-5 w-full rounded-[10px]"
              value={password}
              onChange={passwordHandler}
            />
            <input
              type="submit"
              value="submit"
              onSubmit={authHandler}
              className="mt-6 mb-10 w-48 py-4 mx-auto md:mx-0 bg-dark-denim hover:bg-white rounded-3xl font-bold text-white hover:text-dark-denim shadow-lg block"
            />
          </form>
        </div>
        <img
          src={imgPlanetOrange}
          alt="planet orange"
          className="top-10 left-8 xl:left-[40rem] w-12 aspect-square absolute with-transition"
        />
        <img
          src={imgPlanetLime}
          alt="planet lime"
          className="top-32 lg:top-10 left-20 lg:left-2/4 w-28 md:w-32 aspect-square absolute with-transition"
        />
        <img
          src={imgPlanentPink}
          alt="planet pink"
          className="bottom-8 lg:bottom-[48%] left-10 lg:left-[60%] w-36 lg:w-52 aspect-square absolute with-transition"
        />
        <img
          src={imgPlanetCyan}
          alt="planet cyan"
          className="bottom-28 lg:bottom-[44%] right-20 xl:right-[14%] w-20 md:w-28 aspect-square absolute"
        />
        <img
          src={imgPlanetOrange}
          alt="planet orange"
          className="bottom-60 lg:bottom-60 right-16 lg:right-28 w-9 lg:w-12 aspect-square absolute with-transition"
        />
      </div>
    </MainLayout>
  );
}

export default CreateNewBlog;
