import React from 'react';
import AppDrawer from './components/AppDrawer';
import AppFooter from './components/AppFooter';
import NavegationDesktop from './components/NavegationDesktop';
import {ReactComponent as MenuIcon} from "../assets/icons/menu.svg";

export default function MainLayout({ children }) {
  return (
    <div className="drawer drawer-end">
      <input id="app-drawer" type="checkbox" className="drawer-toggle" />
      <div className="drawer-content">
        <header className="bg-navy-blue pb-4 md:pb-0 md:sticky md:top-0 md:z-50 md:w-full">
          <NavegationDesktop />
        </header>
        <main>{ children }</main>
        <AppFooter />
        <label htmlFor="app-drawer" className="fixed top-2 right-3 grid place-items-center w-12 h-12 rounded-full bg-navy-blue shadow-md cursor-pointer md:hidden">
          <MenuIcon className="w-8" />
        </label>
      </div>
      <AppDrawer />
    </div>
  );
}
