import React from "react";

function PromoteCard({ icon, heading, text }) {
  return (
    <div className="mx-auto rounded-3xl bg-white">
      <img src={icon} alt="star icon" className="mx-auto pt-8" />
      <h3 className="font-bold mb-6">{heading}</h3>
      <p className="px-10 pb-8 lg:text-center tracking-[-0.05em] lg:tracking-normal">
        {text}
      </p>
    </div>
  );
}

export default PromoteCard;
