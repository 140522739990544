import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { commentOnBlog, listAllBlogs } from "../actions/blogActions";

import MainLayout from "../layouts/MainLayout";
import BlogPlanets from "./BlogPlanets";
import BlogCard from "../components/blog/BlogCard";
import BlogComment from "../components/blog/BlogComment";
import Loader from "../components/general/Loader";
import Message from "../components/general/Message";

function SingleBlog() {
  const dispatch = useDispatch();

  const blogList = useSelector((state) => state.blogList);
  const { loading: loadingAll, error: errorAll, blogs } = blogList;

  const params = useParams();
  const { slug } = params;

  const [blog, setBlog] = useState(null);

  useEffect(() => {
    if (slug) {
      dispatch(listAllBlogs());
    }
  }, [slug, dispatch]);

  // blog comments
  const [comment, setComment] = useState("");
  const [person, setPerson] = useState("");
  const [email, setEmail] = useState("");
  const [website, setWebsite] = useState("");

  const commentHandler = (e) => {
    setComment(e.target.value);
  };

  const personHandler = (e) => {
    setPerson(e.target.value);
  };

  const emailHandler = (e) => {
    setEmail(e.target.value);
  };

  const websiteHandler = (e) => {
    setWebsite(e.target.value);
  };

  const submitCommentHandler = (e) => {
    e.preventDefault();
    if (
      comment.length > 0 &&
      person.length > 0 &&
      email.length > 0 &&
      website.length > 0
    ) {
      const blogComment = {
        comment,
        person,
        email,
        website,
      };

      dispatch(commentOnBlog(blog.slug, blogComment));

      setComment("");
      setPerson("");
      setEmail("");
      setWebsite("");

      alert("Thank you for your contribution!");
      window.location.reload();
    } else {
      alert("Invalid inputs!");
    }
  };
  //

  useEffect(() => {
    if (blogs) {
      setBlog(blogs.find((blog) => blog.slug === slug));
    }
  }, [blogs]);

  return loadingAll ? (
    <Loader />
  ) : errorAll ? (
    <Message body={errorAll} />
  ) : (
    blog && (
      <MainLayout>
        <div className="bg-dark-denim lg:flex">
          <div className="relative px-4 2xl:pl-40 xl:container mx-auto pt-20 md:pt-36 text-white">
            <span className="text-xs">
              {blog.createdAt
                .toString()
                .substr(0, 10)
                .split("-")
                .reverse()
                .join("/")}
            </span>
            <p className="font-bold">by bryce mathew watts</p>
            <h1 className="mt-4 mr-4 mb-20 xl:w-3/4 text-neon-pink text-3xl lg:text-6xl font-bold">
              {blog.title[0]}
              <span className="text-bayside-cyan"> {blog.title[1]}</span>
            </h1>
            <BlogPlanets />
          </div>
          <img
            src={blog.banner}
            alt="blog banner"
            className="w-full block lg:hidden"
          />
          <img
            src={blog.banner}
            alt="blog banner"
            className="hidden lg:block w-[27%]"
          />
        </div>
        <div className="bg-white container mx-auto px-0">
          <div className="grid grid-cols-2 gap-7">
            <div className="order-1 col-span-2 lg:col-span-1 lg:my-auto px-4 pt-10 pb-3">
              {blog.body[0].text.map((p, index) => (
                <p
                  key={index}
                  className="mb-7 tracking-tight lg:tracking-normal"
                >
                  {p}
                </p>
              ))}
            </div>
            <div className="order-2 col-span-2 lg:col-span-1">
              <img
                src={blog.heros[0]}
                className="w-full lg:mt-10 pb-3 lg:pb-0 px-4"
              />
            </div>
            <div className="order-3 md:mt-24 col-span-2 lg:col-span-1 flex flex-col gap-7 px-4 mb-3">
              <h2 className="font-bold text-navy-blue text-2xl">
                {blog.body[1].heading}
              </h2>
              {blog.body[1].text.map((p, index) => (
                <p key={index}>{p}</p>
              ))}
            </div>
            <div className="mb-3 md:mt-24 order-4 col-span-2 lg:col-span-1 px-4 lg:px-0 flex flex-col gap-7">
              <h2 className="font-bold text-navy-blue text-2xl">
                {blog.body[2].heading}
              </h2>
              {blog.body[2].text.map((p, index) => (
                <p key={index}>{p}</p>
              ))}
            </div>
            <div className="order-5 col-span-2 md:mt-16 md:mb-6">
              <img src={blog.heros[1]} className="w-full xl:rounded-2xl" />
            </div>
            <div className="order-5 col-span-2 lg:col-span-1 px-4">
              <h2 className="mt-3 mb-7 font-bold text-navy-blue text-2xl">
                {blog.body[3].heading}
              </h2>
              {blog.body[3].text.map((p, index) => (
                <p key={index}>{p}</p>
              ))}

              <h2 className="font-bold my-7 text-navy-blue text-2xl">
                {blog.body[4].heading}
              </h2>
              {blog.body[4].text.map((p, index) => (
                <p key={index}>{p}</p>
              ))}
            </div>
            <div className="mt-3 md:mt-16 md:mb-14 order-6 lg:order-7 col-span-2">
              <img src={blog.heros[2]} className="w-full xl:rounded-2xl" />
            </div>
            <div className="order-7 lg:order-6 col-span-2 lg:col-span-1 px-4">
              <h2 className="mt-3 mb-7 font-bold text-navy-blue text-2xl">
                {blog.body[5].heading}
              </h2>
              {blog.body[5].text.map((p, index) => (
                <p key={index}>{p}</p>
              ))}
              <p className="mb-7"></p>
              <h2 className="mb-7 font-bold text-navy-blue text-2xl">
                {blog.body[6].heading}
              </h2>
              {blog.body[6].text.map((p, index) => (
                <p key={index}>{p}</p>
              ))}
            </div>
            <div className="order-8 col-span-2 lg:col-span-1 px-4 mb-8">
              <h2 className="mb-7 font-bold text-navy-blue text-2xl">
                {blog.body[7].heading}
              </h2>
              {blog.body[7].text.map((p, index) => (
                <p key={index}>{p}</p>
              ))}
              <p className="mb-9"></p>
              <h2 className="mb-7 font-bold text-navy-blue text-2xl">
                {blog.body[8].heading}
              </h2>
              {blog.body[8].text.map((p, index) => (
                <p key={index}>{p}</p>
              ))}
              <p className="mb-9"></p>
              <h2 className="mb-7 font-bold text-navy-blue text-2xl">
                {blog.body[9].heading}
              </h2>
              {blog.body[9].text.map((p, index) => (
                <p key={index} className="mb-7">
                  {p}
                </p>
              ))}
              <div className="hidden xl:block">
                <h2 className="mb-7 mt-9 font-bold text-navy-blue text-2xl">
                  {blog.body[10].heading}
                </h2>
                {blog.body[10].text.map((p, index) => (
                  <p key={index} className="mb-7">
                    {p}
                  </p>
                ))}
              </div>
            </div>
            <div className="order-9 col-span-2 lg:col-span-1 px-4">
              <img
                src={blog.heros[3]}
                className="mb-11 mx-auto xl:mx-0 w-full"
              />
              <div className="xl:hidden">
                <h2 className="mb-7 mt-9 font-bold text-navy-blue text-2xl">
                  {blog.body[10].heading}
                </h2>
                {blog.body[10].text.map((p, index) => (
                  <p key={index} className="mb-7">
                    {p}
                  </p>
                ))}
              </div>
            </div>
            <div className="order-10 col-span-2 px-4 mt-7 lg:mt-20 lg:mb-8">
              <div className="w-9/12 xl:w-5/12 mx-auto flex flex-col md:flex-row justify-center items-center gap-8 py-10 md:py-3 rounded-xl border shadow-lg bg-white">
                <img
                  src={require("../assets/img/blog/guadalajara/bryce.png")}
                  alt="Bryce Mathew Watts"
                  className="w-32"
                />
                <p className="text-center">
                  <strong className="block mb-7">Bryce Mathew Watts</strong>
                  <span>
                    More articles by:
                    <strong className="text-neon-pink">
                      {" "}
                      Bryce Mathew Watts
                    </strong>
                  </span>
                </p>
              </div>
            </div>
            <div className="order-11 col-span-2 px-4 lg:px-20">
              {blog.comments && blog.comments.length > 0 && (
                <div
                  className="comments"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                    justifyContent: "flex-start",
                    paddingBottom: "50px",
                  }}
                >
                  <h2
                    style={{
                      fontSize: "1.5rem",
                      fontWeight: "bold",
                      color: "var(--navy-blue)",
                      marginBottom: "20px",
                    }}
                  >
                    comments
                  </h2>
                  {blog.comments.map((comment) => (
                    <BlogComment key={comment._id} comment={comment} />
                  ))}
                </div>
              )}

              <form
                action=""
                method="POST"
                className="grid grid-cols-3 gap-5 md:gap-7 text-left"
                autoComplete="off"
                onSubmit={submitCommentHandler}
              >
                <div className="my-8 col-span-3 text-center">
                  <strong className="text-3xl">leave a comment</strong>
                </div>
                <label className="font-bold col-span-3">
                  your comment <span className="text-red-600">*</span>
                  <textarea
                    name="comment"
                    value={comment}
                    onChange={commentHandler}
                    className="h-32 rounded-xl border w-full mt-3 p-4"
                    required
                  />
                </label>
                <label className="font-bold col-span-3 md:col-span-1">
                  your name <span className="text-red-600">*</span>
                  <input
                    type="text"
                    name="name"
                    value={person}
                    onChange={personHandler}
                    className="h-14 rounded-xl border w-full mt-3 p-4"
                    required
                  />
                </label>
                <label className="font-bold col-span-3 md:col-span-1">
                  your email <span className="text-red-600">*</span>
                  <input
                    type="email"
                    name="email"
                    value={email}
                    onChange={emailHandler}
                    className="h-14 rounded-xl border w-full mt-3 p-4"
                    required
                  />
                </label>
                <label className="font-bold col-span-3 md:col-span-1">
                  your website <span className="text-red-600">*</span>
                  <input
                    type="text"
                    name="website"
                    value={website}
                    onChange={websiteHandler}
                    className="h-14 rounded-xl border w-full mt-3 p-4"
                    required
                  />
                </label>
                <div className="col-span-3 mt-9 mb-24">
                  <button
                    type="submit"
                    className="block w-56 h-12 mx-auto bg-neon-pink text-white rounded-full font-bold shadow-lg"
                    onSubmit={submitCommentHandler}
                  >
                    post comment
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="bg-dark-denim">
          <div className="container mx-auto grid md:grid-cols-3 gap-9 pt-16 pb-24 md:pb-28 px-4">
            <h2 className="col-span-3 text-white text-center pb-6 text-2xl font-bold">
              you may
              <span className="text-bayside-cyan"> be interested </span>
              in
            </h2>
            {blogs.slice(0, 3).map((blog, index) => (
              <BlogCard key={index} blog={blog} />
            ))}
          </div>
        </div>
      </MainLayout>
    )
  );
}

export default SingleBlog;
