import React from "react";

const Loader = () => {
  return (
    <div className="loading" style={{ zIndex: "200000" }}>
      <div className="container" style={{ zIndex: "200000" }}>
        <img
          className="loader"
          src={require("../../assets/img/general/loading.gif")}
          alt=""
          style={{ zIndex: "200000" }}
        />
      </div>
    </div>
  );
};

export default Loader;
