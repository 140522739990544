import React from 'react';
import Work from '../components/Work.jsx/Work';
import MainLayout from '../layouts/MainLayout';

export default function work() {
  return (
    <MainLayout>
      <Work />
    </MainLayout>
  );
}
