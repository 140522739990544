import React from "react";
import HomeDivisions from "../components/home/HomeDivisions";
import HomeGetInspired from "../components/home/HomeGetInspired";
import HomeHero from "../components/home/HomeHero";
import HomeServices from "../components/home/HomeServices";
import MainLayout from "../layouts/MainLayout";

function Home() {
  return (
    <MainLayout>
      <HomeHero />
      <HomeDivisions />
      <HomeServices />
      <HomeGetInspired />
    </MainLayout>
  );
}

export default Home;
