import React from "react";
import AppModal from "../common/AppModal";
import imgPlanetOrange from "../../assets/img/planets/orange.png";
import imgPlanetLime from "../../assets/img/planets/lime.png";
import imgPlanetWhite from "../../assets/img/planets/white.png";
import imgPlanetCyan from "../../assets/img/planets/cyan.png";
import imgPlanetPink from "../../assets/img/planets/pink.png";
import GuideToGuadalajara from "./GuideToGuadalajara";
import { useDispatch } from "react-redux";
import rokets from "../../assets/img/work/rocket.svg";
import { NavLink } from "react-router-dom";

function Blogs() {
  // const dispatch = useDispatch();

  // const blogList = useSelector((state) => state.blogList);
  // const { loading: loadingAll, error: errorAll, blogs } = blogList;

  const show = true;

  const blogs = [
    {
      image: require("../../assets/img/blog/city.png"),
      title: "Lorem ipsum dolor",
      subtitle: "sbutitle",
      className: "col-span-4 lg:col-span-2",
      modalContent: <GuideToGuadalajara />,
    },
    {
      image: require("../../assets/img/blog/cold-landscape.png"),
      title: "Lorem ipsum dolor",
      subtitle: "sbutitle",
      className: "col-span-4 lg:col-span-2",
      modalContent: <GuideToGuadalajara />,
    },
    {
      image: require("../../assets/img/blog/desert-landscape.png"),
      title: "Lorem ipsum dolor",
      subtitle: "sbutitle",
      className: "col-span-2 lg:col-span-1",
      modalContent: <GuideToGuadalajara />,
    },
    {
      image: require("../../assets/img/blog/landscape.png"),
      title: "Lorem ipsum dolor",
      subtitle: "sbutitle",
      className: "col-span-2 lg:col-span-1",
      modalContent: <GuideToGuadalajara />,
    },
    {
      image: require("../../assets/img/blog/sheet.png"),
      title: "Lorem ipsum dolor",
      subtitle: "sbutitle",
      className: "col-span-2 lg:col-span-1",
      modalContent: <GuideToGuadalajara />,
    },
    {
      image: require("../../assets/img/blog/canoe.png"),
      title: "Lorem ipsum dolor",
      subtitle: "sbutitle",
      className: "col-span-2 lg:col-span-1",
      modalContent: <GuideToGuadalajara />,
    },
    {
      image: require("../../assets/img/blog/airplane.png"),
      title: "Lorem ipsum dolor",
      subtitle: "sbutitle",
      className: "col-span-2 lg:col-span-1",
      modalContent: <GuideToGuadalajara />,
    },
    {
      image: require("../../assets/img/blog/coffee.png"),
      title: "Lorem ipsum dolor",
      subtitle: "sbutitle",
      className: "col-span-2 lg:col-span-1",
      modalContent: <GuideToGuadalajara />,
    },
    {
      image: require("../../assets/img/blog/worker.png"),
      title: "Lorem ipsum dolor",
      subtitle: "sbutitle",
      className: "col-span-4 lg:col-span-2",
      modalContent: <GuideToGuadalajara />,
    },
    {
      image: require("../../assets/img/blog/breakfast.png"),
      title: "Lorem ipsum dolor",
      subtitle: "sbutitle",
      className: "col-span-2 lg:col-span-1",
      modalContent: <GuideToGuadalajara />,
    },
    {
      image: require("../../assets/img/blog/world.png"),
      title: "Lorem ipsum dolor",
      subtitle: "sbutitle",
      className: "col-span-2 lg:col-span-1",
      modalContent: <GuideToGuadalajara />,
    },
    {
      image: require("../../assets/img/blog/city-seen-through-glasses.png"),
      title: "Lorem ipsum dolor",
      subtitle: "sbutitle",
      className: "col-span-4 lg:col-span-1",
      modalContent: <GuideToGuadalajara />,
    },
    {
      image: require("../../assets/img/blog/woman.png"),
      title: "Lorem ipsum dolor",
      subtitle: "sbutitle",
      className: "col-span-2 lg:col-span-1",
      modalContent: <GuideToGuadalajara />,
    },
    {
      image: require("../../assets/img/blog/highway.png"),
      title: "Lorem ipsum dolor",
      subtitle: "sbutitle",
      className: "col-span-2 lg:col-span-2",
      modalContent: <GuideToGuadalajara />,
    },
    {
      image: require("../../assets/img/blog/deer.png"),
      title: "Lorem ipsum dolor",
      subtitle: "sbutitle",
      className: "col-span-2 lg:col-span-1",
      modalContent: <GuideToGuadalajara />,
    },
    {
      image: require("../../assets/img/blog/aurora.png"),
      title: "Lorem ipsum dolor",
      subtitle: "sbutitle",
      className: "col-span-2 lg:col-span-1",
      modalContent: <GuideToGuadalajara />,
    },
  ];

  return (
    <section className="bg-white">
      <div className="relative bg-navy-blue py-6 lg:py-0">
        <div className="flex flex-col lg:flex-row lg:pl-8">
          <h1 className="pl-1 xl:pl-32 text-3xl lg:text-6xl font-bold mx-auto mt-6 lg:mt-[5.3rem] lg:mb-[6.4rem] mb-6 text-bayside-cyan">
            blogs
          </h1>
          <img src={rokets} alt="rokets" className="lg:w-1/2 lg:-mt-2" />
        </div>
      </div>
      <div className="container mx-auto px-4">
        <div className="my-12 md:my-14">
          <h2 className="text-center font-bold text-3xl mb-3 md:mb-4">
            {/* <span className="text-bayside-cyan block text-xl">what we do </span> */}
            discover our
            <span className="text-bayside-cyan block"> latest blogs</span>
          </h2>
          {/* <p className="md:text-center lg:w-10/12 lg:mx-auto lg:tracking-[-0.02em]">
            Forager Media Group specializes in the heritage sector. Our clients
            are from cultural and natural heritage organizations around the
            world and each is a unique opportunity to showcase our team&apos;s
            creativity and extensive technical experience. The heritage sector
            doesn&apos;t always change as quickly as new technologies and
            trends, so Forager Media Group researches, develops, and helps our
            clients implement these new innovative technologies and trends
            in-house and online. Our flexible team is ready to adapt to these
            ever changing environments.
          </p> */}
          <p className="font-bold mt-10 mx-auto text-center text-xl w-2/3">
            take a look at some of our blogs
          </p>
        </div>
        <div>
          <h1 className="text-3xl lg:text-6xl font-bold mx-auto lg:mt-[5.3rem] lg:mb-[3.4rem] text-bayside-cyan text-center">
            New blogs coming soon!
          </h1>
        </div>
        <div className="pt-16 pb-20 max-w-md mx-auto">
          <NavLink
            to="/contact"
            className="hover:bg-dark-denim block py-4 mx-auto rounded-full bg-bayside-cyan text-center text-dark-denim hover:text-white font-bold shadow-lg"
          >
            be our next client. contact us today!
          </NavLink>
        </div>
      </div>
    </section>
    // <div className="mb-20">
    //   <div className="overflow-hidden relative mb-16 bg-navy-blue">
    //     <div className="container mx-auto relative">
    //       <h1 className="text-white text-center pt-[5.4rem] container mx-auto pb-32 px-14">
    //         harvesting the best trends from around the world
    //         <span className="text-neon-pink text-2xl md:text-6xl font-bold block">
    //           <span className="text-white">the fmg </span>
    //           blog
    //         </span>
    //       </h1>
    //       <div className="flex">
    //         <img
    //           src={imgPlanetOrange}
    //           alt="planet orange"
    //           className="w-[4.4rem] lg:w-32 h-[4.4rem] lg:h-32 absolute left-3 md:left-11 top-4 md:top-4 with-transition"
    //         />
    //         <img
    //           src={imgPlanetLime}
    //           alt="planet lime"
    //           className="w-14 lg:w-24 h-14 lg:h-24 absolute left-20 md:left-40 lg:left-72 top-44 md:top-48 xl:top-24 with-transition"
    //         />
    //         <img
    //           src={imgPlanetWhite}
    //           alt="planet white"
    //           className="w-10 lg:w-16 h-10 lg:h-16 absolute left-4 md:left-20 lg:left-40 top-40 with-transition"
    //         />
    //         <img
    //           src={imgPlanetWhite}
    //           alt="planet white"
    //           className="w-6 lg:w-11 h-6 lg:h-11 absolute right-32 md:right-36 lg:right-56 top-2 lg:top-6 with-transition"
    //         />
    //         <img
    //           src={imgPlanetCyan}
    //           alt="planet cyan"
    //           className="w-8 lg:w-16 h-8 lg:h-16 absolute right-3 md:right-9 lg:right-24 top-16 lg:top-20 with-transition"
    //         />
    //         <img
    //           src={imgPlanetPink}
    //           alt="planet pink"
    //           className="w-20 lg:w-36 h-20 lg:h-36 absolute right-10 md:right-36 lg:right-36 top-40 lg:top-32 with-transition"
    //         />
    //       </div>
    //     </div>
    //   </div>
    //   <div className="col-span-4 text-center mb-9">
    //     <h3 className="hidden md:flex justify-center font-bold text-3xl">
    //       our latest blogs
    //     </h3>
    //   </div>
    //   {show ? (
    //     <div className="container mx-auto">
    //       <div className=" grid grid-cols-4 gap-4 px-4">
    //         {blogs.map((e, i) => (
    //           <div
    //             key={i}
    //             className={`shadow-md overflow-hidden relative ${e.className} h-40 md:h-64 rounded-lg group`}
    //           >
    //             <AppModal
    //               activator={
    //                 <>
    //                   <div className="hidden lg:block absolute top-0 h-full w-full group-hover:bg-gradient-to-b from-transparent to-black/50" />
    //                   <div
    //                     className="w-full h-72 bg-cover bg-center bg-no-repeat"
    //                     style={{ backgroundImage: `url(${e.image})` }}
    //                   />
    //                   <div className="lg:hidden group-hover:block absolute bottom-0 w-full p-2 text-xs bg-dark-denim/50 lg:bg-transparent text-white">
    //                     <h3>{e.title}</h3>
    //                     <span>{e.subtitle}</span>
    //                   </div>
    //                 </>
    //               }
    //             >
    //               {e.modalContent}
    //             </AppModal>
    //           </div>
    //         ))}
    //       </div>
    //     </div>
    //   ) : (
    //     <div>
    //       <h1 className="text-3xl lg:text-6xl font-bold mx-auto lg:mt-[5.3rem] lg:mb-[6.4rem] text-bayside-cyan text-center">
    //         New blogs coming soon!
    //       </h1>
    //     </div>
    //   )}
    // </div>
  );
}

export default Blogs;
