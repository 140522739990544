import React from 'react';
import { Provider } from 'react-redux';
import store from '../store';
import MainLayout from '../layouts/MainLayout';
import Contact from '../components/contact/Contact';

function ContactUs() {
  return (
    <Provider store={store}>
      <MainLayout>
        <Contact />
      </MainLayout>
    </Provider>
  );
}

export default ContactUs;
