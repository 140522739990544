import React from 'react';
import Service from '../../../components/divisions/divisionVirtual/services/Services';
import DivisionLayout from '../../../layouts/DivisionLayout';

function Services() {
  const links = {
    about: '/division/virtual/about',
    services: '/division/virtual/services',
    work: '/division/virtual/work',
  };
  return (
    <DivisionLayout colorClass="bg-lime-green" links={links} divisionName="virtual">
      <Service />
    </DivisionLayout>
  );
}

export default Services;
