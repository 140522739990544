import React from "react";

import LogoFacebook from "../../assets/logos/logo-facebook.svg";
import LogoInstagram from "../../assets/logos/logo-instagram.svg";
import LogoLinkedin from "../../assets/logos/logo-linkedin.svg";
import LogoTwitter from "../../assets/logos/logo-twitter.svg";
import LogoTikTok from "../../assets/logos/logo-Tiktok.svg";
import LogoYoutube from "../../assets/logos/logo-youtube.svg";

function SocialLinks({ margin }) {
  return (
    <div className={`hidden md:flex gap-4 ${margin}`}>
      <a
        target="_blank"
        rel="noreferrer"
        href="https://www.facebook.com/foragernet"
        className="flex-grow-0 flex-shrink-0"
      >
        <img src={LogoFacebook} alt="Facebook logo" />
      </a>
      <a
        target="_blank"
        rel="noreferrer"
        href="https://www.instagram.com/foragernet/"
        className="flex-grow-0 flex-shrink-0"
      >
        <img src={LogoInstagram} alt="instagram logo" />
      </a>
      <a
        target="_blank"
        rel="noreferrer"
        href="https://twitter.com/foragernet"
        className="flex-grow-0 flex-shrink-0"
      >
        <img src={LogoTwitter} alt="twitter logo" />
      </a>
      <a
        target="_blank"
        rel="noreferrer"
        href="https://www.tiktok.com/@foragernet"
        className="flex-grow-0 flex-shrink-0"
      >
        <img src={LogoTikTok} alt="twitter logo" />
      </a>
      <a
        target="_blank"
        rel="noreferrer"
        href="https://www.youtube.com/@ForagerNetwork"
        className="flex-grow-0 flex-shrink-0"
      >
        <img src={LogoYoutube} alt="linkedin logo" />
      </a>
      <a
        target="_blank"
        rel="noreferrer"
        href="https://www.linkedin.com/company/forager-media-group/"
        className="flex-grow-0 flex-shrink-0"
      >
        <img src={LogoLinkedin} alt="linkedin logo" />
      </a>
      
    </div>
  );
}

export default SocialLinks;
