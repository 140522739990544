import React from "react";
import PromoteCard from "../../PromoteCard";
import iconPlay from "../../../../assets/icons/play.png";
import book from "../../../../assets/icons/book.svg";
import institution from "../../../../assets/icons/institution.svg";
import medal from "../../../../assets/icons/medal.svg";
import technology from "../../../../assets/img/division/virtual/technology.png";

function Work() {
  const steps = [
    {
      icon: iconPlay,
      heading: "bring virtual guests in",
      text: "We aim to: create virtual tour resources for guests to explore your site without having to leave home. Reach an audience that is far beyond your physical location.",
    },
    {
      icon: book,
      heading: "boost education programs",
      text: "We aim to: create resources that help you boost your education programs through exciting scavenger hunts, virtual quizzes, and other materials that make kids more engaged.",
    },
    {
      icon: institution,
      heading: "promote your institution",
      text: "We aim to: create an engaged audience by allowing them to see your location in an exciting interactive way and let them experience what you have to offer before they arrive.",
    },
    {
      icon: medal,
      heading: "stand out from the pack",
      text: "We aim to: create virtual experiences for visitors. An image on a screen is fine, but being able to interact with objects makes it a more personal experience for your audience.",
    },
  ];
  return (
    <section className="bg-dark-denim -mt-1 pt-16 lg:pt-0">
      <h1 className="text-lime-green text-2xl md:text-3xl text-center font-bold lg:pb-3">
        what
        <span className="text-white"> we will </span>
        do for you
      </h1>
      <div className="px-4 lg:container lg:mx-auto grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-5 pt-14 pb-16 lg:pb-24  text-center">
        {steps.map((ele, index) => (
          <PromoteCard
            key={index}
            icon={ele.icon}
            heading={ele.heading}
            text={ele.text}
          />
        ))}
      </div>
      <div className="bg-white md:py-24">
        <div className="grid grid-cols-1 md:grid-cols-2 items-center gap-y-10 lg:gap-20 lg:container lg:mx-auto">
          <div
            className="md:rounded-3xl w-full mx-0 h-80 division-about"
            style={{
              backgroundImage: `url(${technology})`,
            }}
          ></div>
          <p className="mx-4 pb-3">
            <span className="font-bold">Forager Virtual </span>
            is the division that leads in new technological innovations. Whether we are developing an engaging guided museum tour that integrates augmented reality, building a virtual scavenger hunt for school groups to explore a heritage site, and creating a virtual tour that allows people with mobility issues the opportunity to explore places previously inaccessible, our clients get a team that knows the specifics of this growing industry.
            <span className="text-lime-green text-2xl text-left font-bold block my-6">
              innovation to drive audience engagement
            </span>
            Our designers, developers, and engineers are ready to work with clients big or small to bring their dreams of VR and AR content to life. Don't let the unknown hold you back, our team is here to transport your institution into the future.
          </p>
        </div>
      </div>
    </section>
  );
}

export default Work;
