import {
  CONTACTMAIL_CREATE_REQUEST,
  CONTACTMAIL_CREATE_SUCCESS,
  CONTACTMAIL_CREATE_FAIL,
  CONTACTMAIL_CREATE_RESET,
  CONTACTMAIL_DELETE_REQUEST,
  CONTACTMAIL_DELETE_SUCCESS,
  CONTACTMAIL_DELETE_FAIL,
  CONTACTMAIL_LIST_REQUEST,
  CONTACTMAIL_LIST_SUCCESS,
  CONTACTMAIL_LIST_FAIL,
  CONTACTMAIL_AUTH_REQUEST,
  CONTACTMAIL_AUTH_SUCCESS,
  CONTACTMAIL_AUTH_FAIL,
} from "../constants/contactMailConstants";

// all contactmails
export const contactMailListReducer = (state, action) => {
  switch (action.type) {
    case CONTACTMAIL_LIST_REQUEST:
      return {
        loading: true,
      };
    case CONTACTMAIL_LIST_SUCCESS:
      return {
        loading: false,
        contactMails: action.payload,
      };
    case CONTACTMAIL_LIST_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return {};
  }
};

export const contactMailCreateReducer = (state, action) => {
  switch (action.type) {
    case CONTACTMAIL_CREATE_REQUEST:
      return {
        loading: true,
      };
    case CONTACTMAIL_CREATE_SUCCESS:
      return {
        loading: false,
        success: true,
      };
    case CONTACTMAIL_CREATE_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case CONTACTMAIL_CREATE_RESET:
      return {
        loading: false,
        success: null,
      };
    default:
      return {};
  }
};

// delete mail
export const contactMailDeleteReducer = (state, action) => {
  switch (action.type) {
    case CONTACTMAIL_DELETE_REQUEST:
      return {
        loading: true,
      };
    case CONTACTMAIL_DELETE_SUCCESS:
      return {
        loading: false,
        success: true,
      };
    case CONTACTMAIL_DELETE_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return {};
  }
};

// authorize admin
export const contactMailAuthReducer = (state, action) => {
  switch (action.type) {
    case CONTACTMAIL_AUTH_REQUEST:
      return {
        loading: true,
      };
    case CONTACTMAIL_AUTH_SUCCESS:
      return {
        loading: false,
        success: true,
      };
    case CONTACTMAIL_AUTH_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return {};
  }
};
