import React from "react";
import digitalNomads from "../../assets/img/layouts/digital-nomads.png";
import vacationMarketing from "../../assets/img/layouts/vacation-marketing.png";
import incaPlebeyoImg from "../../assets/img/layouts/Inca-plebeyo.png";
import elIncaPlebeyoImg from "../../assets/img/layouts/el-inca-plebeyo.png";
import FoundationImg from "../../assets/img/layouts/foundation-forager.png";
import upfullFoodsImg from "../../assets/img/layouts/upfull-foods.png";
import imgPlanetCyan from "../../assets/img/planets/cyan.png";
import imgPlanetLime from "../../assets/img/planets/lime.png";
import imgPlanetOrange from "../../assets/img/planets/orange.png";
import imgPlanetPink from "../../assets/img/planets/pink.png";
import { ReactComponent as LogoShort } from "../../assets/logos/logo-short-forager-media-group.svg";
import SocialLinks from "../../components/general/SocialLinks";

function AppFooter() {
  const showFooterBlog = false;
  const showFooterWork = false;

  return (
    <footer>
      <div className="relative overflow-hidden px-4 md:px-24 bg-dark-denim text-white">
        <div className="container grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-y-11 md:gap-x-16 md:mx-auto pt-16">
          <div>
            <LogoShort />
            <p className="pt-4">
              A boutique creative agency specializing in the heritage sector. We
              are a team made up of digital nomads from all over the world.
              Exploration and collaboration is something that brings a unique
              edge to Forager Media Group.
            </p>
            <h4 className="text-2xl font-bold mt-6">follow us</h4>
            <SocialLinks margin="md:mt-6" />
          </div>
          {showFooterBlog ? (
            <div>
              <h4 className="text-2xl font-bold">our latest blogs</h4>
              <div className="flex gap-6 mt-5">
                <img src={digitalNomads} alt="foundation forager" />
                <p>
                  A digital nomad&apos;s
                  <span className="block">Updates coming soon!</span>
                </p>
              </div>
              <div className="flex gap-6 mt-5">
                <img src={vacationMarketing} alt="foundation forager" />
                <p>
                  Vacation rental marketing
                  <span className="block">Updates coming soon!</span>
                </p>
              </div>
              <div className="flex gap-6 mt-5">
                <img src={incaPlebeyoImg} alt="foundation forager" />
                <p>
                  El inca plebeyo
                  <span className="block">Updates coming soon!</span>
                </p>
              </div>
            </div>
          ) : (
            <div>
              <h4 className="text-2xl font-bold">our latest blogs</h4>
              <div className="flex gap-6 mt-5">
                <p>
                  <span className="block">Updates coming soon!</span>
                </p>
              </div>
            </div>
          )}
          {showFooterWork ? (
            <div>
              <h4 className="text-2xl font-bold">our work</h4>
              <div className="flex gap-6 mt-5">
                <img src={FoundationImg} alt="foundation forager" />
                <p>
                  Forager Foundation
                  <span className="block">Updates coming soon!</span>
                </p>
              </div>
              <div className="flex gap-6 mt-5">
                <img src={upfullFoodsImg} alt="foundation forager" />
                <p>
                  upfull foods
                  <span className="block">Updates coming soon!</span>
                </p>
              </div>
              <div className="flex gap-6 mt-5">
                <img src={elIncaPlebeyoImg} alt="foundation forager" />
                <p>
                  el inca plebeyo
                  <span className="block">Updates coming soon!</span>
                </p>
              </div>
            </div>
          ) : (
            <div>
              <h4 className="text-2xl font-bold">our work</h4>
              <div className="flex gap-6 mt-5">
                <p>
                  <span className="block">Updates coming soon!</span>
                </p>
              </div>
            </div>
          )}
          <div className="flex gap-y-5 flex-col">
            <h4 className="text-2xl font-bold">contact us</h4>
            <p>
              <span className="font-bold">europe hq: </span>
              Cork, Ireland
            </p>
            <p>
              <span className="font-bold">north america hq: </span>
              Gibsons, Canada
            </p>
            <p>
              <span className="font-bold">phone: </span>
              <a href="tel:+12505694401">+1 250 569 4401</a>
            </p>
            <p className="font-bold">
              general enquiries:
              <span className="font-normal block">
                <a href="mailto:info@foragermediagroup.com">
                  info@foragermediagroup.com
                </a>
              </span>
            </p>
            <p className="font-bold">
              new projects:
              <span className="font-normal block">
                <a href="mailto:sales@foragermediagroup.com">
                  sales@foragermediagroup.com
                </a>
              </span>
            </p>
            <p className="pb-5 font-bold">
              careers:
              <span className="font-normal block">
                <a href="mailto:careers@foragermediagroup.com">
                  careers@foragermediagroup.com
                </a>
              </span>
            </p>
          </div>
        </div>
        <div className="pb-2 text-center mt-16">
          <hr />
          <span className="text-[0.5rem] md:text-xs">
            © Copyright 2022, Forager Group Holdings Ltd. All rights reserved.
          </span>
        </div>
        <div className="flex flex-col gap-y-11 container mx-auto px-4">
          <img
            src={imgPlanetPink}
            alt="planet Lime"
            className="w-36 md:w-52 absolute bottom-32 md:-bottom-24 -right-16 md:right-0 md:-left-10"
          />
          <img
            src={imgPlanetCyan}
            alt="planet cyan"
            className="w-12 md:w-20 absolute top-96 lg:top-72 right-14 lg:right-[42rem] 2xl:right-1/2"
          />
          <img
            src={imgPlanetLime}
            alt="planet Lime"
            className="w-9 md:w-14 absolute bottom-96 md:bottom-12 right-5"
          />
        </div>
      </div>
      <div className="relative">
        <img
          src={imgPlanetOrange}
          alt="planet orange"
          className="w-24 lg:w-40 absolute -top-[66rem] md:-top-[60rem] lg:-top-[57rem] xl:-top-[38rem] right-14 lg:right-5"
        />
      </div>
    </footer>
  );
}

export default AppFooter;
