import React from "react";

function BlogComment({ comment }) {
  return (
    <div
      style={{
        padding: "30px",
        display: "flex",
        flexDirection: "column",
        gap: "10px",
      }}
      className="rounded-xl border shadow-lg bg-white"
    >
      <h2
        style={{
          fontSize: "1rem",
          color: "var(--dark-denim)",
          display: "flex",
        //   justifyContent: "flex-start",
          gap: "10px"
        }}
      >
        <span style={{ fontWeight: "bold" }}>{comment.person}</span>{" "}
        <span>({comment.email})</span>
        <span style={{ justifySelf: "flex-end" }}>
          {comment.date.toString().substr(0, 10).split("-").reverse().join("/")}
        </span>
      </h2>
      <p>{comment.comment}</p>
    </div>
  );
}

export default BlogComment;
