import React from "react";
import { createBrowserRouter } from "react-router-dom";
import About from "./pages/About";
import Home from "./pages/Home";
import Work from "./pages/Work";
import Industries from "./pages/Industries";
import Contact from "./pages/Contact";
import Blog from "./pages/Blog";
import Admin from "./pages/Admin";
import Error404 from "./pages/Error404";
import DivisionDigital from "./pages/division/digital/About";
import DivisionDigitalService from "./pages/division/digital/Services";
import DivisionDigitalWork from "./pages/division/digital/Work";
import VirtualAbout from "./pages/division/virtual/About";
import VirtualServices from "./pages/division/virtual/Services";
import VirtualWork from "./pages/division/virtual/Work";
import ProductionsAbout from "./pages/division/productions/About";
import ProductionsServices from "./pages/division/productions/Services";
import ProductionsWork from "./pages/division/productions/Work";
import PublishingAbout from "./pages/division/publishing/About";
import PublishingServices from "./pages/division/publishing/Services";
import PublishingWork from "./pages/division/publishing/Work";
import SingleBlog from "./pages/SingleBlog";
import CreateNewBlog from "./pages/CreateNewBlog";
import { Provider } from "react-redux";
import store from "./store";

const router = createBrowserRouter([
  { path: "/", element: <Home /> },
  { path: "/about", element: <About /> },
  {
    path: "/division",
    children: [
      { path: "/division/digital/about", element: <DivisionDigital /> },
      {
        path: "/division/digital/services",
        element: <DivisionDigitalService />,
      },
      { path: "/division/digital/work", element: <DivisionDigitalWork /> },
      { path: "/division/virtual/about", element: <VirtualAbout /> },
      { path: "/division/virtual/services", element: <VirtualServices /> },
      { path: "/division/virtual/work", element: <VirtualWork /> },
      { path: "/division/productions/about", element: <ProductionsAbout /> },
      {
        path: "/division/productions/services",
        element: <ProductionsServices />,
      },
      { path: "/division/productions/work", element: <ProductionsWork /> },
      { path: "/division/publishing/about", element: <PublishingAbout /> },
      {
        path: "/division/publishing/services",
        element: <PublishingServices />,
      },
      { path: "/division/publishing/work", element: <PublishingWork /> },
    ],
  },
  { path: "/industries", element: <Industries /> },
  { path: "/work", element: <Work /> },
  { path: "/blog", element: <Blog /> },
  {
    path: "/blog/:slug",
    element: (
      <Provider Provider store={store}>
        <SingleBlog />
      </Provider>
    ),
  },
  {
    path: "/admin/add-blog",
    element: (
      <Provider Provider store={store}>
        <CreateNewBlog />
      </Provider>
    ),
  },
  { path: "/contact", element: <Contact /> },
  { path: "/admin", element: <Admin /> },
  { path: "*", element: <Error404 /> },
]);

export default router;
