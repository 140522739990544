import React from 'react';
import DivisionLayout from '../../../layouts/DivisionLayout';
import Services from '../../../components/divisions/divisionPublishing/services/Services';

function ServicesPublishing() {
  const links = {
    about: '/division/publishing/about',
    services: '/division/publishing/services',
    work: '/division/publishing/work',
  };
  return (
    <DivisionLayout colorClass="bg-neon-pink" links={links} divisionName="publishing">
      <Services />
    </DivisionLayout>
  );
}

export default ServicesPublishing;
