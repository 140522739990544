import React from 'react';
import imgPlanetOrange from '../../assets/img/planets/orange.png';
import imgPlanetLime from '../../assets/img/planets/lime.png';
import imgPlanetWhite from '../../assets/img/planets/white.png';
import imgPlanetCyan from '../../assets/img/planets/cyan.png';
import imgPlanetPink from '../../assets/img/planets/pink.png';
import temple from '../../assets/img/industries/temple.png';
import templeMobile from '../../assets/img/industries/temple-mobile.png';
import explorerSpace from '../../assets/img/industries/explore_space_exploration.svg';
import explorerHiking from '../../assets/img/industries/Explore_Hiking_in_foot.svg';
import explorerNavegate from '../../assets/img/industries/Explore_river_exploration.svg';
import explorerOcean from '../../assets/img/industries/ocean_exploration.svg';
import explorerUnderwater from '../../assets/img/industries/explore_underwater_exploration.svg';
import explorerAir from '../../assets/img/industries/Explore_air_exploration.svg';
import heritage from '../../assets/img/industries/heritage.png';
import tourism from '../../assets/img/industries/tourism.png';
import foods from '../../assets/img/industries/foods.png';

function Industries() {
  return (
    <section className="mb-16 lg:mb-40">
      <div className="bg-navy-blue">
        <div className="relative container mx-auto text-center">
          <h1 className="mx-auto pt-[4.6rem] pb-[6.6rem] font-bold text-3xl md:text-6xl text-white">industries</h1>
          <img src={imgPlanetOrange} alt="planet orange" className="w-[4.4rem] lg:w-32 h-[4.4rem] lg:h-32 absolute left-3 md:left-11 top-4 md:top-4 with-transition" />
          <img src={imgPlanetLime} alt="planet lime" className="w-14 lg:w-24 h-14 lg:h-24 absolute left-20 md:left-40 lg:left-72 top-32 md:top-24 with-transition" />
          <img src={imgPlanetWhite} alt="planet white" className="w-10 lg:w-16 h-10 lg:h-16 absolute left-4 md:left-20 lg:left-40 top-40 with-transition" />
          <img src={imgPlanetWhite} alt="planet white" className="w-6 lg:w-11 h-6 lg:h-11 absolute right-32 md:right-36 lg:right-56 top-2 lg:top-6 with-transition" />
          <img src={imgPlanetCyan} alt="planet cyan" className="w-8 lg:w-16 h-8 lg:h-16 absolute right-3 md:right-9 lg:right-24 top-16 lg:top-20 with-transition" />
          <img src={imgPlanetPink} alt="planet pink" className="w-20 lg:w-36 h-20 lg:h-36 absolute right-10 md:right-36 lg:right-56 top-[7.5rem] lg:top-32 with-transition" />
        </div>
      </div>
      <div className="bg-white container mx-auto">
        <div className="pt-11 lg:pt-16 grid grid-rows-auto grid-cols-2 items-center gap-4">
          <div className="col-span-2 lg:col-span-1 order-1">
            <h2 className="font-bold lg:text-left px-4 text-2xl text-navy-blue">
              innovation with a focus on heritage
            </h2>
          </div>
          <div className="col-span-2 lg:col-span-1 order-2 lg:order-3">
            <p className="px-4">
              In 2020, when the world was gripped by the COVID-19 pandemic, our team experienced a complete reset in how we did business, who we worked with, and how we approached our work. Stripping everything down to the foundations, Bryce took a step back and looked for ways to reengage with his anthropological background. Since then, Forager Media Group has relaunched with a specific focus on heritage.
            </p>
          </div>
          <div className="col-span-2 lg:col-span-1 order-4">
            <p className="px-4 pb-6 lg:pb-0">
              With new partnerships in the heritage sector added to the Forager community each month, our team is gaining the insights and insider knowledge to be able to address the specific needs of an industry that is so often overlooked. Heritage organizations like museums, art galleries, science centres, and cultural centres, are important institutions within communities for preserving local history, promoting tourism, and creating opportunities for community members to come together and build greater bonds.
            </p>
          </div>
          <div className="col-span-2 lg:col-span-1 order-4 lg:order-2 row-span-4 grid overflow-hidden w-full">
            <img src={temple} alt="industries" className="hidden md:block pt-2 w-full max-h-[32rem] md:rounded-r-[30px]" />
            <img src={templeMobile} alt="industries" className="md:hidden pt-2 w-full max-h-[32rem]" />
          </div>
          <div className="col-span-2 lg:col-span-1 order-5 lg:pb-2 2xl:pb-20 ">
            <p className="px-4 pt-6 md:pt-2">
              The work of Forager Media Group is to provide support to these partnering organizations and find ways to bring new trends, new technologies, and new perspectives to the projects being planned. It is our passion for this industry that shines through with our partners, and they can see that our team is committed to seeing projects through and achieving the goals of each partner, whether they be a tiny local organization or a large national institution.
            </p>
          </div>
        </div>
        <div className="container mx-auto flex flex-wrap xl:px-0">
          <div className="grid grid-cols-3 sm:grid-cols-6 mx-auto pt-12 pb-20 lg:pt-14">
            <img src={explorerSpace} alt="exploring space" className="w-full lg:w-[11.7rem]" />
            <img src={explorerHiking} alt="exploring ocean" className="w-full" />
            <img src={explorerNavegate} alt="exploring space" className="w-full" />
            <img src={explorerUnderwater} alt="exploring ocean" className="w-full" />
            <img src={explorerOcean} alt="exploring ocean" className="w-full" />
            <img src={explorerAir} alt="exploring ocean" className="w-full" />
          </div>
          <div className="flex flex-wrap justify-center gap-y-7 m-auto">
            <article className="card w-[22rem] md:w-full md:h-32 flex md:flex-row bg-base-100 shadow-xl rounded-xl overflow-hidden text-center">
              <div className="card md:flex-grow md:1/2 bg-base-100">
                <figure><img src={heritage} alt="heritage" className="h-32 w-full" /></figure>
                <h2 className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 card-title text-white m-auto font-bold text-3xl">heritage</h2>
              </div>
              <div className="md:w-1/2 bg-white px-6 py-6 lg:pl-20 lg:pr-36 my-auto">
                <p className="text-base">
                  Working with institutions in this sector gives us the opportunity to bring natural and cultural heritage alive in new and exciting ways.
                </p>
              </div>
            </article>
            <article className="card w-[22rem] md:w-full md:h-32 flex md:flex-row bg-base-100 shadow-xl rounded-xl overflow-hidden text-center">
              <div className="card md:flex-grow md:1/2">
                <figure><img src={tourism} alt="heritage" className="h-32 w-full" /></figure>
                <h2 className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 card-title text-white m-auto font-bold text-3xl">tourism </h2>
              </div>
              <div className="md:w-1/2 bg-white px-6 py-6 lg:pl-20 lg:pr-36 my-auto">
                <p className="text-base">
                  Working with tourism organizations that use cultural appreciation as a driving force lets us adapt a god story that also does good in the community.
                </p>
              </div>
            </article>
            <article className="card w-[22rem] md:w-full md:h-32 flex md:flex-row bg-base-100 shadow-xl rounded-xl overflow-hidden text-center">
              <div className="card md:flex-grow md:1/2">
                <figure><img src={foods} alt="heritage" className="h-32 w-full" /></figure>
                <h2 className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 card-title text-white m-auto font-bold text-3xl">food</h2>
              </div>
              <div className="md:w-1/2 bg-white px-6 py-6 lg:pl-20 lg:pr-36 my-auto">
                <p className="text-base">
                  Working with heritage-minded food brands keeps us hungry for trying new things and there is nothing brings people together like food.
                </p>
              </div>
            </article>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Industries;
