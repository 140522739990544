export const CONTACTMAIL_CREATE_REQUEST = 'CONTACTMAIL_CREATE_REQUEST';
export const CONTACTMAIL_CREATE_SUCCESS = 'CONTACTMAIL_CREATE_SUCCESS';
export const CONTACTMAIL_CREATE_FAIL = 'CONTACTMAIL_CREATE_FAIL';
export const CONTACTMAIL_CREATE_RESET = 'CONTACTMAIL_CREATE_RESET';

export const CONTACTMAIL_DELETE_REQUEST = 'CONTACTMAIL_DELETE_REQUEST';
export const CONTACTMAIL_DELETE_SUCCESS = 'CONTACTMAIL_DELETE_SUCCESS';
export const CONTACTMAIL_DELETE_FAIL = 'CONTACTMAIL_DELETE_FAIL';

export const CONTACTMAIL_LIST_REQUEST = 'CONTACTMAIL_LIST_REQUEST';
export const CONTACTMAIL_LIST_SUCCESS = 'CONTACTMAIL_LIST_SUCCESS';
export const CONTACTMAIL_LIST_FAIL = 'CONTACTMAIL_LIST_FAIL';

export const CONTACTMAIL_AUTH_REQUEST = 'CONTACTMAIL_AUTH_REQUEST';
export const CONTACTMAIL_AUTH_SUCCESS = 'CONTACTMAIL_AUTH_SUCCESS';
export const CONTACTMAIL_AUTH_FAIL = 'CONTACTMAIL_AUTH_FAIL';
