import React from "react";
import { NavLink } from "react-router-dom";
import rokets from "../../assets/img/work/rocket.svg";

function Work() {
  const show = false;
  const works = [
    {
      image: require("../../assets/img/work/orange.png"),
      title: "Lorem ipsum dolor",
      subtitle: "sbutitle",
      className: "col-span-4 lg:col-span-2",
    },
    {
      image: require("../../assets/img/work/building.png"),
      title: "Lorem ipsum dolor",
      subtitle: "sbutitle",
      className: "col-span-4 lg:col-span-2",
    },
    {
      image: require("../../assets/img/work/table.png"),
      title: "Lorem ipsum dolor",
      subtitle: "sbutitle",
      className: "col-span-2 lg:col-span-1",
    },
    {
      image: require("../../assets/img/work/chair.png"),
      title: "Lorem ipsum dolor",
      subtitle: "sbutitle",
      className: "col-span-2 lg:col-span-1",
    },
    {
      image: require("../../assets/img/work/monkey.png"),
      title: "Lorem ipsum dolor",
      subtitle: "sbutitle",
      className: "col-span-2 lg:col-span-1",
    },
    {
      image: require("../../assets/img/work/woman.png"),
      title: "Lorem ipsum dolor",
      subtitle: "sbutitle",
      className: "col-span-2 lg:col-span-1",
    },
  ];
  return (
    <section className="bg-white">
      <div className="relative bg-navy-blue py-6 lg:py-0">
        <div className="flex flex-col lg:flex-row lg:pl-8">
          <h1 className="pl-1 xl:pl-32 text-3xl lg:text-6xl font-bold mx-auto mt-6 lg:mt-[5.3rem] lg:mb-[6.4rem] mb-6 text-bayside-cyan">
            <span className="text-white">we harvest </span>
            results
          </h1>
          <img src={rokets} alt="rokets" className="lg:w-1/2 lg:-mt-2" />
        </div>
      </div>
      <div className="container mx-auto px-4">
        <div className="my-12 md:my-14">
          <h2 className="text-center font-bold text-3xl mb-3 md:mb-4">
            <span className="text-bayside-cyan block text-xl">what we do </span>
            discover our
            <span className="text-neon-pink block"> featured client work</span>
          </h2>
          <p className="md:text-center lg:w-10/12 lg:mx-auto lg:tracking-[-0.02em]">
            Forager Media Group specializes in the heritage sector. Our clients
            are from cultural and natural heritage organizations around the
            world and each is a unique opportunity to showcase our team&apos;s
            creativity and extensive technical experience. The heritage sector
            doesn&apos;t always change as quickly as new technologies and
            trends, so Forager Media Group researches, develops, and helps our
            clients implement these new innovative technologies and trends
            in-house and online. Our flexible team is ready to adapt to these
            ever changing environments.
          </p>
          <p className="font-bold mt-10 mx-auto text-center text-xl w-2/3">
            take a look at some of our latest work
          </p>
        </div>
        {show ? (
          <div className="container mx-auto">
            <div className=" grid grid-cols-4 gap-4 px-4">
              {works.map((e, i) => (
                <div
                  key={i}
                  className={`shadow-md overflow-hidden relative ${e.className} h-40 md:h-64 rounded-lg group`}
                >
                  <div className="hidden lg:block absolute top-0 h-full w-full group-hover:bg-gradient-to-b from-transparent to-black/50" />
                  <div
                    className="w-full h-72 bg-cover bg-center bg-no-repeat"
                    style={{ backgroundImage: `url(${e.image})` }}
                  />
                  <div className="lg:hidden group-hover:block absolute bottom-0 w-full p-2 text-xs bg-dark-denim/50 lg:bg-transparent text-white">
                    <h3>{e.title}</h3>
                    <span>{e.subtitle}</span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ) : (
          <div>
            <h1 className="text-3xl lg:text-6xl font-bold mx-auto lg:mt-[5.3rem] lg:mb-[6.4rem] text-bayside-cyan text-center">
              New projects coming soon!
            </h1>
          </div>
        )}
        <div className="pt-16 pb-20 max-w-md mx-auto">
          <NavLink
            to="/contact"
            className="hover:bg-dark-denim block py-4 mx-auto rounded-full bg-neon-pink text-center text-white font-bold shadow-lg"
          >
            be our next client. contact us today!
          </NavLink>
        </div>
      </div>
    </section>
  );
}

export default Work;
