import React from 'react';
import DivisionLayout from '../../../layouts/DivisionLayout';
import AboutExplorer from '../../../components/divisions/divisionPublishing/about/AboutExplorer';
import AboutWork from '../../../components/divisions/divisionPublishing/about/AboutWork';
import AboutServices from '../../../components/divisions/divisionPublishing/about/AboutServices';

function About() {
  const links = {
    about: '/division/publishing/about',
    services: '/division/publishing/services',
    work: '/division/publishing/work',
  };
  return (
    <DivisionLayout colorClass="bg-neon-pink" links={links} divisionName="publishing">
      <AboutExplorer />
      <AboutWork />
      <AboutServices />
    </DivisionLayout>
  );
}

export default About;
