import React from 'react';
import About from '../components/about/About';
import MainLayout from '../layouts/MainLayout';

export default function Abouts() {
  return (
    <MainLayout>
      <About />
    </MainLayout>
  );
}
