import React from 'react';
import DivisionLayout from '../../../layouts/DivisionLayout';
import DivisionDigitalServices from '../../../components/divisions/divisionDigital/services/Services';

function Services() {
  const links = {
    about: '/division/digital/about',
    services: '/division/digital/services',
    work: '/division/digital/work',
  };
  return (
    <DivisionLayout colorClass="bg-princeton-orange" links={links} divisionName="digital">
      <DivisionDigitalServices />
    </DivisionLayout>
  );
}

export default Services;
