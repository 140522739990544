import React from "react";
import { NavLink } from "react-router-dom";

function ServiceCard({ img, service, text, division }) {
  return (
    <article className="mx-auto bg-base-100 shadow-md rounded-3xl flex flex-col overflow-hidden text-center border border-gray-300">
      <img src={img} alt="heritage" className="w-full" />
      <h2 className="text-dark-denim m-auto font-bold text-2xl mt-7">
        {service}
      </h2>
      <div className="card-body bg-white p-7">
        <p className="text-base text-center lg:tracking-[-0.02em]">{text}</p>
        <NavLink
          to={`/division/${division}/services#${service.split(" ").join("-")}`}
          className={`mt-4 w-48 h-12 bg-dark-denim hover:bg-${
            division === "digital"
              ? "princeton-orange"
              : division === "virtual"
              ? "lime-green"
              : division === "productions"
              ? "bayside-cyan"
              : division === "publishing"
              ? "neon-pink"
              : "dark-denim"
          } shadow-md text-white rounded-3xl mx-auto grid place-items-center font-bold`}
        >
          learn more
        </NavLink>
      </div>
    </article>
  );
}

export default ServiceCard;
