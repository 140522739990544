import React from "react";
import { NavLink } from "react-router-dom";
import MainLayout from "../layouts/MainLayout";
import search from "../assets/img/Error404/search.svg";
import imgPlanetOrange from "../assets/img/planets/orange.png";
import imgPlanetLime from "../assets/img/planets/lime.png";
import imgPlanetWhite from "../assets/img/planets/white.png";
import imgPlanetCyan from "../assets/img/planets/cyan.png";
import imgPlanentPink from "../assets/img/planets/pink.png";

function Error404() {
  return (
    <MainLayout>
      <section className="bg-navy-blue h-screen md:h-full">
        <div className="flex flex-col md:flex-row-reverse container mx-auto">
          <img
            src={search}
            alt="Error 404"
            className="w-56 md:w-[27.5rem] py-12 md:py-16 mx-auto"
          />
          <div className="lg:px-4 flex flex-col justify-center text-center md:text-start">
            <h1 className="pb-8 font-bold text-4xl md:text-6xl text-white">
              page not found
            </h1>
            <p className="px-4 md:px-0 pb-9 text-white">
              It looks like this page has moved somewhere else. come back to the
              homepage
            </p>
            <NavLink
              to="/"
              className="py-4 px-8 w-fit block mx-auto md:mx-0 text-white bg-dark-denim rounded-full"
            >
              back to home
            </NavLink>
          </div>
        </div>
        <div className="relative container mx-auto">
          <img
            src={imgPlanetOrange}
            alt="planet orange"
            className="w-8 lg:w-36 right-6 xl:right-[60%] bottom-[30rem] aspect-square absolute with-transition"
          />
          <img
            src={imgPlanetCyan}
            alt="planet cyan"
            className="w-11 lg:w-20 left-6 bottom-96 md:bottom-[26rem] lg:bottom-20 aspect-square absolute with-transition"
          />
          <img
            src={imgPlanetLime}
            alt="planet lime"
            className="w-10 lg:w-20 right-6 md:right-2.5 lg:right-0 bottom-72 lg:-bottom-20 xl:bottom-[27rem] aspect-square absolute with-transition"
          />
          <img
            src={imgPlanetLime}
            alt="planet lime"
            className="w-20 lg:w-12 left-3 lg:left-20 top-12 lg:-top-[34rem] aspect-square absolute with-transition"
          />
          <img
            src={imgPlanetWhite}
            alt="planet white"
            className="w-7 lg:w-14 right-9 lg:right-10 top-6 xl:-top-44 aspect-square absolute with-transition"
          />
          <img
            src={imgPlanentPink}
            alt="planet pink"
            className="w-36 lg:w-72 right-20 lg:right-2/4 top-12 lg:-top-72 aspect-square absolute with-transition"
          />
        </div>
      </section>
    </MainLayout>
  );
}

export default Error404;
