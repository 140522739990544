import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import SingleMail from "./SingleMail";
import {
  listContactMails,
  authContactMails,
} from "../../actions/contactMailActions";

import imgPlanetOrange from "../../assets/img/planets/orange.png";
import imgPlanetLime from "../../assets/img/planets/lime.png";
import imgPlanetCyan from "../../assets/img/planets/cyan.png";
import imgPlanentPink from "../../assets/img/planets/pink.png";

function Email() {
  const navigate = useNavigate();

  const contactMailAuth = useSelector((state) => state.contactMailAuth);
  const { error: errorAuth, success: successAuth } = contactMailAuth;

  const contactMailList = useSelector((state) => state.contactMailList);
  const { error, contactMails } = contactMailList;

  const contactMailDelete = useSelector((state) => state.contactMailDelete);
  const { success } = contactMailDelete;

  const dispatch = useDispatch();
  const [auth, setAuth] = useState(false);
  const [password, setPassword] = useState(``);

  const passwordHandler = (e) => {
    setPassword(e.target.value);
  };

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(authContactMails(password));
    setPassword(``);
  };

  useEffect(() => {
    if (success || successAuth) {
      setAuth(true);
      setPassword(``);
      dispatch(listContactMails());
    }
  }, [success, successAuth]);

  useEffect(() => {
    if (errorAuth) {
      setAuth(false);
      setPassword(``);
      alert("Wrong password!");
      navigate("/");
    }
  }, [errorAuth]);

  useEffect(() => {
    if (error) {
      setAuth(false);
      setPassword(``);
      alert("Something went wrong!");
      navigate("/");
    }
  }, [error]);

  return auth ? (
    <section
      className="bg-navy-blue"
      style={{ display: "flex", justifyContent: "center" }}
    >
      <div className="container px-4 pt-9 pb-24 lg:pb-32 flex flex-col gap-2">
        {contactMails && contactMails.length > 0 ? (
          <>
            {contactMails.map((mail) => (
              <SingleMail mail={mail} key={mail.createdAt} />
            ))}
          </>
        ) : (
          <h1 className="mb-5 lg:pt-36 font-bold text-3xl text-white text-center md:text-start">
            no mails found!
          </h1>
        )}
      </div>
    </section>
  ) : (
    <div className="relative">
      <div
        className="h-[92vh] px-4 lg:px-24 flex flex-col justify-center lg:justify-start bg-navy-blue"
        style={{ display: "flex", justifyContent: "center" }}
      >
        <h1 className="mb-5 lg:pt-36 font-bold text-3xl text-white text-center md:text-start">
          enter password
        </h1>
        <form onSubmit={submitHandler}>
          <input
            type="password"
            name=""
            id=""
            placeholder="enter password here"
            className="px-6 py-5 w-full rounded-[10px]"
            value={password}
            onChange={passwordHandler}
          />
          <input
            type="submit"
            value="submit"
            onSubmit={submitHandler}
            className="mt-6 mb-10 w-48 py-4 mx-auto md:mx-0 bg-dark-denim hover:bg-white rounded-3xl font-bold text-white hover:text-dark-denim shadow-lg block"
          />
        </form>
      </div>
      <img
        src={imgPlanetOrange}
        alt="planet orange"
        className="top-10 left-8 xl:left-[40rem] w-12 aspect-square absolute with-transition"
      />
      <img
        src={imgPlanetLime}
        alt="planet lime"
        className="top-32 lg:top-10 left-20 lg:left-2/4 w-28 md:w-32 aspect-square absolute with-transition"
      />
      <img
        src={imgPlanentPink}
        alt="planet pink"
        className="bottom-8 lg:bottom-[48%] left-10 lg:left-[60%] w-36 lg:w-52 aspect-square absolute with-transition"
      />
      <img
        src={imgPlanetCyan}
        alt="planet cyan"
        className="bottom-28 lg:bottom-[44%] right-20 xl:right-[14%] w-20 md:w-28 aspect-square absolute"
      />
      <img
        src={imgPlanetOrange}
        alt="planet orange"
        className="bottom-60 lg:bottom-60 right-16 lg:right-28 w-9 lg:w-12 aspect-square absolute with-transition"
      />
    </div>
  );
}

export default Email;
