import React from "react";
import { NavLink } from "react-router-dom";
import imgPlanetOrange from "../../../../assets/img/planets/orange.png";
import imgPlanetWhite from "../../../../assets/img/planets/white.png";
import building from "../../../../assets/img/work/building.png";
import building2 from "../../../../assets/img/division/digital/Rectangle 3.png";
import chair from "../../../../assets/img/work/chair.png";
import chair2 from "../../../../assets/img/division/digital/Rectangle 4.png";
import monkey from "../../../../assets/img/work/monkey.png";
import monkey2 from "../../../../assets/img/division/digital/Rectangle 3-1.png";
import orange from "../../../../assets/img/work/orange.png";
import orange2 from "../../../../assets/img/division/digital/Rectangle 86.png";
import table from "../../../../assets/img/work/table.png";
import table2 from "../../../../assets/img/division/digital/portfolio large.png";
import woman from "../../../../assets/img/work/woman.png";
import woman2 from "../../../../assets/img/division/digital/Rectangle 87.png";
import rokets from "../../../../assets/img/work/rocket.svg";

function DivisionDigitalWork() {
  const show = false;

  return (
    <section className="bg-white">
      <div className="relative bg-navy-blue py-16 lg:py-0">
        <div className="flex">
          <h1 className="text-3xl md:text-6xl font-bold m-auto text-princeton-orange">
            <span className="text-white">we harvest </span>
            results
          </h1>
          <img src={rokets} alt="rokets" className="xl:w-1/2 hidden lg:flex" />
        </div>
        <img
          src={imgPlanetWhite}
          alt="planet white"
          className="w-14 h-14 absolute left-4 top-4 lg:hidden"
        />
        <img
          src={imgPlanetOrange}
          alt="planet orange"
          className="w-7 h-7 absolute left-20 top-28 lg:hidden"
        />
        <img
          src={imgPlanetOrange}
          alt="planet orange"
          className="w-9 h-9 absolute right-28 top-4 lg:hidden"
        />
        <img
          src={imgPlanetWhite}
          alt="planet white"
          className="w-5 h-5 absolute right-9 top-12  lg:hidden"
        />
        <img
          src={imgPlanetOrange}
          alt="planet orange"
          className="w-16 h-16 absolute right-5 top-28 lg:hidden"
        />
      </div>
      <div className="container mx-auto px-4">
        <div className="my-14">
          <h2 className="text-center font-bold text-3xl mb-14 md:mb-4">
            <span className="text-princeton-orange text-xl block">
              what we do{" "}
            </span>
            discover our featured digital work
          </h2>
          <p className="md:text-center lg:w-3/4 lg:m-auto">
            Forager Digital specializes in providing the heritage, tourism, and
            food sectors with quality digital services ranging from graphic
            design to web development, brand creation to marketing strategy
            implementation. These sectors require skilled teams to tackle their
            projects, and Forager Digital is that team.
          </p>
          <p className="font-bold mt-10 mx-auto text-center text-xl w-2/3">
            take a look at some of our latest work
          </p>
        </div>
        {show ? (
          <div className="container mx-auto grid grid-cols-4 gap-4">
            <div className="overflow-hidden relative col-span-4 md:col-span-1 h-40 md:h-64 rounded-lg group">
              <div className="hidden lg:block absolute top-0 h-full w-full group-hover:bg-gradient-to-b from-transparent to-black/50" />
              <img src={orange} alt="" className="h-full w-full md:hidden" />
              <img src={orange2} alt="" className="h-full w-full" />
              <div className="lg:hidden group-hover:block absolute bottom-0 w-full p-2 text-xs bg-dark-denim/50 lg:bg-transparent text-white">
                <h3>Lorem ipsum dolor</h3>
                <span>subtitle</span>
              </div>
            </div>
            <div className="overflow-hidden relative col-span-2 md:col-span-1 h-40 md:h-64 rounded-lg group">
              <div className="hidden lg:block absolute top-0 h-full w-full group-hover:bg-gradient-to-b from-transparent to-black/50" />
              <img src={building} alt="" className="h-full w-full md:hidden" />
              <img src={building2} alt="" className="h-full w-full" />
              <div className="lg:hidden group-hover:block absolute bottom-0 w-full p-2 text-xs bg-dark-denim/50 lg:bg-transparent text-white">
                <h3>Lorem ipsum dolor</h3>
                <span>subtitle</span>
              </div>
            </div>
            <div className="overflow-hidden relative col-span-2 md:col-span-2 h-40 md:h-64 rounded-lg group">
              <div className="hidden lg:block absolute top-0 h-full w-full group-hover:bg-gradient-to-b from-transparent to-black/50" />
              <img src={table} alt="" className="h-full w-full md:hidden" />
              <img src={table2} alt="" className="h-full w-full" />
              <div className="lg:hidden group-hover:block absolute bottom-0 w-full p-2 text-xs bg-dark-denim/50 lg:bg-transparent text-white">
                <h3>Lorem ipsum dolor</h3>
                <span>subtitle</span>
              </div>
            </div>
            <div className="overflow-hidden relative col-span-4 md:col-span-2 h-40 md:h-64 rounded-lg group">
              <div className="hidden lg:block absolute top-0 h-full w-full group-hover:bg-gradient-to-b from-transparent to-black/50" />
              <img src={chair} alt="" className="h-full w-full md:hidden" />
              <img src={chair2} alt="" className="h-full w-full" />
              <div className="lg:hidden group-hover:block absolute bottom-0 w-full p-2 text-xs bg-dark-denim/50 lg:bg-transparent text-white">
                <h3>Lorem ipsum dolor</h3>
                <span>subtitle</span>
              </div>
            </div>
            <div className="overflow-hidden relative col-span-2 md:col-span-1 h-40 md:h-64 rounded-lg group">
              <div className="hidden lg:block absolute top-0 h-full w-full group-hover:bg-gradient-to-b from-transparent to-black/50" />
              <img
                src={monkey}
                alt=""
                className="h-full w-full flex md:hidden"
              />
              <img src={monkey2} alt="" className="h-full w-full" />
              <div className="lg:hidden group-hover:block absolute bottom-0 w-full p-2 text-xs bg-dark-denim/50 lg:bg-transparent text-white">
                <h3>Lorem ipsum dolor</h3>
                <span>subtitle</span>
              </div>
            </div>
            <div className="overflow-hidden relative col-span-2 md:col-span-1 h-40 md:h-64 rounded-lg group">
              <div className="hidden lg:block absolute top-0 h-full w-full group-hover:bg-gradient-to-b from-transparent to-black/50" />
              <img src={woman} alt="" className="h-full w-full  md:hidden" />
              <img src={woman2} alt="" className="h-full w-full" />
              <div className="lg:hidden group-hover:block absolute bottom-0 w-full p-2 text-xs bg-dark-denim/50 lg:bg-transparent text-white">
                <h3>Lorem ipsum dolor</h3>
                <span>subtitle</span>
              </div>
            </div>
          </div>
        ) : (
          <div>
            <h1 className="text-3xl lg:text-6xl font-bold mx-auto lg:mt-[5.3rem] lg:mb-[6.4rem] text-princeton-orange text-center">
              New projects coming soon!
            </h1>
          </div>
        )}
        <div className="pt-16 pb-32 max-w-md mx-auto">
          <NavLink
            to="/contact"
            className="block py-4 mx-auto rounded-full bg-princeton-orange text-center hover:bg-dark-denim text-white font-bold shadow-lg"
          >
            be our next client. contact us today!
          </NavLink>
        </div>
      </div>
    </section>
  );
}

export default DivisionDigitalWork;
