import React from 'react';
import MainLayout from '../layouts/MainLayout';
import Blogs from '../components/blog/Blogs';

function Blog() {
  return (
    <MainLayout>
      <Blogs />
    </MainLayout>
  );
}

export default Blog;
