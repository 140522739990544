import React from "react";
import { NavLink } from "react-router-dom";
import brand from "../../../../assets/img/division/digital/brand-development.png";
import design from "../../../../assets/img/division/digital/graphic-design.png";
import marketing from "../../../../assets/img/division/digital/marketing-strategy.png";
import develop from "../../../../assets/img/division/digital/web-development.png";
import rocket from "../../../../assets/img/division/digital/rocket.svg";
import ServiceCard from "../../ServiceCard";

function ServicesAbout() {
  const services = [
    {
      img: brand,
      service: "brand development",
      text: `Our team works to develop a unique style that is all your own. Don&apos;t just write something, make it immediately recognizable as "you". With a full style guideline in-hand, your team will be able to showcase quality in everything they do.`,
    },
    {
      img: design,
      service: "graphic design",
      text: `Our team works to create visually engaging materials that inspire increased sale, audience interaction, and community appreciation. We design the materials that showcase how you are furthering your mandate and developing new program streams.`,
    },
    {
      img: develop,
      service: "web development",
      text: `Our team works to pair the right platform with each client. From WordPress to custom web solutions, we will create your perfect site. Endless options and integrations mean you have no limitations other than your imagination.`,
    },
    {
      img: marketing,
      service: "marketing strategy",
      text: `Our team works with you to make your brand more social by finding the best content for each social network and promoting your events in the community. We create unique content and keep your audience engaged to grow brand awareness.`,
    },
  ];
  return (
    <div className="bg-white">
      <div className="container mx-auto px-0">
        <h1 className="font-bold text-2xl text-center -mt-1 lg:pt-5 pt-12 pb-14 md:pt-0">
          our
          <span className="text-princeton-orange"> design & development </span>
          services
        </h1>
        <section className="px-4 lg:container mx-auto grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-5">
          {services.map((ele, index) => (
            <ServiceCard
              key={index}
              division="digital"
              img={ele.img}
              service={ele.service}
              text={ele.text}
            />
          ))}
        </section>
      </div>
      <div className="overflow-hidden relative mt-20">
        <div className="container mx-auto">
          <div className="sm:w-1/2">
            <p className="px-4 md:mt-5 lg:mt-14">
              We have worked with clients across three continents, from big name
              attractions to small community-led institutions. No matter the
              size, each client we work with gets the same attention to detail,
              experience-driven creativity, and respect towards culturally
              sensitive materials to deliver a project that not only impresses
              our clients but impresses their audiences.
            </p>
            <NavLink
              to="/division/digital/work"
              className="shadow-md mt-14 mb-20 lg:mb-28 w-56 h-12 hover:bg-dark-denim bg-princeton-orange text-white rounded-3xl mx-auto sm:mx-4 grid place-items-center font-bold"
            >
              see portfolio
            </NavLink>
          </div>
        </div>
        <div className="pl-4 bg-princeton-orange pb-10 md:py-12">
          <div className="container mx-auto">
            <img
              src={rocket}
              alt="rocket"
              className=" -rotate-90 sm:rotate-0 sm:absolute sm:bottom-0 sm:-right-48 lg:right-0 sm:max-h-full"
            />
            <div className="sm:w-1/2 xl:pt-10">
              <p className="pr-4 pt-8 font-bold">
                If you are looking for a team that has
                <span className="text-white"> the experience and passion </span>
                to truly understand the needs of the heritage sector,
                <span className="text-white">
                  {" "}
                  Forager Digital is your best resource.{" "}
                </span>
                We are committed to working with institutions of all sizes and
                together with our partner Forager Financial Services, will work
                to find funding that supports
                <span className="text-white"> your next project.</span>
              </p>
              <NavLink
                to="/contact"
                className="shadow-md mt-14 lg:mt-16 mb-14 xl:mb-9 w-48 h-12 hover:bg-white bg-dark-denim text-white hover:text-dark-denim rounded-3xl mx-auto sm:mx-4 grid place-items-center font-bold"
              >
                contact us
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ServicesAbout;
