import React from "react";
import { NavLink } from "react-router-dom";
import video from "../../../../assets/img/division/productions/video.png";
import consola from "../../../../assets/img/division/productions/consola.png";
import camera from "../../../../assets/img/division/productions/camara.png";
import animations from "../../../../assets/img/division/productions/animations.png";
import rocket from "../../../../assets/img/division/digital/rocket.svg";
import ServiceCard from "../../ServiceCard";

function AboutServices() {
  const services = [
    {
      img: video,
      service: "marketing videos",
      text: `Our team works with you to produce exciting videos to showcase at conferences or online. We can take that idea and produce a video that inspires your audience. Video exponentially grows engagement over static posts, so take this chance to innovate.`,
    },
    {
      img: consola,
      service: "audio productions",
      text: `Our team works with your curators to create engaging audio productions whether they are podcasts or audio installations for a new exhibit. Taking simple words on a page and adding life to them brings your visitors in and makes them feel a part of the conversation.`,
    },
    {
      img: camera,
      service: "long-form productions",
      text: `Our team works with your ideas to produce long-form productions like documentaries. If there is a story you want to tell and it can&apost;t be understood in five minutes, our production team can help formulate a narrative and produce your own visual masterpiece.`,
    },
    {
      img: animations,
      service: "animations",
      text: `Our team works on animations as well as live-action productions. When a standard video isn’t enough, our animators can input custom animated features to add an extra layer to your videos. We can animate text-based videos or create fully animated features.`,
    },
  ];

  return (
    <div className="bg-white">
      <div className="container mx-auto px-0">
        <h1 className="font-bold text-2xl text-center -mt-1 lg:pt-5 pt-12 pb-14 md:pt-0">
          our
          <span className="text-bayside-cyan"> production </span>
          services
        </h1>
        <section className="px-4 lg:container mx-auto grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-5">
          {services.map((ele, index) => (
            <ServiceCard
              key={index}
              division="productions"
              img={ele.img}
              service={ele.service}
              text={ele.text}
            />
          ))}
        </section>
      </div>
      <div className="overflow-hidden relative mt-20">
        <div className="container mx-auto">
          <div className="sm:w-1/2">
            <p className="px-4 md:mt-5 lg:mt-14">
              We have worked with clients across three continents, from big name
              attractions to small community-led institutions. No matter the
              size, each client we work with gets the same attention to detail,
              experience-driven creativity, and respect towards culturally
              sensitive materials to deliver a project that not only impresses
              our clients but impresses their audiences.
            </p>
            <NavLink
              to="/division/productions/work"
              className="shadow-md mt-14 mb-20 lg:mb-28 w-56 h-12 hover:bg-dark-denim bg-bayside-cyan text-white rounded-3xl mx-auto sm:mx-4 grid place-items-center font-bold"
            >
              see portfolio
            </NavLink>
          </div>
        </div>
        <div className="pl-4 bg-bayside-cyan pb-10 md:py-12">
          <div className="container mx-auto">
            <img
              src={rocket}
              alt="rocket"
              className="-rotate-90 sm:rotate-0 sm:absolute sm:bottom-0 sm:-right-48 lg:right-0 sm:max-h-full"
            />
            <div className="sm:w-1/2 xl:pt-10">
              <p className="pr-4 pt-8 font-bold">
                If you are looking for a team that has
                <span className="text-white"> the experience and passion </span>
                to truly understand the needs of the heritage sector,
                <span className="text-white">
                  {" "}
                  Forager Productions is your best resource.{" "}
                </span>
                We are committed to working with institutions of all sizes and
                together with our partner Forager Financial Services, will work
                to find funding that supports
                <span className="text-white"> your next project.</span>
              </p>
              <NavLink
                to="/contact"
                className="shadow-md mt-14 lg:mt-16 mb-20 xl:mb-9 w-48 h-12 hover:bg-white bg-dark-denim text-white hover:text-dark-denim rounded-3xl mx-auto sm:mx-4 grid place-items-center font-bold"
              >
                contact us
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutServices;
