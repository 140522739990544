import React from 'react';
import DivisionLayout from '../../../layouts/DivisionLayout';
import DivisionDigitalWork from '../../../components/divisions/divisionDigital/work/Work';

function Work() {
  const links = {
    about: '/division/digital/about',
    services: '/division/digital/services',
    work: '/division/digital/work',
  };
  return (
    <DivisionLayout colorClass="bg-princeton-orange" links={links} divisionName="digital">
      <DivisionDigitalWork />
    </DivisionLayout>
  );
}

export default Work;
