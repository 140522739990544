import React from "react";
import imgPlanetPink from "../../../../assets/img/planets/pink.png";
import imgPlanetWhite from "../../../../assets/img/planets/white.png";
import ghostwriting1 from "../../../../assets/img/divisions/publishing/ghostwriting/1.png";
import ghostwriting2 from "../../../../assets/img/divisions/publishing/ghostwriting/2.png";
import ghostwriting3 from "../../../../assets/img/divisions/publishing/ghostwriting/3.png";
import copy_editing1 from "../../../../assets/img/divisions/publishing/copy_editing/1.png";
import copy_editing2 from "../../../../assets/img/divisions/publishing/copy_editing/2.png";
import copy_editing3 from "../../../../assets/img/divisions/publishing/copy_editing/3.png";
import digital_magazines1 from "../../../../assets/img/divisions/publishing/digital_magazines/1.png";
import digital_magazines2 from "../../../../assets/img/divisions/publishing/digital_magazines/2.png";
import digital_magazines3 from "../../../../assets/img/divisions/publishing/digital_magazines/3.png";
import book_publishing1 from "../../../../assets/img/divisions/publishing/book_publishing/1.png";
import book_publishing2 from "../../../../assets/img/divisions/publishing/book_publishing/2.png";
import book_publishing3 from "../../../../assets/img/divisions/publishing/book_publishing/3.png";

function Services() {
  return (
    <section className="bg-white">
      <div className="bg-navy-blue">
        <div className="container mx-auto relative py-16 lg:pt-9 lg:pb-12">
          <h1 className="text-3xl md:text-6xl font-bold text-center xl:text-left xl:pl-24 text-neon-pink">
            <span className="text-white">our </span>
            services
          </h1>
          <img
            src={imgPlanetWhite}
            alt="planet white"
            className="w-14 h-14 absolute left-4 xl:left-[51rem] 2xl:left-[58rem] top-5 xl:top-8"
          />
          <img
            src={imgPlanetPink}
            alt="planet orange"
            className="w-7 xl:w-36 aspect-square absolute left-20 xl:left-[40rem] top-28 xl:top-8"
          />
          <img
            src={imgPlanetPink}
            alt="planet orange"
            className="w-9 xl:w-28 aspect-square absolute right-28 xl:right-[12.5rem] 2xl:right-[16.5rem] top-4 xl:top-4"
          />
          <img
            src={imgPlanetWhite}
            alt="planet white"
            className="w-5 xl:w-14 aspect-square absolute right-9 xl:right-20 top-12 xl:top-16"
          />
          <img
            src={imgPlanetPink}
            alt="planet orange"
            className="w-16 xl:w-20 aspect-square absolute right-5 xl:-right-8 top-28 xl:-top-7"
          />
        </div>
      </div>
      <div id="ghostwriting" className="bg-dark-denim division-service-ids">
        <div className="container mx-auto grid grid-cols-2 grid-rows-auto gap-3 px-4 py-14 lg:py-20">
          <div className="order-1 col-span-2 lg:col-span-1 max-w-xl mx-auto">
            <h2 className="mb-5 text-3xl font-bold text-center text-neon-pink">
              ghostwriting
            </h2>
          </div>
          <div className="pr-4 order-2 col-span-2 lg:col-span-1 row-span-2 flex gap-2 w-full max-w-xl max-h-96 mx-auto mb-10 lg:mb-0">
            <img src={ghostwriting1} alt="" className="w-1/3" />
            <img src={ghostwriting2} alt="" className="translate-y-7 w-1/3" />
            <img src={ghostwriting3} alt="" className="w-1/3" />
          </div>
          <div className="order-3 col-span-2 lg:col-span-1 max-w-xl mx-auto">
            <p className="text-white flex flex-col gap-4 pb-4">
              Sometimes putting words to a page is easier said than done. Not
              everyone that has a great idea for a book, article, or blog has
              the skills to turn that idea into a captivating written work.
              Ghostwriting solves that problem by taking your voice and creating
              written content that brings the subject matter to life.
              <span className="block">
                Forager Publishing’s writers have the skills and attention to
                detail to write your next content piece. They will work with you
                to capture the essence of your character so it comes through in
                your content. Whether it is to help make it sound right or a
                full takeover of the writing process, the team will work with
                you every step of the way to ensure your publication is
                appreciated by everyone that reads it. At the end of the day,
                people are bombarded with information constantly, so capturing
                their attention right away is key. Let a professional writer
                help you make sure your voice cuts through the clutter.
              </span>
            </p>
          </div>
        </div>
      </div>
      <div id="copyediting" className="bg-white division-service-ids">
        <div className="container mx-auto grid grid-cols-2 grid-rows-auto gap-3 px-4 py-14">
          <div className="order-1 lg:order-2 col-span-2 lg:col-span-1 max-w-xl mx-auto">
            <h2 className="mb-5 text-3xl font-bold text-center text-neon-pink">
              copyediting
            </h2>
          </div>
          <div className="pr-4 order-2 lg:order-1 col-span-2 lg:col-span-1 row-span-2 flex gap-2 w-full max-w-xl max-h-96 mx-auto mb-10 lg:mb-0">
            <img src={copy_editing1} alt="" className="w-1/3" />
            <img src={copy_editing2} alt="" className="translate-y-7 w-1/3" />
            <img src={copy_editing3} alt="" className="w-1/3" />
          </div>
          <div className="order-3 col-span-2 lg:col-span-1 max-w-xl mx-auto">
            <p className="flex flex-col gap-4 pb-4">
              Not everyone was as focused in English class as others, so editing
              can often be an overlooked aspect of publishing an article or
              blog. Making sure there are no spelling mistakes, grammatical
              errors, or readability issues ensures that the message of your
              article is not lost by your audience being distracted or losing
              trust in what you are trying to convey.
              <span className="block">
                Forager Publishing’s editors are here to help you get your
                article or blog into tip-top shape, meaning there are no reasons
                to disregard the editing process now. We work with great detail
                to ensure no element is overlooked. The flow of writing is just
                as important as the substance. If an article is choppy and
                meandering, it can be just as distracting as having spelling
                mistakes and grammatical issues. Each editor will remove all
                issues and optimize the flow to make reading a pleasant and
                informative experience.
              </span>
            </p>
          </div>
        </div>
      </div>
      <div
        id="digital-magazines"
        className="bg-dark-denim division-service-ids"
      >
        <div className="container mx-auto grid grid-cols-2 grid-rows-auto gap-3 px-4 py-14">
          <div className="order-1 col-span-2 lg:col-span-1 max-w-xl mx-auto">
            <h2 className="mb-5 text-3xl font-bold text-center text-neon-pink">
              digital
              <span className="text-white"> magazines</span>
            </h2>
          </div>
          <div className="pr-4 order-2 col-span-2 lg:col-span-1 row-span-2 flex gap-2 w-full max-w-xl max-h-96 mx-auto mb-10 lg:mb-0">
            <img src={digital_magazines1} alt="" className="w-1/3" />
            <img
              src={digital_magazines2}
              alt=""
              className="translate-y-7 w-1/3"
            />
            <img src={digital_magazines3} alt="" className="w-1/3" />
          </div>
          <div className="order-3 col-span-2 lg:col-span-1 max-w-xl mx-auto">
            <p className="text-white flex flex-col gap-4 pb-4">
              Many organizations and associations dream of a digital magazine
              publication. Better than just a recurring blog series, a digital
              magazine can focus on different themes and bring together a myriad
              of different voices to really dive deep into a particular subject
              matter. Whether it&apos;s an annual publication or a monthly
              magazine, there are easy ways to bring this project to life
              through the help of trained professionals from the publishing
              industry.
              <span className="block">
                Forager Publishing’s writers, editors, designers, and publishers
                have worked on productions and know the needs of each project.
                They will work with you from start to finish, bringing together
                the content, visuals, and even advertising to develop a
                professional quality publication ready to send out to your
                membership or to publish on any number of publishing platforms.
              </span>
            </p>
          </div>
        </div>
      </div>
      <div id="book-publishing" className="bg-white division-service-ids">
        <div className="container mx-auto grid grid-cols-2 grid-rows-auto gap-3 px-4 py-14 lg:pb-28">
          <div className="order-1 lg:order-2 col-span-2 lg:col-span-1 max-w-xl mx-auto">
            <h2 className="mb-5 text-3xl font-bold text-center text-neon-pink">
              book
              <span className="text-dark-denim"> publishing</span>
            </h2>
          </div>
          <div className="pr-4 order-2 lg:order-1 col-span-2 lg:col-span-1 row-span-2 flex gap-2 w-full max-w-xl max-h-96 mx-auto mb-10 lg:mb-0">
            <img src={book_publishing1} alt="" className="w-1/3" />
            <img
              src={book_publishing2}
              alt=""
              className="translate-y-7 w-1/3"
            />
            <img src={book_publishing3} alt="" className="w-1/3" />
          </div>
          <div className="order-3 col-span-2 lg:col-span-1 max-w-xl mx-auto">
            <p className="flex flex-col gap-4 pb-4">
              One of the most daunting tasks in publishing is a full book
              project. It involves many different aspects that can quickly take
              a writer out of their comfort zone. With the help of an
              experienced publishing team, that seemingly unattainable feat of
              taking it from an idea to a fully realized published book can be
              greatly reduced.
              <span className="block">
                Forager Publishing’s team has the skills to help at every stage
                of the publishing process. If it is a visually-focused
                publication, there are designers to bring that to life, and if
                it is more focused on the written content, there are writers and
                editors to ensure that it is up to scratch. No matter the stage
                you are at in your book project, Forager Publishing will help
                you tackle one step at a time until that final goal where it is
                sitting up on your audience’s shelf.
              </span>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Services;
