import React from 'react';
import AboutExplorer from '../../../components/divisions/divisionProductions/About/AboutExplorer';
import AboutServices from '../../../components/divisions/divisionProductions/About/AboutServices';
import AboutWork from '../../../components/divisions/divisionProductions/About/AboutWork';
import DivisionLayout from '../../../layouts/DivisionLayout';

function About() {
  const links = {
    about: '/division/productions/about',
    services: '/division/productions/services',
    work: '/division/productions/work',
  };
  return (
    <DivisionLayout colorClass="bg-bayside-cyan" links={links} divisionName="productions">
      <AboutExplorer />
      <AboutWork />
      <AboutServices />
    </DivisionLayout>
  );
}

export default About;
