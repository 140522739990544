import React from "react";
import PromoteCard from "../../PromoteCard";
import graph from "../../../../assets/icons/graphProductions.svg";
import reel from "../../../../assets/icons/reel.svg";
import medal from "../../../../assets/icons/medalProductions.png";
import rocket from "../../../../assets/icons/rocket.png";
import recording from "../../../../assets/img/division/productions/recording.png";

function AboutWork() {
  const steps = [
    {
      icon: graph,
      heading: "monetize your content",
      text: "We aim to: take your content ideas and turn them into sponsored videos that create new revenue streams. Video is a highly engaging medium, let it support your institution.",
    },
    {
      icon: reel,
      heading: "tell a cinematic story",
      text: "We aim to: captivate audiences with long-form video content exploring topics that introduce exciting stories to viewers. Grow your following with great documentary stories.",
    },
    {
      icon: medal,
      heading: "make your brand stand out",
      text: "We aim to: promote your brand in the most exciting and visually appealing way. Tell your story through engaging video content. Show more than just words on a page.",
    },
    {
      icon: rocket,
      heading: "animate your social media",
      text: "We aim to: take your posts and create quick video clips that animate your content. Video backdrops with text overlay catch the eye much more than a static image or full-text post.",
    },
  ];
  return (
    <section className="bg-dark-denim -mt-1 pt-16 lg:pt-0">
      <h1 className="text-bayside-cyan text-2xl md:text-3xl text-center font-bold lg:pb-3">
        what
        <span className="text-white"> we will </span>
        do for you
      </h1>
      <div className="px-4 lg:container lg:mx-auto grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-5 pt-14 pb-16 lg:pb-24  text-center">
        {steps.map((ele, index) => (
          <PromoteCard
            key={index}
            icon={ele.icon}
            heading={ele.heading}
            text={ele.text}
          />
        ))}
      </div>
      <div className="bg-white md:py-24">
        <div className="grid grid-cols-1 md:grid-cols-2 items-center gap-y-10 lg:gap-20 lg:container lg:mx-auto">
          <div
            className="md:rounded-3xl w-full mx-0 h-80 division-about"
            style={{
              backgroundImage: `url(${recording})`,
            }}
          ></div>
          <p className="mx-4 pb-3">
            <span className="font-bold">Forager Productions </span>
            is the division that leads in visual and audio storytelling. Whether
            we are helping create a new podcast series or producing a
            promotional videos or even a documentary film, we are a dedicated
            team that shares your passion for storytelling. We will work with
            your team to showcase what it is you have to say with all the
            technical skills to ensure it not only is a great idea, but executed
            in the best way to inspire your audience.
            <span className="text-bayside-cyan text-2xl text-left font-bold block my-6">
              an experienced team to innovate storytelling
            </span>
            Our writers, editors, sound engineers, and videographers are ready
            to work with clients big or small to bring their ideas for video and
            audio productions to life. Don't let thought of hours of producing
            and editing stop you, that is what we are here for.
          </p>
        </div>
      </div>
    </section>
  );
}

export default AboutWork;
