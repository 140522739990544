import React from 'react';
import Industries from '../components/industries/Industries';
import MainLayout from '../layouts/MainLayout';

function Home() {
  return (
    <MainLayout>
      <Industries />
    </MainLayout>
  );
}

export default Home;
