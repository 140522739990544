import React from "react";
import { Provider } from "react-redux";
import store from "../store";
import MainLayout from "../layouts/MainLayout";
import Email from "../components/contact/Email";

function Admin() {
  return (
    <Provider store={store}>
      <MainLayout>
        <Email />
      </MainLayout>
    </Provider>
  );
}

export default Admin;
